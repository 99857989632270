import React from 'react';
import loadable from '@loadable/component';

import type { NavMenuCategoryProps } from '#components/NavMenuCategory';
import NavMenuCategory from '#components/NavMenuCategory';
import LanguageSelector from '#components/LanguageSelector';
import SmartLink from '#components/SmartLink';

const PrimaryLogo = loadable(() => import('#components/PrimaryLogo'));

export type NavBarDesktopProps = {
  menu: NavMenuCategoryProps[],
};

const NavBarDesktop = ({
  menu,
}: NavBarDesktopProps) => {
  const isLanguageEnabled = process.env.GATSBY_ENABLE_LANG === 'true';

  return (
    <div className="w-full min-h-16 grid grid-cols-12">
      <div className="col-span-2 flex items-center">
        <SmartLink slug="/" ariaLabel="Improving Home Page">
          <PrimaryLogo />
        </SmartLink>
      </div>
      <div className="col-span-9 flex justify-end items-stretch">
        {menu.map((categoryProps) => (
          <NavMenuCategory
            key={categoryProps.id}
            {...categoryProps}
          />
        ))}
      </div>
      {isLanguageEnabled && (
      <div className="col-span-1 flex justify-end items-center">
        <LanguageSelector />
      </div>
      )}
    </div>
  );
};

export default NavBarDesktop;
