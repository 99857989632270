import type { MouseEventHandler } from 'react';
import React from 'react';

import {
  createTheme,
  ListItemButton,
  ListItemText,
  ThemeProvider,
} from '@mui/material';

import { cn } from '@/utils';
import baseTheme from '#components/Layout/theme';
import SmartLink from '#components/SmartLink';
// import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
// import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

export type NavMenuLinkDefinition = NavMenuLinkProps & {
  id: string,
  contentfulId?: string,
};

export type NavMenuLinkProps = {
  label: string,
  href?: string,
  slug?: string,
  openInNewWindow?: boolean,
  onClick?: MouseEventHandler<HTMLAnchorElement>,
  className?: string,
  listStyles?: object,
  buttonClasses?: string,
  color?: string,
  menuOpen?: boolean,
  isMobile?: boolean
  // expandedItem?: boolean
};

const themeNavMenuLink = createTheme(baseTheme, {
  typography: {
    body1: baseTheme.typography.body2,
  },
});

const NavMenuLink = ({
  label,
  href,
  slug,
  openInNewWindow = false,
  onClick,
  className,
  listStyles,
  buttonClasses,
  color,
  menuOpen,
  isMobile,
  // expandedItem,
}: NavMenuLinkProps) => (
  <ThemeProvider theme={themeNavMenuLink}>
    <SmartLink
      href={href}
      slug={slug}
      openInNewWindow={openInNewWindow}
      onClick={onClick}
      className={cn('no-underline', className)}
    >
      <ListItemButton
        className={cn('h-full', buttonClasses)}
        sx={{
          ...listStyles,
          // I have no idea when this happens or what this color is
          ...((menuOpen && !isMobile) && {
            backgroundColor: themeNavMenuLink.palette.action.hover,
          }),
        }}
      >
        <ListItemText sx={{ color: color || '#646466' }}>
          {label}
          {/* <div className="md:flex md:flex-row md:justify-center">
            {(expandedItem && !isMobile) && (
              menuOpen
                ? (<ExpandLessOutlinedIcon className="text-[#5bc2a7]" />)
                : (<ExpandMoreOutlinedIcon className="text-[#5bc2a7]" />)
            )}
          </div> */}
        </ListItemText>
      </ListItemButton>
    </SmartLink>
  </ThemeProvider>
);

export default NavMenuLink;
