import React from 'react';

import CardHolder from '#components/CardHolder';
import type { LinkDefinition } from '#contentful/Link';
import type { ListingContentTypes, ListingOptionTypes } from '#components/Listing';
import JobTable from '#components/Listing/JobTable';
import WebinarCardHolder from '#components/WebinarCardHolder';
import FullClassSchedule from '#components/Listing/FullClassScheduleTable';
import CourseTable from '#components/CourseTable';
import type { WebinarListingOptions } from '#components/WebinarCardHolder';
import type { CardHolderListingOptions, BlogCategoryDefinition } from '#components/CardHolder';
import type { DataTableListingOptions } from '#components/DataTable';

import { useI18next } from 'gatsby-plugin-react-i18next';
import { flow, sortBy } from 'lodash/fp';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';

import autoCardFinder from './_autoCardFinder';

export type AutomatedSuggestedContentDefinition =
  ContentfulComponentDefinition & {
    heading?: string,
    text?: string,
    linkToMoreContent?: LinkDefinition,
    contentType: ListingContentTypes,
    listingOptions: ListingOptionTypes,
    internal: {
      type: 'ContentfulComponentAutomatedSuggestedContent'
    }
  };

export type AutomatedSuggestedContentProps = {
  content: AutomatedSuggestedContentDefinition,
  prioritizedContent?: string[]
};

const AutomatedSuggestedContent = ({
  content: {
    heading,
    text,
    linkToMoreContent,
    contentType,
    listingOptions,
  },
  prioritizedContent,
}: AutomatedSuggestedContentProps) => {
  const { language } = useI18next();

  const getIndex = (id: string) => (prioritizedContent ? prioritizedContent.indexOf(id) : Infinity);

  const sortCards = flow(
    sortBy(({ id }) => {
      const index = getIndex(id);
      return index === -1 ? Infinity : index;
    }),
  );

  switch (contentType.toLowerCase()) {
    case 'jobs':
      return (
        <JobTable
          heading={heading}
          text={text}
          linkToMoreContent={linkToMoreContent}
          {...listingOptions as DataTableListingOptions}
        />
      );
    case 'webinar':
      return (
        <WebinarCardHolder
          {...listingOptions as WebinarListingOptions}
        />
      );
    case 'classes':
      return (
        <FullClassSchedule
          heading={heading}
          text={text}
          linkToMoreContent={linkToMoreContent}
          {...listingOptions as DataTableListingOptions}
        />
      );
    case 'courses':
      return (
        <CourseTable
          heading={heading}
          text={text}
          linkToMoreContent={linkToMoreContent}
          {...listingOptions as DataTableListingOptions}
        />
      );
    default: {
      const cards = autoCardFinder(
        contentType,
        language,
        (listingOptions as CardHolderListingOptions)
          ?.categoryFilter as BlogCategoryDefinition,
      );

      const sortedCards = sortCards(cards);

      return (
        <CardHolder
          {...{
            heading,
            text,
            linkToMoreContent,
            cards: sortedCards,
          }}
          {...listingOptions as CardHolderListingOptions}
        />
      );
    }
  }
};

export default AutomatedSuggestedContent;
