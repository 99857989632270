import { clsx } from 'clsx';

interface Margin {
  mt?: SpacingValue;
  mb?: SpacingValue;
  ml?: SpacingValue;
  mr?: SpacingValue;
}

interface Padding {
  pt?: SpacingValue;
  pb?: SpacingValue;
  pl?: SpacingValue;
  pr?: SpacingValue;
}

const tailwindSpacing = {
  xxsmall: '1',
  xsmall: '2',
  small: '4',
  medium: '6',
  large: '8',
  xlarge: '10',
  xxlarge: '12',
  xxxlarge: '16',
  none: '0',
  default: 'auto',
  auto: 'auto',
};

const contentfulSpacing: { [k: string]: keyof typeof tailwindSpacing } = {
  'XX-Small': 'xxsmall',
  'X-Small': 'xsmall',
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
  'X-Large': 'xlarge',
  'XX-Large': 'xxlarge',
  'XXX-Large': 'xxxlarge',
  None: 'none',
  Default: 'default',
  Auto: 'auto',
};

export type WordSpacing = keyof typeof tailwindSpacing;
export type ContentfulSpacing = keyof typeof contentfulSpacing;

export type SpacingValue = ContentfulSpacing | WordSpacing | number;

const toTailwindSpacing = (value: SpacingValue) => {
  if (typeof value === 'number') {
    return `ml-${value}`;
  }

  const spacingProp = contentfulSpacing[value as string];
  const tailwindClass = tailwindSpacing[spacingProp] || '';

  return tailwindClass;
};

export const getMarginClass = ({
  mt, mb, ml, mr,
}: Margin) => {
  const classes = [
    mt ? `mt-${toTailwindSpacing(mt)}` : '',
    mb ? `mb-${toTailwindSpacing(mb)}` : '',
    ml ? `ml-${toTailwindSpacing(ml)}` : '',
    mr ? `mr-${toTailwindSpacing(mr)}` : '',
  ];

  return clsx(classes);
};

export const getPaddingClass = ({
  pt, pb, pl, pr,
}: Padding) => {
  const classes = [
    pt ? `pt-${toTailwindSpacing(pt)}` : '',
    pb ? `pb-${toTailwindSpacing(pb)}` : '',
    pl ? `pl-${toTailwindSpacing(pl)}` : '',
    pr ? `pr-${toTailwindSpacing(pr)}` : '',
  ];

  return clsx(classes);
};
