import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

export type LocationCellDefinition = LocationCellProps & {
  slug?: string,
};

export type LocationCellProps = {
  isOnline: boolean,
  name: string,
  street1: string,
  street2?: string,
  city: string,
  state: string,
};

const translationKey = 'Components.LocationCell';

const LocationCell = ({
  isOnline = false,
  name,
  street1,
  street2,
  city,
  state,
}: LocationCellProps) => {
  const { t } = useTranslation();

  if (isOnline) {
    return (
      <>
        {t(`${translationKey}.Online`)}
      </>
    );
  }

  return (
    <>
      <b>
        {name}
      </b>
      <br />
      {`${street1}${street2 ? `, ${street2}` : ''}, ${city}, ${state}`}
    </>
  );
};

export default LocationCell;
