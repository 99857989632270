import React, { useMemo } from 'react';

import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';
import { Box } from '@mui/material';

export type VideoDefinition = ContentfulComponentDefinition & {
  youTubeUrl: string,
  width?: String,
  horizontalAlignment?: string,
  internal: {
    type: 'ContentfulComponentVideo'
  }
};

export type VideoProps = {
  content: VideoDefinition
};

const Video = ({
  content: {
    youTubeUrl,
    horizontalAlignment,
    width,
  },
}: VideoProps) => {
  const youtubeEmbeddedUrl = useMemo(
    () => youTubeUrl
    && `${youTubeUrl.replace('watch?v=', 'embed/')}?showinfo=0&modestbranding=1`,
    [youTubeUrl],
  );

  const getWidthClass = () => {
    switch (width) {
      case 'Large':
        return 'w-[960px]';
      case 'Medium':
        return 'w-[640px]';
      case 'Small':
        return 'w-[320px]';
      default:
        return 'w-[320px]';
    }
  };

  const getAlignmentClass = () => {
    switch (horizontalAlignment) {
      case 'Left':
        return 'justify-start';
      case 'Center ':
        return 'justify-center';
      case 'Right':
        return 'justify-end';
      default:
        return 'justify-center';
    }
  };

  return (
    <Box className={`flex ${getAlignmentClass()} my-2`}>
      <iframe
        src={youtubeEmbeddedUrl}
        title="promotional video"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className={`${getWidthClass()} aspect-[16/9] border-0`}
      />
    </Box>
  );
};

export default Video;
