import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  filter,
  first,
  flow,
  map,
} from 'lodash/fp';

type QueryPage = {
  userProfile: {
    arloPresenterId: number,
  },
  composePage: {
    slug: string,
  }[],
  nodeLocale: string,
};

export type TrainingProfilePage = {
  arloPresenterId: number,
  slug: string,
};

export default (
  language: string,
): TrainingProfilePage[] => {
  const data = useStaticQuery(graphql`
    {
      all: allContentfulPageTemplateProfilePage(
        filter: {
          compose__page: {elemMatch: {slug: {ne: null}}}, 
          userProfile: {arloPresenterId: {ne: null}}
        }
      ) {
        pages: nodes {
          composePage: compose__page {
            slug
          }
          userProfile {
            arloPresenterId
          }
          nodeLocale: node_locale
        }
      }
    }
  `);

  return useMemo(
    () => flow(
      filter({ nodeLocale: language }),
      map(
        ({
          userProfile,
          composePage,
        }: QueryPage) => ({
          arloPresenterId: userProfile.arloPresenterId,
          slug: first(composePage)?.slug,
        }) as TrainingProfilePage,
      ),
    )(data.all.pages),
    [data.all.pages, language],
  );
};
