export default (filename: string, fileUrl: string): void => {
  if (!fileUrl) {
    return;
  }

  fetch(fileUrl)
    .then((resp) => resp.blob())
    .then((blobobject) => {
      const blob = window.URL.createObjectURL(blobobject);
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = blob;
      anchor.download = filename ?? fileUrl;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(blob);
    });
};
