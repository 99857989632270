import {
  filter,
  flow,
  map,
  includes,
  curry,
} from 'lodash/fp';

import type { TrainingCourseCategory } from '#components/DataTable';
import type {
  TrainingPageCardDefinition,
} from '#contentful/ContentfulCard/TrainingPageCard';
import type { ContentfulTrainingPage } from '#utils/getTrainingPage';

export type TrainingCategoryParent = TrainingPageCardDefinition
| ContentfulTrainingPage;

export default curry((
  category: TrainingCourseCategory,
  items: TrainingCategoryParent[],
) => filter(
  ({ categories }: TrainingCategoryParent) => (
    !category || flow(
      map('contentfulId'),
      includes(category.contentfulId),
    )(categories)
  ),
  items,
)) as any;
