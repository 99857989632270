import type { ContentfulColumnSpan } from './types';

export default (span?: ContentfulColumnSpan): number => {
  switch (span) {
    case '1/4':
      return 3;
    case '1/3':
      return 4;
    case '1/2':
      return 6;
    case '2/3':
      return 8;
    case '3/4':
      return 9;
    case 'Full':
    default:
      return 12;
  }
};
