import React, { useEffect, useState } from 'react';
import { filter, flow } from 'lodash/fp';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import type { JobTableDefinition } from '#services/dynamics';
import { getOpenPositions } from '#services/dynamics';
import type { LinkDefinition } from '#contentful/Link';
import DataTable from '#components/DataTable';

import type { InitialRowCount, JobCountry } from '#components/DataTable';
import columns from './_columns';

export type JobTableProps = {
  heading?: string,
  text?: string,
  linkToMoreContent?: LinkDefinition,
  initialRowCount?: InitialRowCount,
  filterValue?: JobCountry,
};

const JobTable = ({
  heading,
  text,
  linkToMoreContent,
  initialRowCount,
  filterValue,
}: JobTableProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState<JobTableDefinition[]>([]);

  useEffect(() => {
    getOpenPositions()
      .then(
        flow(
          filter(({ country }) => !filterValue || country === filterValue?.name),
          setListings,
          () => setLoading(false),
        ),
      );
  }, [filterValue]);

  return (
    <DataTable
      columns={columns(t)}
      items={listings}
      noItemsCopy={`There are no positions currently available in ${filterValue?.name}.`}
      heading={heading}
      text={text}
      href={linkToMoreContent?.href}
      slug={linkToMoreContent?.page?.slug}
      anchorTag={linkToMoreContent?.anchorTag?.id}
      searchable
      initialRowCount={initialRowCount}
      loadingItems={loading}
    />
  );
};

export default JobTable;
