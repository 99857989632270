import React from 'react';
import { Grid } from '@mui/material';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';
import SuggestedContentTabsGroup from './SuggestedContentTabsGroup';
import type { SuggestedContentTabsTabDefinition } from './SuggestedContentTabsTab';
import SuggestedContentTabsTab from './SuggestedContentTabsTab';

export type SuggestedContentTabsDefinition = ContentfulComponentDefinition & {
  tabs: SuggestedContentTabsTabDefinition[],
  id?: string,
  internal: {
    type: 'ContentfulComponentSuggestedContentTabs'
  }
};

export type SuggestedContentTabsProps = {
  content: SuggestedContentTabsDefinition
};

const SuggestedContentTabs = ({
  content,
}: SuggestedContentTabsProps) => {
  const [activeTab, setActiveTab] = React.useState(content?.tabs?.[0]?.id);

  const tabToShow = content?.tabs?.find((tab: any) => tab?.id === activeTab) || {};

  return (
    <>
      <Grid
        container
        justifyContent="center"
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={{
          xs: 2,
          md: 12,
        }}
      >
        {
          content?.tabs?.map((tab: any) => (
            <SuggestedContentTabsTab
              key={tab?.id}
              tab={tab}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ))
        }
      </Grid>

      <SuggestedContentTabsGroup
        key={activeTab}
        id={activeTab}
        tab={tabToShow as SuggestedContentTabsTabDefinition}
      />
    </>
  );
};

export default SuggestedContentTabs;
