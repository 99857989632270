import React, { useEffect, useRef, useState } from 'react';
import {
  flow,
  forEach,
  isEmpty,
} from 'lodash/fp';
import MuiCarousel from 'react-material-ui-carousel';
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from '@mui/icons-material';
import {
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import type {
  ContentfulSectionSpacingDefinition,
} from '#hooks/useSectionSpacing';
import useSectionSpacing from '#hooks/useSectionSpacing';
import IconButton from '#contentful/IconButton';
import type { LinkDefinition } from '#contentful/Link';
import ContentfulLink from '#contentful/Link';

import useBorderRoundingToPx from '#hooks/useBorderRoundingToPx';
import type { BorderRounding } from '#hooks/useBorderRoundingToPx/useBorderRoundingToPx';
import CarouselItem from './CarouselItem';
import type {
  CarouselItemDefinition,
} from './CarouselItem';

export type CarouselDefinition = CarouselProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentCarousel',
  },
};

type CarouselOptions = {
  interval?: number,
  transitionDuration?: number,
  transitionType?: 'slide' | 'fade',
  autoplay?: boolean,
  stopAutoplayOnHover?: boolean,
  cornerRoundingSize?: BorderRounding,
  arrowNavigation?: boolean,
  dotNavigation?: boolean,
};

type CarouselProps = {
  items: CarouselItemDefinition[],
  minHeight?: number,
  spacingSettings?: ContentfulSectionSpacingDefinition,
  options?: CarouselOptions,
  link: LinkDefinition,
};

const Carousel = ({
  items,
  minHeight,
  spacingSettings,
  options,
  link,
}: CarouselProps) => {
  const {
    interval = 5.5,
    transitionDuration = 1.5,
    transitionType = 'slide',
    autoplay = true,
    stopAutoplayOnHover = true,
    cornerRoundingSize = 'None',
    arrowNavigation = true,
    dotNavigation = true,
  } = options ?? {};

  const {
    border,
    breakpoints,
  } = useTheme();

  const indicatorIcons: JSX.Element[] = [];
  flow(
    forEach(
      ({ indicatorIcon }: CarouselItemDefinition) => (
        indicatorIcon && indicatorIcons.push(
          <IconButton
            icon={indicatorIcon.icon}
            label={indicatorIcon.label}
            labelColor={indicatorIcon.labelColor}
            style={{
              width: 90,
            }}
          />,
        )
      ),
    ),
  )(items);

  //  Calculate section settings
  const responsiveSectionSpacing = useSectionSpacing(
    {
      ...spacingSettings,
    },
    {
      mtmd: 1,
      mbmd: 1,
      mtlg: 2,
      mblg: 2,
      mllg: 0,
      mrlg: 0,
      ptmd: 1,
      pbmd: 1,
      plmd: 0,
      prmd: 0,
      ptlg: 2,
      pblg: 2,
      pllg: 2,
      prlg: 2,
      plxl: 8,
      prxl: 8,
      columnSpacinglg: 4,
    },
  );

  const smallScreen = useMediaQuery(
    breakpoints.down('md'),
  );

  const borderRadious = useBorderRoundingToPx(cornerRoundingSize);

  const showNavButtons = items?.length > 1 && arrowNavigation !== false;
  const showDotNavigation = dotNavigation !== false;

  const [carouselHeight, setCarouselHeight] = useState<string | undefined>(undefined);
  const firstItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (firstItemRef?.current) {
      const firstItemHeight = firstItemRef?.current?.clientHeight;
      const height = firstItemHeight !== 0
        ? `${firstItemHeight}px`
        : undefined;
      setCarouselHeight(height);
    }
  }, [firstItemRef?.current?.clientHeight]);

  return (
    <>
      {link && (
        <Box
          display="flex"
          justifyContent="flex-end"
          mr={2}
          mb={{
            xs: 1,
            md: -1,
          }}
        >
          <ContentfulLink
            content={link}
          >
            {link?.label}
          </ContentfulLink>
        </Box>
      )}
      <MuiCarousel
        interval={interval * 1000}
        duration={transitionDuration * 1000}
        animation={transitionType}
        autoPlay={autoplay}
        stopAutoPlayOnHover={stopAutoplayOnHover}
        navButtonsAlwaysVisible={!smallScreen}
        changeOnFirstRender
        fullHeightHover={false}
        height={carouselHeight}
        onChange={(now) => {
          if (now !== 0) {
            setCarouselHeight(undefined);
          }
        }}
        PrevIcon={(
          <ArrowBackIosOutlined
            fontSize="large"
          />
      )}
        NextIcon={(
          <ArrowForwardIosOutlined
            fontSize="large"
          />
      )}
        navButtonsProps={{
          style: {
            padding: '2rem 1rem',
            margin: -8,
            borderRadius: border.section,
            display: showNavButtons ? 'flex' : 'none',
          },
        }}
        {...(
        !smallScreen && !isEmpty(indicatorIcons) && {
          IndicatorIcon: indicatorIcons,
        }
      )}
        indicatorContainerProps={{
          style: {
            marginTop: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: `${(smallScreen || isEmpty(indicatorIcons)) ? 1 : 3}rem`,
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: smallScreen ? 'rgba(0,0,0,0)' : '#efefef',
          },
        }}
        indicatorIconButtonProps={{
          style: {
            borderRadius: border.section,
            padding: 8,
            display: showDotNavigation ? 'flex' : 'none',
          },
        }}
        sx={{
          div: {
            ':nth-of-type(2), :nth-of-type(3)': {
              '.MuiIconButton-sizeMedium': {
                backgroundColor: 'rgba(0,0,0,0.16)',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.42)',
                },
              },
            },
          },
          '.section-settings': {
            borderRadius: borderRadious,
            overflow: 'hidden',
          },
        }}
      >
        {items?.map((item, index: number) => (
          <div
            key={item.id}
            ref={index === 0 ? firstItemRef : null}
          >
            <CarouselItem
              {...item}
              minHeight={minHeight}
              responsiveSectionSpacing={responsiveSectionSpacing}
            />
          </div>
        ))}
      </MuiCarousel>
    </>

  );
};

export default Carousel;
