import React from 'react';
import { curry } from 'lodash/fp';
import { Typography } from '@mui/material';

const renderTypography = curry((
  variant: Parameters<(typeof Typography)>[0]['variant'] | '',
  _: any,
  children: any,
) => (
  <Typography variant={variant || undefined}>
    {children}
  </Typography>
));

export default renderTypography;
