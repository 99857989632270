import React from 'react';
import type { GridRenderCellParams } from '@mui/x-data-grid';

import type { DataTableColDef } from '#components/DataTable';
import Button from '#components/Button';
import TableHeaderCell from '#components/DataTable/TableHeaderCell';
import type { JobTableDefinition } from '#services/dynamics';
import LinkCell from '#components/DataTable/LinkCell';

import getLocationPage from '#utils/getLocationPage';
import type { TFunction } from 'i18next';

const translationKey = 'Components.JobTable.Columns';

const columns: (t: TFunction) => DataTableColDef[] = (t) => ([
  {
    field: 'title',
    headerName: 'Title',
    flex: 4,
    minWidth: 260,
    searchable: true,
    renderHeader: () => <TableHeaderCell title={t(`${translationKey}.Title`)} />,
    valueGetter: ({ row }: GridRenderCellParams<JobTableDefinition>) => row.title,
    valueField: 'title',
    renderCell: ({ row }: GridRenderCellParams<JobTableDefinition>) => (
      <LinkCell
        slug={`/careers/open-positions/details?id=${row.guid}`}
        minWidth={260}
      >
        {row.title}
      </LinkCell>
    ),
  },
  {
    field: 'category',
    headerName: 'Category',
    flex: 3,
    minWidth: 200,
    searchable: true,
    renderHeader: () => <TableHeaderCell title={t(`${translationKey}.Category`)} />,
    valueGetter: ({ row }: GridRenderCellParams<JobTableDefinition>) => row.category,
    valueField: 'category',
    renderCell: ({ row }: GridRenderCellParams<JobTableDefinition>) => (
      <LinkCell minWidth={180}>
        {row.category}
      </LinkCell>
    ),
  },
  {
    field: 'jobType',
    headerName: 'Job Type',
    flex: 2,
    minWidth: 100,
    searchable: true,
    renderHeader: () => <TableHeaderCell title={t(`${translationKey}.JobType`)} />,
    valueGetter: ({ row }: GridRenderCellParams<JobTableDefinition>) => row.jobType,
    valueField: 'jobType',
    renderCell: ({ row }: GridRenderCellParams<JobTableDefinition>) => (
      <LinkCell minWidth={100}>
        {row.jobType}
      </LinkCell>
    ),
  },
  {
    field: 'location',
    headerName: 'Location',
    flex: 2,
    minWidth: 200,
    searchable: true,
    renderHeader: () => <TableHeaderCell title={t(`${translationKey}.Location`)} />,
    valueGetter: ({ row }: GridRenderCellParams<JobTableDefinition>) => row.location,
    valueField: 'location',
    renderCell: ({ row }: GridRenderCellParams<JobTableDefinition>) => {
      const locationPage = getLocationPage(row.location);

      return (
        <LinkCell
          slug={locationPage?.slug}
          minWidth={200}
        >
          {row.location}
        </LinkCell>
      );
    },
  },
  {
    field: 'apply',
    headerName: '',
    flex: 1,
    minWidth: 110,
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    renderCell: ({ row }: GridRenderCellParams<JobTableDefinition>) => (
      row.applicationUrl
        ? (
          <Button
            variant="primary"
            href={row.applicationUrl ?? ''}
            isInTable
          >
            {t(`${translationKey}.ApplyButton`)}
          </Button>
        )
        : (
          <>
            {t(`${translationKey}.ClosedButton`)}
          </>
        )),
  },
]);

export default columns;
