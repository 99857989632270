import React from 'react';
import {
  Box,
} from '@mui/material';
import Slider from 'react-slick';

import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import type {
  ContentfulSectionSpacingDefinition,
} from '#hooks/useSectionSpacing';
import useSectionSpacing from '#hooks/useSectionSpacing';
import type { LinkDefinition } from '#contentful/Link';
import ContentfulLink from '#contentful/Link';

import SectionSettings from '#components/Section/SectionSettings';
import ImageCarouselItem from './ImageCarouselItem';
import type {
  ImageCarouselItemDefinition,
} from './ImageCarouselItem';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export type ImageCarouselDefinition = ImageCarouselProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentImageCarousel',
  },
};

type ImageCarouselOptions = {
  slidesToShow?: number,
  slidesToScroll?: number,
  interval?: number,
  transitionDuration?: number,
  transitionType?: 'slide' | 'fade',
  centered?: boolean,
  showNavigationDots?: boolean,
  autoplay?: boolean,
  stopAutoplayOnHover?: boolean,
};

type ImageCarouselProps = {
  items: ImageCarouselItemDefinition[],
  spacingSettings?: ContentfulSectionSpacingDefinition,
  options?: ImageCarouselOptions,
  link: LinkDefinition,
};

const ImageCarousel = ({
  items,
  spacingSettings,
  options,
  link,
}: ImageCarouselProps) => {
  const {
    interval = 5.5,
    transitionDuration = 1.5,
    transitionType = 'slide',
    centered = false,
    autoplay = true,
    stopAutoplayOnHover = true,
    showNavigationDots = true,
    slidesToShow = 4,
    slidesToScroll = 1,
  } = options ?? {};

  //  Calculate section settings
  const responsiveSectionSpacing = useSectionSpacing(
    {
      ...spacingSettings,
    },
    {
      mtmd: 0,
      mbmd: 0,
      mtlg: 0,
      mblg: 0,
      mllg: 0,
      mrlg: 0,
      ptmd: 0,
      pbmd: 0,
      plmd: 0,
      prmd: 0,
      ptlg: 0,
      pblg: 0,
      pllg: 0,
      prlg: 0,
      plxl: 0,
      prxl: 0,
      columnSpacinglg: 0,
    },
  );

  return (
    <SectionSettings {...responsiveSectionSpacing}>
      <Box
        sx={{
          mb: 6,
          boxSizing: 'border-box',
        }}
      >
        {link && (
        <Box display="flex" justifyContent="flex-end" mr={2} mb={2}>
          <ContentfulLink
            content={link}
          >
            {link?.label}
          </ContentfulLink>
        </Box>
        )}
        <Slider
          infinite
          dots={showNavigationDots}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToScroll}
          speed={transitionDuration * 1000}
          autoplaySpeed={interval * 1000}
          centerMode={centered}
          autoplay={autoplay}
          pauseOnHover={stopAutoplayOnHover}
          fade={transitionType === 'fade'}
          arrows={false}
          pauseOnDotsHover={stopAutoplayOnHover}
          responsive={[
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {items?.map((item) => (
            <ImageCarouselItem
              key={item.id}
              {...item}
            />
          ))}
        </Slider>
      </Box>
    </SectionSettings>
  );
};

export default ImageCarousel;
