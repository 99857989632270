import React from 'react';
import {
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';

import type { MultiSelectOptionProps } from '../types';

const FormFieldRadioOption = ({
  label,
  value,
  required,
}: MultiSelectOptionProps) => (
  <FormControlLabel
    value={value}
    control={<Radio required={required} />}
    label={<Typography variant="subtitle1">{label}</Typography>}
  />
);

export default FormFieldRadioOption;
