import React from 'react';
import { Grid } from '@mui/material';
import { map } from 'lodash/fp';

import type { RichTextContent } from '#components/RichText';
import RichText from '#components/RichText';

import columnOptions from './_columnOptions';

export type FooterColumnDefinition = {
  content: RichTextContent,
  id: string
};

export type FooterColumnProps = {
  columns: FooterColumnDefinition[],
};

const FooterColumns = ({ columns }: FooterColumnProps) => (
  <Grid
    container
    rowGap={{ xs: 5, md: 2, lg: 0 }}
    columnSpacing={{ xs: 5, md: 2 }}
  >
    {map(
      (column: FooterColumnDefinition) => (
        <Grid
          key={column.id}
          item
          xs={6}
          md={3}
        >
          <RichText
            content={column.content}
            options={columnOptions}
          />
        </Grid>
      ),
      columns,
    )}
  </Grid>
);

export default FooterColumns;
