import React from 'react';
import type { GridRenderCellParams } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
import { isEmpty, map } from 'lodash/fp';
import type { TFunction } from 'i18next';

import Button from '#components/Button';
import TableHeaderCell from '#components/DataTable/TableHeaderCell';
import type { DataTableColDef } from '#components/DataTable';
import type { FlatClassData } from '#utils/flattenArloEventIntoClassData';
import LinkCell from '#components/DataTable/LinkCell';
import ScheduleCell from '#components/DataTable/ScheduleCell';
import PriceCell from '#components/DataTable/PriceCell';

import type {
  InstructorDefinition,
  TrainingCourseTableData,
} from './_convertArloEventToTableData';
import CourseNameLink from './CourseNameLink';

const translationKey = 'Components.FullClassScheduleTable.Columns';

const columns: (t: TFunction) => DataTableColDef[] = (t) => ([
  {
    field: 'date',
    headerName: 'Date',
    flex: 2,
    minWidth: 100,
    filterable: false,
    renderHeader: () => <TableHeaderCell title={t(`${translationKey}.Date`)} />,
    renderCell: ({ row }: GridRenderCellParams<TrainingCourseTableData>) => (
      <ScheduleCell
        startDateTime={row.start ?? ''}
        endDateTime={row.end ?? ''}
      />
    ),
  },
  {
    field: 'course',
    headerName: 'Course',
    flex: 5,
    minWidth: 200,
    searchable: true,
    renderHeader: () => <TableHeaderCell title={t(`${translationKey}.Course`)} />,
    valueGetter: ({ row }: GridRenderCellParams<TrainingCourseTableData>) => row.course,
    valueField: 'course',
    renderCell: ({ row }: GridRenderCellParams<TrainingCourseTableData>) => (
      <CourseNameLink
        id={row.eventTemplateId as number}
        name={row.course as string}
      />
    ),
  },
  {
    field: 'location',
    headerName: 'Location',
    flex: 2,
    minWidth: 144,
    searchable: true,
    renderHeader: () => <TableHeaderCell title={t(`${translationKey}.Location`)} />,
    valueGetter: ({ row }: GridRenderCellParams<TrainingCourseTableData>) => row.location,
    valueField: 'location',
    renderCell: ({ row }: GridRenderCellParams<TrainingCourseTableData>) => (
      <LinkCell
        minWidth={100}
        slug={row.locationSlug}
      >
        {row.location}
      </LinkCell>
    ),
  },
  {
    field: 'instructors',
    headerName: 'Instructors',
    flex: 2,
    minWidth: 146,
    searchable: true,
    renderHeader: () => <TableHeaderCell title={t(`${translationKey}.Instructors`)} />,
    valueGetter: ({ row }: GridRenderCellParams<TrainingCourseTableData>) => (
      row.instructors.map((i: InstructorDefinition) => i.name).join(', ')
    ),
    valueField: 'instructorNames',
    renderCell: ({ row }: GridRenderCellParams<TrainingCourseTableData>) => (
      <Grid container>
        {isEmpty(row.instructors) ? (
          <Typography
            variant="subtitle1"
            color="color.impgray900"
          >
            TBA
          </Typography>
        ) : map(({
          id,
          name,
          slug,
        }: InstructorDefinition) => (
          <Grid item key={id}>
            <LinkCell
              minWidth={140}
              slug={slug}
            >
              {name}
            </LinkCell>
          </Grid>
        ), row.instructors)}
      </Grid>
    ),
  },
  {
    field: 'price',
    headerName: 'Price',
    flex: 2,
    minWidth: 146,
    filterable: false,
    renderHeader: () => <TableHeaderCell title={t(`${translationKey}.StartingAt`)} />,
    renderCell: ({ row }: GridRenderCellParams<FlatClassData>) => (
      <PriceCell
        basePrice={row.basePrice}
        discountPrice={row.discountPrice}
        currencyCode={row.currencyCode}
      />
    ),
  },
  {
    field: 'register',
    headerName: '',
    flex: 1,
    minWidth: 130,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }: GridRenderCellParams<TrainingCourseTableData>) => (
      Date.parse(row.start) > Date.now()
        ? (
          <Button
            variant="primary"
            href={row.registrationUri ?? ''}
            isInTable
          >
            {t(`${translationKey}.RegisterButton`)}
          </Button>
        )
        : (
          <>
            {t(`${translationKey}.ClosedButton`)}
          </>
        )),
  },
]);

export default columns;
