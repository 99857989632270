import { defaultsDeep } from 'lodash/fp';

import { toMobileSpace } from '#components/Layout/theme/spacing.theme';
import useContentfulAlignment from '#hooks/useContentfulAlignment';
import useResponsiveAlignItems from '#hooks/useResponsiveAlignItems';
import useResponsiveJustifyContent from '#hooks/useResponsiveJustifyContent';
import useResponsiveSpacing from '#hooks/useResponsiveSpacing';
import useResponsiveTextAlign from '#hooks/useResponsiveTextAlign';

import type {
  ContentfulSectionSpacingDefinition,
  ResponsiveSectionSpacing,
  SectionSpacingOptions,
} from './types';
import defaultOptions from './_defaultOptions';

export default (
  {
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    sectionColumnsHorizontalAlignment,
    sectionColumnsVerticalAlignment,
    columnSpacing,
    rowSpacing,
    sectionMaximumWidth,
    // sectionMinimumHeight,
    textAlignment,
  }: ContentfulSectionSpacingDefinition,
  options: SectionSpacingOptions,
): ResponsiveSectionSpacing => {
  const {
    mtxs,
    mtsm,
    mtmd,
    mtlg,
    mtxl,
    mbxs,
    mbsm,
    mbmd,
    mblg,
    mbxl,
    mrxs,
    mrsm,
    mrmd,
    mrlg,
    mrxl,
    mlxs,
    mlsm,
    mlmd,
    mllg,
    mlxl,
    ptxs,
    ptsm,
    ptmd,
    ptlg,
    ptxl,
    pbxs,
    pbsm,
    pbmd,
    pblg,
    pbxl,
    prxs,
    prsm,
    prmd,
    prlg,
    prxl,
    plxs,
    plsm,
    plmd,
    pllg,
    plxl,
    verticalAlignment,
    horizontalAlignment,
    justifyContentxs,
    justifyContentsm,
    justifyContentmd,
    justifyContentlg,
    justifyContentxl,
    alignItemsxs,
    alignItemssm,
    alignItemsmd,
    alignItemslg,
    alignItemsxl,
    columnSpacingxs,
    columnSpacingsm,
    columnSpacingmd,
    columnSpacinglg,
    columnSpacingxl,
    rowSpacingxs,
    rowSpacingsm,
    rowSpacingmd,
    rowSpacinglg,
    rowSpacingxl,
    widthxs,
    widthsm,
    widthmd,
    widthlg,
    widthxl,
    textAlignmentxs,
    textAlignmentsm,
    textAlignmentmd,
    textAlignmentlg,
    textAlignmentxl,
  } = defaultsDeep(defaultOptions, options);

  const mt = useResponsiveSpacing({
    xs: toMobileSpace(marginTop) ?? mtxs,
    sm: toMobileSpace(marginTop) ?? mtsm,
    md: toMobileSpace(marginTop) ?? mtmd,
    lg: marginTop ?? mtlg,
    xl: marginTop ?? mtxl,
  });
  const mb = useResponsiveSpacing({
    xs: toMobileSpace(marginBottom) ?? mbxs,
    sm: toMobileSpace(marginBottom) ?? mbsm,
    md: toMobileSpace(marginBottom) ?? mbmd,
    lg: marginBottom ?? mblg,
    xl: marginBottom ?? mbxl,
  });
  const ml = useResponsiveSpacing({
    xs: mlxs,
    sm: mlsm,
    md: mlmd,
    lg: marginLeft ?? mllg,
    xl: marginLeft ?? mlxl,
  });
  const mr = useResponsiveSpacing({
    xs: mrxs,
    sm: mrsm,
    md: mrmd,
    lg: marginRight ?? mrlg,
    xl: marginRight ?? mrxl,
  });

  const pt = useResponsiveSpacing({
    xs: toMobileSpace(paddingTop) ?? ptxs,
    sm: toMobileSpace(paddingTop) ?? ptsm,
    md: toMobileSpace(paddingTop) ?? ptmd,
    lg: paddingTop ?? ptlg,
    xl: paddingTop ?? ptxl,
  });
  const pb = useResponsiveSpacing({
    xs: toMobileSpace(paddingBottom) ?? pbxs,
    sm: toMobileSpace(paddingBottom) ?? pbsm,
    md: toMobileSpace(paddingBottom) ?? pbmd,
    lg: paddingBottom ?? pblg,
    xl: paddingBottom ?? pbxl,
  });
  const pl = useResponsiveSpacing({
    xs: plxs,
    sm: plsm,
    md: plmd,
    lg: paddingLeft ?? pllg,
    xl: paddingLeft ?? plxl,
  });
  const pr = useResponsiveSpacing({
    xs: prxs,
    sm: prsm,
    md: prmd,
    lg: paddingRight ?? prlg,
    xl: paddingRight ?? prxl,
  });

  const sectionJustifyContent = useContentfulAlignment(
    sectionColumnsHorizontalAlignment
      ?? horizontalAlignment,
  );
  const sectionAlignItems = useContentfulAlignment(
    sectionColumnsVerticalAlignment
      ?? verticalAlignment,
  );
  const responsiveJustifyContent = useResponsiveJustifyContent({
    xs: justifyContentxs,
    sm: justifyContentsm,
    md: justifyContentmd,
    lg: sectionJustifyContent ?? justifyContentlg,
    xl: sectionJustifyContent ?? justifyContentxl,
  });
  const responsiveAlignItems = useResponsiveAlignItems({
    xs: alignItemsxs,
    sm: alignItemssm,
    md: alignItemsmd,
    lg: sectionAlignItems ?? alignItemslg,
    xl: sectionAlignItems ?? alignItemsxl,
  });

  const responsiveColumnSpacing = useResponsiveSpacing({
    xs: columnSpacingxs,
    sm: columnSpacingsm,
    md: columnSpacing ?? columnSpacingmd,
    lg: columnSpacing ?? columnSpacinglg,
    xl: columnSpacing ?? columnSpacingxl,
  });
  const responsiveRowSpacing = useResponsiveSpacing({
    xs: rowSpacingxs,
    sm: rowSpacingsm,
    md: rowSpacing ?? rowSpacingmd,
    lg: rowSpacing ?? rowSpacinglg,
    xl: rowSpacing ?? rowSpacingxl,
  });

  const responsiveWidth = useResponsiveSpacing({
    xs: widthxs,
    sm: widthsm,
    md: widthmd,
    lg: sectionMaximumWidth ? `${sectionMaximumWidth}%` : widthlg,
    xl: sectionMaximumWidth ? `${sectionMaximumWidth}%` : widthxl,
  });

  const correctedTextAlignment = textAlignment === 'Default' ? 'inherit' : textAlignment;
  const responsiveTextAlign = useResponsiveTextAlign({
    xs: textAlignmentxs,
    sm: (correctedTextAlignment ?? textAlignmentsm),
    md: (correctedTextAlignment ?? textAlignmentmd),
    lg: (correctedTextAlignment ?? textAlignmentlg),
    xl: (correctedTextAlignment ?? textAlignmentxl),
  });

  return {
    mt,
    mb,
    ml,
    mr,
    pt,
    pb,
    pl,
    pr,
    responsiveJustifyContent,
    responsiveAlignItems,
    responsiveColumnSpacing,
    responsiveRowSpacing,
    responsiveWidth,
    responsiveTextAlign,
  };
};
