import React from 'react';

import type { InteractiveGraphicFragment } from '../InteractiveGraphic';
import ExpertiseGraphic from './GraphicStyles/Expertise/Variations/ExpertiseGraphic';

type ExpertiseGraphicProps = {
  id: string,
  graphicStyle: {
    internal: {
      raw: string,
    },
  }
  fragments: InteractiveGraphicFragment[],
  activeElement: string | null,
  setActiveElement: React.Dispatch<React.SetStateAction<string | null>>,
};

const Graphics = ({
  graphicStyle,
  fragments,
  activeElement,
  setActiveElement,
  id,
}: ExpertiseGraphicProps) => {
  const style = JSON.parse(graphicStyle?.internal?.raw);

  switch (style?.svgId) {
    case 'expertiseGraphic':
      return (
        <ExpertiseGraphic
          variation={style?.svgVariationId}
          fragments={fragments}
          activeElement={activeElement}
          setActiveElement={setActiveElement}
          id={id}
        />
      );
    default:
      throw new Error(`Unexpected graphicStyle type: ${style?.graphicStyle}`);
  }
};

export default Graphics;
