import type React from 'react';
import type { Theme } from '@mui/material';
import type {
  TypographyOptions,
} from '@mui/material/styles/createTypography';

export default ({
  breakpoints,
}: Theme): TypographyOptions => ({
  h1: {
    fontFamily: 'Poppins-Bold',
    fontWeight: 'bold',
    fontSize: '4rem',
    lineHeight: 1.17,
    letterSpacing: '-0.86px',
    color: '#005596',
    [breakpoints.down('md')]: {
      fontWeight: 'normal',
      fontSize: '2.8rem',
      lineHeight: 1.3,
      letterSpacing: '-0.01em',
    },
    [breakpoints.down('sm')]: {
      fontWeight: 'normal',
      fontSize: '2rem',
      lineHeight: 1.3,
      letterSpacing: '-0.01em',
    },
  },
  fouxH1: {},
  h2: {
    fontFamily: 'Poppins-Bold',
    fontWeight: 'bold',
    fontSize: '2.25rem',
    lineHeight: 1.19,
    letterSpacing: '-0.42px',
    color: '#4D88B6',
    marginBottom: '0.5em',
    [breakpoints.down('md')]: {
      fontSize: '2.4rem',
      lineHeight: 1.19,
      letterSpacing: '-0.34px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '1.8rem',
      lineHeight: 1.19,
      letterSpacing: '-0.34px',
    },
  },
  h3: {
    fontFamily: 'Poppins-Bold',
    fontWeight: 'bold',
    fontSize: '1.75rem',
    lineHeight: 1.19,
    letterSpacing: '-0.14px',
    color: '#4D88B6',
    marginBottom: '0.5rem',
    [breakpoints.down('md')]: {
      fontSize: '1.8rem',
      lineHeight: 1.29,
      letterSpacing: '-0.22px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '1.6rem',
      lineHeight: 1.29,
      letterSpacing: '-0.22px',
    },
  },
  h4: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: '1.5rem',
    lineHeight: 1.13,
    letterSpacing: '-0.1px',
    fontStyle: 'normal',
    color: '#4D88B6',
    marginBottom: '0.5rem',
  },
  h5: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '600',
    fontSize: '1.25rem',
    lineHeight: 1.25,
    letterSpacing: '0.1px',
    color: '#4D88B6',
    paddingTop: '8px',
    paddingBottom: '4px',
  },
  h6: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: 1,
    letterSpacing: '0.06px',
    color: '#4D88B6',
  },
  subheading1: {
    fontFamily: 'Roboto-Light',
    fontWeight: '300',
    fontSize: '1.5rem',
    lineHeight: 1.9,
    letterSpacing: '0.62px',
    [breakpoints.down('md')]: {
      fontSize: '1.3rem',
    },
  },
  subheading2: {
    fontFamily: 'Roboto-Light',
    fontWeight: '300',
    fontSize: '1.375rem',
    lineHeight: 1.27,
    letterSpacing: '0.44px',
  },
  subtitle1: {
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.14px',
  },
  subtitle2: {
    fontFamily: 'Roboto-Medium',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: 1.71,
    letterSpacing: '0.1px',
    marginBottom: '1.5em',
  },
  body1: {
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    fontSize: '1.25rem',
    lineHeight: 1.8,
    letterSpacing: '0.72px',
    '& strong, & b': {
      fontFamily: 'Poppins-SemiBold',
    },
  },
  body2: {
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    '& strong, & b': {
      fontFamily: 'Poppins-SemiBold',
    },
  },
  body3: {
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.25px',
  },
  button1: {
    fontFamily: 'Roboto-Medium',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: 1,
    letterSpacing: '0.51px',
  },
  button2: {
    fontFamily: 'Roboto-Medium',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: 1,
    letterSpacing: '0.45px',
  },
  caption: {
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    fontSize: '0.75rem',
    lineHeight: 1.33,
    letterSpacing: '0.4px',
  },
  overline1: {
    fontFamily: 'Roboto-Medium',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '2.4px',
  },
  overline2: {
    fontFamily: 'Roboto-Medium',
    fontWeight: '500',
    fontSize: '0.625rem',
    lineHeight: 1.6,
    letterSpacing: '1.5px',
  },
  quoteSm: {
    fontFamily: 'Poppins-Light',
    fontWeight: '300',
    fontSize: 24,
    lineHeight: 1.875,
    // letterSpacing: '0.23px',
  },
  quoteMd: {
    fontFamily: 'Poppins-Light',
    fontWeight: '300',
    fontSize: 36,
    lineHeight: 1.55,
    // letterSpacing: '.08em',
  },
  quoteLg: {
    fontFamily: 'Poppins-Light',
    fontWeight: '300',
    fontSize: 56,
    lineHeight: 1.41,
    // letterSpacing: '0.23px',
  },
  statistic: {
    fontFamily: 'Roboto-Light',
    fontWeight: '300',
    fontSize: '5.25rem',
    lineHeight: 1.4,
    letterSpacing: '-3.61px',
  },
  iconLabel: {
    fontFamily: 'Roboto-Medium',
    fontWeight: '500',
    fontSize: '1.25rem',
    lineHeight: 1.3,
    letterSpacing: '0.38px',
  },
});

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subheading1: React.CSSProperties;
    subheading2: React.CSSProperties;
    subtitle1: React.CSSProperties;
    subtitle2: React.CSSProperties;
    body3: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
    overline1: React.CSSProperties;
    overline2: React.CSSProperties;
    quoteSm: React.CSSProperties;
    quoteMd: React.CSSProperties;
    quoteLg: React.CSSProperties;
    caption: React.CSSProperties;
    statistic: React.CSSProperties;
    iconLabel: React.CSSProperties;
    fouxH1: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subheading1: React.CSSProperties;
    subheading2: React.CSSProperties;
    subtitle1: React.CSSProperties;
    subtitle2: React.CSSProperties;
    body3: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
    overline1: React.CSSProperties;
    overline2: React.CSSProperties;
    quoteSm: React.CSSProperties;
    quoteMd: React.CSSProperties;
    quoteLg: React.CSSProperties;
    caption: React.CSSProperties;
    statistic: React.CSSProperties;
    iconLabel: React.CSSProperties;
    fouxH1: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subheading1: true;
    subheading2: true;
    subtitle1: true;
    subtitle2: true;
    body3: true;
    button: false,
    button1: true;
    button2: true;
    overline: false;
    overline1: true;
    overline2: true;
    quote: false;
    quoteSm: true;
    quoteMd: true;
    quoteLg: true;
    caption: true;
    statistic: true;
    iconLabel: true;
    fouxH1: true;
  }
}
