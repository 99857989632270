import { Typography } from '@mui/material';
import React from 'react';

export type TimeProps = {
  time: string,
};

const Time = ({ time }: TimeProps) => {
  const number = time.match(/([0-9])+/g)?.[0];
  const meridiem = time.match(/([A-Z])+/g)?.[0];

  return (
    <>
      {number}
      <Typography variant="body3">
        {meridiem}
      </Typography>
    </>
  );
};

export default Time;
