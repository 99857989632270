import React from 'react';
import {
  Box,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import type { ContentfulAlignment } from '#hooks/useContentfulAlignment';
import useContentfulAlignment from '#hooks/useContentfulAlignment';
import type { GatsbyImageProps } from '#contentful/Image';
import type { ColorIndex } from '#components/Layout/theme/palette.theme';

import GridItemText from '#components/GridItemText';
import BackgroundImage from '#components/BackgroundImage';
import Form from '#contentful/Form';
import Image from '#contentful/Image';
import SectionSettings from '#components/Section/SectionSettings';
import useSectionSpacing from '#hooks/useSectionSpacing';
import type { ButtonDefinition } from '#contentful/Button';
import Button from '#contentful/Button';
import useOverlay from './_useOverlay';
import useBgImage from './_useBgImage';
import useDimentions from './_useDimentions';
import useFontColors from './_useFontColors';

function getFontSizeValue(option: string | undefined): string {
  if (!option) {
    return '4.0rem';
  }

  const regex = /\((\d+(\.\d+)?)rem\)/;
  const match = option.match(regex);

  if (match) {
    return `${match[1]}rem`;
  }

  return '4.0rem';
}

export type HeroProps = {
  alignment: ContentfulAlignment,
  overline?: string,
  headline: string,
  titleFontSize?: string,
  subtitle?: string,
  info?: string,
  image?: GatsbyImageProps,
  maxTextWidth?: string | null,
  className?: string,
  fontColor?: ColorIndex,
  addOverlay?: boolean,
  isSectionTallest?: boolean,
  extendContentWidth?: boolean,
  noTopMargin?: boolean,
  loading?: boolean,
  rightSection?: any,
  button?: ButtonDefinition,
};

const Hero = ({
  alignment,
  overline,
  headline,
  titleFontSize,
  subtitle,
  info,
  image,
  maxTextWidth = '32rem',
  className,
  fontColor = 'implightblue100',
  addOverlay = false,
  isSectionTallest = false,
  extendContentWidth = false,
  noTopMargin = false,
  loading,
  rightSection,
  button,
}: HeroProps) => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('lg'));
  const headLineFontsiZe = getFontSizeValue(titleFontSize);

  const dimentions = useDimentions(isSectionTallest);
  const bgImage = useBgImage(dimentions, image);
  const { textColor, subheadingColor } = useFontColors(fontColor);
  const { ColorOverlay } = useOverlay();

  const textAlignmentDefault = 'left';
  const itemsAlignment = useContentfulAlignment(alignment);
  const maxWidth = maxTextWidth ?? '100%';

  const calculateWidth = () => {
    if (extendContentWidth) {
      return '70%';
    }
    return rightSection ? '50%' : '50rem';
  };

  //  Calculate section settings
  const responsiveSectionSpacing = useSectionSpacing(
    {
      ...rightSection?.sectionSpacing,
    },
    {
      mtmd: 1,
      mbmd: 1,
      mtlg: 2,
      mblg: 2,
      mllg: 0,
      mrlg: 0,
      ptmd: 1,
      pbmd: 1,
      plmd: 0,
      prmd: 0,
      ptlg: 2,
      pblg: 2,
      pllg: 2,
      prlg: 2,
      plxl: 8,
      prxl: 8,
      columnSpacinglg: 4,
    },
  );

  const sectionSettings = {
    ...responsiveSectionSpacing,
    ...rightSection?.sectionStyling?.background,
    ...rightSection?.sectionStyling,
  };

  return (
    <Grid
      container
      direction={{
        xs: 'column',
        lg: 'row',
      }}
      justifyContent={itemsAlignment}
      alignItems={{ xs: 'flex-start', lg: 'center' }}
      className={className}
      sx={{
        position: 'relative',
        marginTop: noTopMargin ? 0 : { xs: 7, sm: 8 },
        minHeight: {
          xs: 288,
          md: isSectionTallest ? 530 : 100,
          lg: isSectionTallest ? 636 : 400,
        },
      }}
    >

      <BackgroundImage bgImage={bgImage} />

      {addOverlay && (
      <ColorOverlay
        sx={{
          position: 'absolute',
          backgroundImage: palette.gradient?.bluegreenlefttoright,
          zIndex: 15,
        }}
      />
      )}

      <Grid
        container
        item
        direction="column"
        justifyContent="flex-end"
        rowSpacing={1}
        sx={{
          width: {
            xs: '100%',
            sm: '80%',
            lg: '70%',
            xl: calculateWidth(),
          },
          pt: 5,
          pb: 6,
          pl: { xs: 2, lg: 10, xl: 17 },
          pr: { xs: 3, lg: 2 },
          zIndex: 20,
          position: 'relative',
        }}
      >

        {(loading || overline) && (
        <GridItemText
          variant="overline1"
          text={overline?.toUpperCase()}
          loading={loading}
          typographyProps={{
            textAlign: textAlignmentDefault,
            color: subheadingColor,
            sx: { zIndex: 20 },
          }}
        />
        )}

        {(loading || headline) && (
        <GridItemText
          variant="h1"
          text={headline}
          loading={loading}
          typographyProps={{
            textAlign: textAlignmentDefault,
            color: textColor,
            fontSize: `${headLineFontsiZe}`,
          }}
        />
        )}

        {(loading || subtitle) && (
        <GridItemText
          variant="subheading1"
          text={subtitle}
          loading={loading}
          typographyProps={{
            textAlign: textAlignmentDefault,
            color: subheadingColor,
            maxWidth,
          }}
        />
        )}

        {(loading || info) && (
        <GridItemText
          variant="subheading1"
          text={info}
          loading={loading}
          typographyProps={{
            textAlign: textAlignmentDefault,
            color: subheadingColor,
            maxWidth,
            mt: 2.5,
          }}
        />
        )}

        {button && (
        <Box mt={4}>
          <Button content={button} />
        </Box>
        )}

      </Grid>
      {!isMobile && rightSection
        && (
        <Grid
          item
          width={{
            xs: '100%',
            lg: '30%',
            xl: '50%',
          }}
          sx={{
            position: 'relative',
            zIndex: 20,
          }}
        >
          <SectionSettings {...sectionSettings}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
              }}
              alignItems={sectionSettings?.responsiveAlignItems}
              justifyContent={sectionSettings?.responsiveJustifyContent}
            >
              {rightSection?.component?.internal?.type === 'ContentfulComponentImage' && (
              <Image content={rightSection.component} />
              )}
              {rightSection?.component?.internal?.type === 'ContentfulComponentForm' && (
              <Form content={rightSection.component} />
              )}
            </Box>
          </SectionSettings>
        </Grid>
        )}
    </Grid>
  );
};

export default styled(Hero)({});
