import type { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/material';

export const viewport = {
  mobile: 'xs',
  smallTablet: 'sm',
  largeTablet: 'md',
  laptop: 'lg',
  desktop: 'xl',
};

/**
 * Return a string with the viewport name (mobile, tablet, desktop, etc.)
 * based on the viewport width.
 *
 * It can be used to get the viewport name at load time or
 * as a dependecy of useEffect or useMemo to get the viewport
 * name every time the viewport is resized.
 */
export default (): string => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const isLaptop = useMediaQuery((theme: Theme) => theme.breakpoints.between('lg', 'xl'));
  const isLargeTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));
  const isSmallTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));

  if (isDesktop) {
    return viewport.desktop;
  }
  if (isLaptop) {
    return viewport.laptop;
  }
  if (isLargeTablet) {
    return viewport.largeTablet;
  }
  if (isSmallTablet) {
    return viewport.smallTablet;
  }
  return viewport.mobile;
};
