import { useMediaQuery, useTheme } from '@mui/material';

import {
  ColorBackground,
  MobileColorOverlayBackground,
  TabletColorOverlayBackground,
} from './styles';

type Overlay = {
  ColorOverlay: any,
};

export default (): Overlay => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('xl'));
  const isLaptop = useMediaQuery(breakpoints.between('lg', 'xl'));
  const isLargeTablet = useMediaQuery(breakpoints.between('md', 'lg'));

  if (isDesktop || isLaptop) {
    return {
      ColorOverlay: ColorBackground,
    };
  }
  if (isLargeTablet) {
    return {
      ColorOverlay: TabletColorOverlayBackground,
    };
  }
  return {
    ColorOverlay: MobileColorOverlayBackground,
  };
};
