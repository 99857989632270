import React from 'react';
import {
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

import ClassSchedule from '#components/ClassSchedule/ClassSchedule';
import SmartLink from '#components/SmartLink';

export type UserGroupCardDefinition = UserGroupCardProps & {
  id: string,
  internal: {
    type: 'ContentfulUserGroupMeeting',
  },
};

export type UserGroupCardProps = {
  name: string,
  url: string,
  location?: string,
  start: Date,
  end?: Date,
};

const UserGroupCard = ({
  name,
  location,
  start,
  end,
  url,
}: UserGroupCardProps) => {
  const { palette } = useTheme();

  return (
    <SmartLink
      href={url}
      openInNewWindow
      sx={{ textDecoration: 'none' }}
    >
      <Grid
        container
        direction="column"
        rowSpacing={2.5}
        className="hover-scale transition-ease-1"
        sx={{
          minHeight: 200,
          maxWidth: 400,
          my: 0,
          mx: 'auto',
          pt: 2,
          pb: 4.5,
          pl: 2.5,
          pr: 4,
          borderRadius: '12px',
          boxShadow: palette.shadow?.darker,
        }}
      >
        <Grid item>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              textDecoration: url ? 'underline' : 'none',
            }}
            color={palette.color?.impblue500}
          >
            {name}
          </Typography>
        </Grid>

        {location && (
        <Grid item>
          <Typography variant="subtitle2">
            {location}
          </Typography>
        </Grid>
        )}

        <Grid item>
          <ClassSchedule
            startDateTime={start}
            endDateTime={end}
          />
        </Grid>
      </Grid>
    </SmartLink>
  );
};

export default UserGroupCard;
