import React from 'react';
import {
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ClassSchedule from '#components/ClassSchedule/ClassSchedule';
import SmartLink from '#components/SmartLink';
import Button from '#components/Button';
import useContactPageData from '#hooks/useContactPageData';

export type CourseCardDefinition = {
  title: string,
  start?: string,
  end?: string,
  duration?: string,
  slug?: string,
  registrationUrl?: string,
};

export type CourseCardProps = CourseCardDefinition;

const translationKey = 'Components.CourseCard';

const CourseCard = ({
  title,
  start,
  end,
  duration,
  slug,
  registrationUrl,
}: CourseCardProps) => {
  const { palette, border } = useTheme();

  const { slug: contactPageSlug } = useContactPageData().page;

  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      minHeight={{ xs: 282, md: 340, lg: 282 }}
      maxWidth={400}
      sx={{
        pt: 2,
        pb: 4.5,
        pl: 2.5,
        pr: 4,
        boxShadow: palette.shadow?.darker,
        borderRadius: border.section,
      }}
    >
      <Grid item>
        <SmartLink
          slug={slug}
          sx={{ textDecoration: 'none' }}
        >
          <Typography
            variant="h4"
            color="color.impblue500"
            sx={{ fontWeight: 'bold' }}
          >
            {title}
          </Typography>
        </SmartLink>
      </Grid>

      <Grid item>
        <ClassSchedule
          startDateTime={start}
          endDateTime={end}
          duration={duration}
        />
      </Grid>

      <Grid item>
        {registrationUrl ? (
          <Button variant="primary" href={registrationUrl}>
            {t(`${translationKey}.RegisterButton`)}
          </Button>
        ) : (
          <Button variant="primary" slug={contactPageSlug}>
            {t(`${translationKey}.ContactUsButton`)}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default CourseCard;
