import React from 'react';
import {
  Grid,
  Container,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';

import RichText from '#components/RichText';
import SocialSharing from '#contentful/SocialSharing';

import useQueryFooterData from './_useQueryFooterData';
import legalOptions from './_legalOptions';
import BrandBlock from './_BrandBlock';
import type { FooterColumnDefinition } from './_FooterColumns';
import FooterColumns from './_FooterColumns';

const StyledFooter = styled(Grid)({
  '&.MuiGrid-root': {
    marginTop: '0',
  },
});

const Footer = () => {
  const {
    motto,
    improvingSocials,
    farLeftColumn,
    middleLeftColumn,
    middleRightColumn,
    farRightColumn,
    legalLinks,
  } = useQueryFooterData();
  const { breakpoints, palette } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  const columns: FooterColumnDefinition[] = [
    {
      content: farLeftColumn,
      id: 'outer-left-footer-column',
    },
    {
      content: middleLeftColumn,
      id: 'inner-left-footer-column',
    },
    {
      content: middleRightColumn,
      id: 'inner-right-footer-column',
    },
    {
      content: farRightColumn,
      id: 'outer-right-footer-column',
    },
  ];

  return (
    <Container
      maxWidth="xl"
      className="footer-main"
      sx={{
        color: palette.color?.impgray100,
        px: { xs: '5%', md: 4, lg: 5 },
        pb: 7,
        pt: { xs: 7, md: 4 },
      }}
    >
      <StyledFooter
        container
        justifyContent="space-between"
        mb={{ xs: 8, md: 13 }}
      >
        {isDesktop && (
          <Grid
            item
            md={6}
            lg={4}
            mt={{ lg: 4 }}
          >
            <BrandBlock
              motto={motto}
              socials={improvingSocials}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          lg={7}
          mt={{ xs: 4, lg: 8 }}
        >
          <FooterColumns columns={columns} />
        </Grid>
      </StyledFooter>

      {!isDesktop && (
        <Box mb={4} maxWidth={240} mx="auto">
          <SocialSharing {...improvingSocials} />
        </Box>
      )}

      <Box textAlign="center">
        <RichText
          content={legalLinks}
          options={legalOptions}
        />
      </Box>
    </Container>
  );
};

export default Footer;
