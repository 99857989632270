import type { ColumnSettingOptions } from './types';

const defaults: ColumnSettingOptions = {
  ptxs: 0,
  pbxs: 0,
  prxs: 0,
  plxs: 0,
  ptmd: 0,
  pbmd: 0,
  prmd: 0,
  plmd: 0,
  verticalAlignment: 'top',
  horizontalAlignment: 'full width',
  justifyContentxs: 'flex-start',
  alignItemsxs: 'stretch',
};

export default defaults;
