import { useMediaQuery } from '@mui/material';
import type { Theme, Breakpoint } from '@mui/material';

import type { SpacingValue } from '#components/Layout/theme/spacing.theme';

export type ResponsiveSpacing = SpacingValue | undefined;

type Breakpoints = {
  [K in Breakpoint ]: SpacingValue
};

export default (breakpoints: Partial<Breakpoints>): ResponsiveSpacing => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const isLaptop = useMediaQuery((theme: Theme) => theme.breakpoints.between('lg', 'xl'));
  const isLargeTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));
  const isSmallTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));

  if (isDesktop) {
    return breakpoints.xl ?? breakpoints.lg ?? breakpoints.md ?? breakpoints.sm ?? breakpoints.xs;
  }
  if (isLaptop) {
    return breakpoints.lg ?? breakpoints.md ?? breakpoints.sm ?? breakpoints.xs;
  }
  if (isLargeTablet) {
    return breakpoints.md ?? breakpoints.sm ?? breakpoints.xs;
  }
  if (isSmallTablet) {
    return breakpoints.sm ?? breakpoints.xs;
  }
  return breakpoints.xs;
};
