import React, {
  useEffect, useState, useContext,
} from 'react';
import {
  Grid, Box, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { GeolocationContext } from '#providers/GeolocationProvider';
import RegionSelector from '#components/RegionSelector';
import WorldMap from '#components/WorldMap';
import NextClassDesktop from '#components/NextClassDesktop';
import NextClassMobile from '#components/NextClassMobile';
import type { FlatClassData } from '#utils/flattenArloEventIntoClassData';
import {
  first, flow, get, filter, map,
} from 'lodash/fp';
import { getEvents } from '#services/arlo';
import flattenArloEventIntoClassData from '#utils/flattenArloEventIntoClassData';
import useLocationPages from '#hooks/useLocationPages';
import useTrainingProfilePages from '#hooks/useTrainingProfilePages';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import type { ButtonDefinition } from '#contentful/Button';

export type CourseNextClassDefinition = ContentfulComponentDefinition & {
  arloCourseTemplateId: number;
  defaultDate: string;
  defaultLocation: string;
  defaultInstructor: string;
  defaultStartingAt: string;
  defaultStartingAtMxn: string;
  defaultStartingAtCad: string;
  defaultStartingAtGbp: string;
  defaultButton: ButtonDefinition;
  internal: {
    type: 'ContentfulComponentCourseNextClass'
  }
};

interface CourseNextClassProps {
  content: CourseNextClassDefinition;
}

const translationKey = 'Pages.CoursePage';

const CourseNextClass: React.FC<CourseNextClassProps> = ({
  content: {
    arloCourseTemplateId,
    defaultDate,
    defaultLocation,
    defaultInstructor,
    defaultStartingAt,
    defaultStartingAtMxn,
    defaultStartingAtCad,
    defaultStartingAtGbp,
    defaultButton,
  },
}: CourseNextClassProps) => {
  const { t } = useTranslation();
  const { region } = useContext(GeolocationContext);
  const theme = useTheme();
  const isLargeTablet = useMediaQuery(theme.breakpoints.up('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const { language } = useI18next();
  const locationPages = useLocationPages(language);
  const trainingProfilePages = useTrainingProfilePages(language);
  const [events, setEvents] = useState<FlatClassData[]>([]);

  useEffect(() => {
    if (arloCourseTemplateId) {
      getEvents({}, { skip: 0, top: 100 }, region ?? undefined)
        .then(
          flow(
            get('Items'),
            filter((i) => i.EventTemplateID === arloCourseTemplateId),
            map(flattenArloEventIntoClassData(locationPages, trainingProfilePages)),
            setEvents,
          ),
        );
    }
  }, [arloCourseTemplateId, locationPages, region, trainingProfilePages]);

  const getDefaultStartingAt = () => {
    switch (region) {
      case 'mx':
        return defaultStartingAtMxn;
      case 'ca':
        return defaultStartingAtCad;
      case 'uk':
        return defaultStartingAtGbp;
      default:
        return defaultStartingAt;
    }
  };

  const defaults = {
    defaultDate,
    defaultLocation,
    defaultInstructor,
    defaultStartingAt: getDefaultStartingAt(),
    defaultButton,
  };

  return (
    <Grid item xs={12}>
      <Box
        height={{ xs: 'auto' }}
        py={{ xs: 2, lg: 5 }}
      >
        <Grid
          item
          container
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          rowSpacing={2}
          py={{ xs: 0, lg: 5 }}
          px={{ xs: 2.5, lg: 6, xl: 10 }}
          sx={{
            background: theme.palette.color?.impgreen50,
          }}
        >
          <Grid item>
            <Typography variant={isLargeTablet ? 'h3' : 'h4'}>
              {t(`${translationKey}.NextClass`)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={6}
            container
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          >
            <Grid item>
              <RegionSelector defaultValue={region} />
            </Grid>
            {isDesktop && (
              <Grid item>
                <WorldMap />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <div
              id="course-next-class"
              style={{ position: 'relative', top: -270 }}
            />
            {isLargeTablet ? (
              <NextClassDesktop class={first(events)!} defaults={defaults} />
            ) : (
              <NextClassMobile class={first(events)!} defaults={defaults} />
            )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default CourseNextClass;
