import type { ChangeEvent } from 'react';
import React, { useCallback } from 'react';
import { InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { cn } from '@/utils';

export type SearchBarProps = {
  value: string,
  onChange: (
    search: string,
    event: ChangeEvent<HTMLInputElement>
  ) => any,
  inputAriaLabel: string,
  className?: string,
};

const translationKey = 'Components.SearchBar';

const SearchBar = ({
  value,
  onChange,
  inputAriaLabel,
  className,
}: SearchBarProps) => {
  const { t } = useTranslation();

  const onChangeEvent = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => (
      onChange(e.target.value, e)
    ),
    [onChange],
  );

  return (
    <Paper className={cn('px-2', className)}>
      <InputBase
        placeholder={t(`${translationKey}.SearchPlaceholder`) as string}
        inputProps={{ 'aria-label': inputAriaLabel }}
        onChange={onChangeEvent}
        value={value}
        fullWidth
      />
      <SearchIcon />
    </Paper>
  );
};

export default SearchBar;
