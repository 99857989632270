import React from 'react';
import { Box } from '@mui/material';
import type { ButtonDefinition } from '#contentful/Button';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import type { TextButtonDefinition } from '#contentful/TextButton';
import ButtonGroupComponent from './ButtonGroupComponent';

export type ButtonGroupDefinition = ContentfulComponentDefinition & {
  buttons: ButtonDefinition[] | TextButtonDefinition[],
  gap:number,
  internal: {
    type: 'ContentfulComponentButtonGroup'
  },
};

export type ButtonGroupProps = {
  content: ButtonGroupDefinition,
};

/**
 * Contentful Content Model Name: "Component: Button Group"
 *
 * URL: https://app.contentful.com/spaces/0vvalmm98slw/content_types/componentButtonGroup/fields
 */
const ButtonGroup = ({
  content: {
    buttons,
    gap = 16,
  },
}: ButtonGroupProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: `${gap}px`,
    }}
  >
    {buttons.map((button) => (
      <ButtonGroupComponent key={button.id} content={button} />
    ))}
  </Box>
);

export default ButtonGroup;
