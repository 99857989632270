import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'gatsby-plugin-react-i18next';
import { slashySlug } from '#components/SmartLink';
import { getFontSize, type FontSizeValue } from '#utils/convertFintSize';
import clsx from 'clsx';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';

export type TextCardDefinition = ContentfulComponentDefinition & {
  title: string,
  background?: {
    value: string;
  }
  textColor?: {
    value: string;
  }
  link?: {
    slug: string
  },
  props: any,
  cornerRounding?: 'None' | 'Rounded';
  hoverAnimation?: 'None' | 'Expand';
  id?: string,
  fontSize: FontSizeValue
  internal: {
    type: 'ContentfulSubComponentTextCard'
  }
};

export type TextCardProps = {
  content: TextCardDefinition
};

const TextCard = ({
  content,
}: TextCardProps) => {
  const {
    title,
    background,
    cornerRounding,
    hoverAnimation,
    link,
    textColor,
    props,
    fontSize,
  } = content;

  const fontSizeValue = getFontSize(fontSize ?? 'X-Small (0.75rem)');

  return (
    <Link
      className={
        clsx(
          'h-10 w-32 flex flex-1 items-center justify-center no-underline',
          cornerRounding === 'Rounded' && 'rounded',
          hoverAnimation === 'Expand' && 'transition-transform duration-500 transform hover:scale-105',
        )
      }
      to={slashySlug(link?.slug)}
      style={{
        lineHeight: 2, backgroundColor: background?.value || 'white',
      }}
      {...props}
    >
      <Typography variant="h4" className={`mb-0 pt-0 pb-0 ${fontSizeValue}`} color={textColor?.value ?? 'white'}>
        {title}
      </Typography>
    </Link>
  );
};

export default TextCard;
