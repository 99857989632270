import React from 'react';
import type { GridRenderCellParams } from '@mui/x-data-grid';
import type { TFunction } from 'i18next';

import type { DataTableColDef } from '#components/DataTable';
import TableHeaderCell from '#components/DataTable/TableHeaderCell';
import Button from '#components/Button';
import LinkCell from '#components/DataTable/LinkCell';

import type { TrainingCourseCardDefinition } from '#contentful/ContentfulCard/TrainingCourseCard';

const translationKey = 'Components.CourseTable.Columns';

const columns: (t: TFunction) => DataTableColDef[] = (t) => ([
  {
    field: 'title',
    headerName: 'Name',
    flex: 5,
    minWidth: 400,
    searchable: true,
    hideable: false,
    renderHeader: () => <TableHeaderCell title={t(`${translationKey}.Course`)} />,
    valueGetter: ({ row }: GridRenderCellParams<TrainingCourseCardDefinition>) => row?.title,
    valueField: 'title',
    renderCell: ({ row }: GridRenderCellParams<TrainingCourseCardDefinition>) => {
      const slug = row?.pageTemplate?.[0]?.composePage?.[0]?.slug;

      return (
        <LinkCell
          minWidth={400}
          slug={slug}
        >
          {row.title}
        </LinkCell>
      );
    },
  },
  {
    field: 'gotopage',
    headerName: '',
    flex: 1,
    minWidth: 180,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }: GridRenderCellParams<TrainingCourseCardDefinition>) => {
      const slug = row.pageTemplate?.[0]?.composePage?.[0]?.slug;

      return (
        <Button
          variant="primary"
          slug={slug}
          isInTable
        >
          {t(`${translationKey}.CourseDetailsButton`)}
        </Button>
      );
    },
  },
]);

export default columns;
