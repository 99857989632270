import React, { useEffect, useState } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';

import type {
  MultiSelectOptionDefinition,
  MultiSelectProps,
} from '../types';

const FormFieldDropdown = ({
  label,
  name = '',
  required = false,
  options,
  reset,
}: MultiSelectProps) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
  }, [reset]);

  const handleChange = (
    event: SelectChangeEvent<typeof value>,
  ) => {
    setValue(event.target.value);
  };

  return (
    <FormControl
      variant="filled"
      fullWidth
      required={required}
    >
      <InputLabel id={`${name}-select-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}-select-label`}
        id={`${name}-select`}
        name={name}
        value={value}
        onChange={handleChange}
        required={required}
      >
        {options.map((option: MultiSelectOptionDefinition) => (
          <MenuItem
            key={option.id}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FormFieldDropdown;
