import React from 'react';

import Text from '#contentful/Text';
import Image from '#contentful/Image';
import Button from '#contentful/Button';
import IconList from '#components/IconList';

import type { TextDefinition } from '#contentful/Text';
import type { ImageDefinition } from '#contentful/Image';
import type { ButtonDefinition } from '#contentful/Button';
import type { IconListDefinition } from '#components/IconList/IconList';
import type { IconButtonDefinition } from '#contentful/IconButton';
import IconButton from '#contentful/IconButton';

export type ExpandableColumnComponentDefinition = (
  TextDefinition
  | ImageDefinition
  | ButtonDefinition
  | IconListDefinition
  | IconButtonDefinition
);

export type ExpandableColumnComponentProps = {
  content: ExpandableColumnComponentDefinition,
};

const ExpandableColumnComponent = ({
  content,
}: ExpandableColumnComponentProps) => {
  if (!content.internal?.type) {
    return null;
  }

  switch (content.internal?.type) {
    case 'ContentfulComponentText':
      return (
        <Text
          key={content.id}
          content={content as TextDefinition}
          sx={{ px: { xs: 2, md: 0 } }}
        />
      );
    case 'ContentfulComponentImage':
      return <Image key={content.id} content={content as ImageDefinition} />;
    case 'ContentfulComponentButton':
      return (
        <Button
          key={content.id}
          content={content as ButtonDefinition}
          sx={{ px: { xs: 2, md: 0 } }}
        />
      );
    case 'ContentfulComponentIconList':
      return (
        <IconList
          key={content.id}
          {...content as IconListDefinition}
        />
      );
    case 'ContentfulComponentIconButton':
      return (
        <IconButton
          key={content.id}
          {...content as IconButtonDefinition}
        />
      );
    default:
      console.error(`Unexpected component type: ${JSON.stringify(content)}`);
      return null;
  }
};

export default ExpandableColumnComponent;
