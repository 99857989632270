import type { BlogCategoryDefinition } from '#components/CardHolder/types';

import type { AnyContentfulCardDefinition } from '../ContentfulCard';
import queryLatestBlogs from './_queryLatestBlogs';
import queryLatestCaseStudies from './_queryLatestCaseStudies';
import queryLatestNews from './_queryLatestNews';

export default (
  contentType: string,
  language: string,
  categoryFilter?: BlogCategoryDefinition,
): AnyContentfulCardDefinition[] => {
  switch (contentType.toLowerCase()) {
    case 'blogs':
      return queryLatestBlogs(
        language,
        categoryFilter?.contentfulId,
      ) as AnyContentfulCardDefinition[];
    case 'newsroom':
      return queryLatestNews(language);
    case 'case studies':
      return queryLatestCaseStudies(language);
    default:
      throw new Error(`Unable to read content type: ${contentType}`);
  }
};
