import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Button from '#components/Button';

export type WebinarCtaButtonProps = {
  isFutureWebinar: boolean,
  registrationUrl?: string,
  videoUrl?: string,
};

const translationKey = 'Components.WebinarCtaButton';

const WebinarCtaButton = ({
  isFutureWebinar,
  registrationUrl,
  videoUrl,
}: WebinarCtaButtonProps) => {
  const { t } = useTranslation();

  if (isFutureWebinar) {
    return (
      <Button
        variant="secondary"
        href={registrationUrl}
      >
        {t(`${translationKey}.RegisterButton`)}
      </Button>
    );
  }

  if (videoUrl) {
    return (
      <Button
        variant="secondary"
        href={videoUrl}
      >
        {t(`${translationKey}.WatchButton`)}
      </Button>
    );
  }

  return null;
};

export default WebinarCtaButton;
