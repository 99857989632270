import React from 'react';

import type { ButtonDefinition } from '#contentful/Button/Button';
import type { TextButtonDefinition } from '#contentful/TextButton';

import Button from '#contentful/Button/Button';
import TextButton from '#contentful/TextButton';

export type ButtonGroupComponentDefinition = (
  TextButtonDefinition
  | ButtonDefinition
);

export type ButtonGroupComponentProps = {
  content: ButtonGroupComponentDefinition,
};

const ButtonGroupComponent = ({
  content,
}: ButtonGroupComponentProps) => {
  if (!content.internal?.type) {
    return null;
  }

  switch (content.internal?.type) {
    case 'ContentfulComponentButton':
      return (
        <Button
          content={content as ButtonDefinition}
          sx={{ px: { xs: 2, md: 0 } }}
        />
      );
    case 'ContentfulComponentTextButton':
      return (
        <TextButton
          key={content.id}
          content={content as TextButtonDefinition}
          sx={{ px: { xs: 2, md: 0 } }}
        />
      );
    default:
      console.error(`Unexpected component type: ${JSON.stringify(content)}`);
      return null;
  }
};

export default ButtonGroupComponent;
