export type FontSizeValue = 'X-Small (0.75rem)' | 'Small (1.0rem)' | 'Medium (1.25rem)' | 'Large (1.50rem)' | 'X-Large (1.75rem)';

export const getFontSize = (value?: FontSizeValue) => {
  switch (value) {
    case 'X-Small (0.75rem)':
      return 'text-xs';
    case 'Small (1.0rem)':
      return 'text-base';
    case 'Medium (1.25rem)':
      return 'text-xl';
    case 'Large (1.50rem)':
      return 'text-2xl';
    case 'X-Large (1.75rem)':
      return 'text-[1.75rem]';
    default:
      return 'text-2xl';
  }
};
