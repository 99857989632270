import React from 'react';

import type { IconButtonDefinition } from '#contentful/IconButton';
import IconButton from '#contentful/IconButton';

export type ImageCarouselComponentDefinition = (
  IconButtonDefinition
);

export type ImageCarouselComponentProps = {
  content: ImageCarouselComponentDefinition,
};

const ImageCarouselComponent = ({
  content,
}: ImageCarouselComponentProps) => {
  if (!content.internal?.type) {
    return null;
  }

  switch (content.internal?.type) {
    case 'ContentfulComponentIconButton':
      return (
        <IconButton {...content as IconButtonDefinition} />
      );
    default:
      console.error(`Unexpected component type: ${JSON.stringify(content)}`);
      return null;
  }
};

export default ImageCarouselComponent;
