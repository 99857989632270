import React, { useMemo } from 'react';
import {
  Divider, Grid, Typography,
} from '@mui/material';
import {
  flow,
  filter,
  reverse,
  isEmpty,
  orderBy,
} from 'lodash/fp';

import type { ContentfulWebinarCardDefinition } from '#components/WebinarCard/WebinarCard';
import type { LinkDefinition } from '#contentful/Link';
import ContentfulLink from '#contentful/Link';
import renderCardSet from '#components/CardHolder/renderCardSet';

import { useI18next } from 'gatsby-plugin-react-i18next';
import queryLatestWebinars from './_queryLatestWebinars';
import StyledWidthGrid from './styles';

export type WebinarListingOptions = {
  futureHeading?: string,
  futureSubheading?: string,
  futureCardCount?: number,
  pastHeading?: string,
  pastSubheading?: string,
  pastCardCount?: number,
  linkToMoreContent?: LinkDefinition,
};

export type WebinarCardHolderProps = WebinarListingOptions;

const WebinarCardHolder = ({
  futureHeading,
  futureSubheading,
  futureCardCount = 6,
  pastHeading,
  pastSubheading,
  pastCardCount = 3,
  linkToMoreContent,
}: WebinarCardHolderProps) => {
  const { language } = useI18next();

  const data = queryLatestWebinars().all.cards;

  const cards = useMemo(() => filter(
    ({ nodeLocale: language }),
    data,
  ), [language, data]);

  const futureCards = useMemo(
    () => {
      const filteredCards = flow(
        filter((c: ContentfulWebinarCardDefinition) => (
          Date.parse(c.startDateTime) > Date.now()
        )),
        orderBy('startDateTime', 'desc'),
        reverse,
      )(cards);

      return renderCardSet(futureCardCount)(filteredCards);
    },
    [cards, futureCardCount],
  );

  const pastCards = useMemo(
    () => {
      const filteredCards = flow(
        filter((c: ContentfulWebinarCardDefinition) => (
          Date.parse(c.startDateTime) < Date.now())),
      )(cards);

      return renderCardSet(pastCardCount)(filteredCards);
    },
    [cards, pastCardCount],
  );

  const areThereFutureCards: boolean = !isEmpty(futureCards);

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <StyledWidthGrid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          {futureHeading && (
            <Grid item px={1}>
              <Typography variant="h2">
                {futureHeading}
              </Typography>
            </Grid>
          )}

          {futureSubheading && (
            <Grid
              item
              mb={6}
              px={1}
            >
              <Typography
                variant="subheading2"
                sx={{
                  maxWidth: '46rem',
                  display: 'block',
                }}
              >
                {futureCards.length > 0 ? (
                  futureSubheading
                ) : (
                  <>
                    {'We don\'t have any webinars scheduled right now.'}
                    <br />
                    Check back with us to see what new topics we have in store.
                  </>
                )}
              </Typography>
            </Grid>
          )}
        </StyledWidthGrid>

        <Grid
          item
          container
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'stretch', md: 'flex-start' }}
          rowSpacing={1}
          textAlign="left"
          mb={areThereFutureCards ? 10 : 0}
        >
          {futureCards}
        </Grid>
      </Grid>

      {!areThereFutureCards
      && (
      <Grid my={3} item>
        <Divider />
      </Grid>
      ) }

      {pastCards && (
        <Grid
          mt={areThereFutureCards ? 0 : 0}
          container
          direction="column"
          alignItems="center"
          textAlign="center"
        >
          <StyledWidthGrid
            container
            item
            className="debugger"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {pastHeading && (
            <Grid item px={1}>
              <Typography variant="h2">
                {pastHeading}
              </Typography>
            </Grid>
            )}

            {pastSubheading && (
            <Grid item mb={2} px={1}>
              <Typography
                variant="subheading2"
                sx={{
                  maxWidth: '46rem',
                  display: 'block',
                }}
              >
                {pastSubheading}
              </Typography>
            </Grid>
            )}
          </StyledWidthGrid>
          {linkToMoreContent && (
            <Grid
              item
              alignSelf="end"
              mr={{ xs: 2, md: 3.5 }}
              mb={2.5}
            >
              <ContentfulLink content={linkToMoreContent}>
                {linkToMoreContent.label}
              </ContentfulLink>
            </Grid>
          )}
          <Grid
            item
            container
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'stretch', md: 'flex-start' }}
            rowSpacing={1}
            textAlign="left"
            mb={10}
          >
            {pastCards}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default WebinarCardHolder;
