import React from 'react';
import {
  styled,
  type AccordionSummaryProps,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export default styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon />}
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(45deg)',
  },
}));
