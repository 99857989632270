import arloClient from './arlo';
import type { ArloPagedRequest, ArloRegionCodes } from './models/arlo';
import type { ArloCourseData } from './models/courses';

export default (
  arloId: number,
  region: ArloRegionCodes = 'us',
  pagedRequest: ArloPagedRequest = { skip: 0, top: 100 },
) => {
  const arloParams = new URLSearchParams([
    ['top', `${pagedRequest.top}`],
    ['skip', `${pagedRequest.skip}`],
    ['region', region],
    ['fields', 'TemplateID,Name,AdvertisedDuration,RegisterInterestUri,BestAdvertisedOffers'],
  ]);

  return arloClient
    .get<ArloCourseData>(
    `/eventtemplates/${arloId}`,
    { params: arloParams },
  )
    .then((x) => x.data);
};
