import React, { useEffect } from 'react';
import {
  Box, Grid,
} from '@mui/material';

import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import type { LinkDefinition } from '#contentful/Link';
import ContentfulLink from '#contentful/Link';
import Graphics from './Graphics/Graphics';
import type { GraphicCardContentDefinition } from './GraphicCard/GraphicCardContent';
import GraphicCard from './GraphicCard';

export type InteractiveGraphicDefinition = InteractiveGraphicProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentInteractiveGraphic',
  },
};

type BackgroundColor = {
  value: string,
};

export type InteractiveGraphicCard = {
  content: GraphicCardContentDefinition[],
  border: ContentfulBorderDefinition,
  title: String,
};

export type InteractiveGraphicFragment = {
  activeBackgroundColor: BackgroundColor,
  hoverBackgroundColor: BackgroundColor,
  nonActiveBackgroundColor: BackgroundColor,
  interactiveCard: InteractiveGraphicCard,
  fragmentId: string,
};

export type InteractiveGraphicProps = {
  fragments: InteractiveGraphicFragment[],
  link: LinkDefinition,
  id: string,
  graphicStyle: {
    internal: {
      raw: string,
    },
  },
};

const InteractiveGraphic = (
  props
  : InteractiveGraphicProps,
) => {
  const {
    id,
    link,
    fragments,
    graphicStyle,
  } = props;

  const [activeElement, setActiveElement] = React.useState<string | null>(null);

  const settingKey = activeElement?.replace(id, '');
  const setting = fragments.find((fragment) => fragment?.fragmentId === settingKey);

  useEffect(() => {
    if (!activeElement) {
      setActiveElement(`${fragments[0]?.fragmentId}${id}`);
    }
  }, [activeElement, fragments, id]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
        marginTop: '1em',
      }}
    >
      <Grid
        container
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        {link && (
        <Grid
          item
          xs={12}
          pr={{ xs: 2, md: 0 }}
          width={{ xs: '100%', sm: 400 }}
          mb={{ xs: '-1em' }}
          zIndex={2}
        >
          <Box display="flex" justifyContent="flex-end">
            <ContentfulLink
              content={link}
            >
              {link.label}
            </ContentfulLink>
          </Box>
        </Grid>
        )}
        <Grid item xs={12} lg={6}>
          {activeElement && (
            <Graphics
              graphicStyle={graphicStyle}
              fragments={fragments}
              activeElement={activeElement}
              setActiveElement={setActiveElement}
              id={id}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {setting && (
            <GraphicCard
              card={setting?.interactiveCard}
              bannerColor={setting?.activeBackgroundColor?.value}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default InteractiveGraphic;
