import { Typography } from '@mui/material';
import React from 'react';

export type TableHeaderCellProps = {
  title: string,
};

const TableHeaderCell = ({
  title,
}: TableHeaderCellProps) => (
  <Typography
    variant="caption"
    className="text-impgray900 font-extrabold"
  >
    {title}
  </Typography>
);

export default TableHeaderCell;
