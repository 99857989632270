import React from 'react';
import FormFieldDropdown from './FormFieldDropdown';
import FormFieldRadio from './FormFieldRadio';
import type { ContentfulMultiSelectProps } from './types';

export type FormFieldMultiSelectDefinition = {
  id: string,
};

const FormFieldMultiSelect = ({
  label,
  name,
  customName,
  required,
  options,
  reset,
  selectionType,
  defaultValue,
}: ContentfulMultiSelectProps) => {
  switch (selectionType) {
    case 'Dropdown':
      return (
        <FormFieldDropdown
          name={name !== 'Other' ? name : customName}
          label={label}
          options={options}
          required={required}
          reset={reset}
        />
      );
    case 'Radio Buttons':
      return (
        <FormFieldRadio
          name={name !== 'Other' ? name : customName}
          label={label}
          options={options}
          required={required}
          reset={reset}
        />
      );
    case 'Hidden':
      return (
        <input
          type="hidden"
          id={`${name}-select`}
          name={name !== 'Other' ? name : customName}
          value={defaultValue}
        />
      );
    default:
      throw new Error(`Selection Type ${selectionType} not recognized.`);
  }
};

export default FormFieldMultiSelect;
