import React from 'react';

import type { InteractiveGraphicFragment } from '#contentful/InteractiveGraphic/InteractiveGraphic';
import ExpertiseGraphic8Fragments from './ExpertiseGraphic8Fragments';

type ExpertiseGraphicProps = {
  id: string,
  variation: string,
  fragments: InteractiveGraphicFragment[],
  activeElement: string | null,
  setActiveElement: React.Dispatch<React.SetStateAction<string | null>>,
};

const Graphics = ({
  variation,
  fragments,
  activeElement,
  setActiveElement,
  id,
}: ExpertiseGraphicProps) => {
  switch (variation) {
    case 'expertiseGraphic8Fragments':
      return (
        <ExpertiseGraphic8Fragments
          fragments={fragments}
          activeElement={activeElement}
          setActiveElement={setActiveElement}
          id={id}
        />
      );
    default:
      throw new Error(`Unexpected graphicStyle type: ${variation}`);
  }
};

export default Graphics;
