export type TextTransform = 'None' | 'Uppercase' | 'Lowercase' | 'Capitalize';

export const transformText = (transformType: TextTransform | undefined) => {
  switch (transformType) {
    case 'Capitalize':
      return 'capitalize';
    case 'Lowercase':
      return 'lowercase';
    case 'Uppercase':
      return 'uppercase';
    default:
      return 'normal-case';
  }
};
