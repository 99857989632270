import React from 'react';
import {
  Box,
  Card, CardActions, CardContent, Grid, Typography,
} from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { useContentfulImage } from 'gatsby-source-contentful/hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import SmartLink from '#components/SmartLink';
import type { ButtonVariant } from '#components/Button';
import Button from '#components/Button';
import theme from '#components/Layout/theme';
import { isEmpty } from 'lodash/fp';
import { navigate } from 'gatsby';

type ProfileCardPhotoDefinition = {
  gatsbyImageData: IGatsbyImageData,
  alt: string,
  url: string,
};

type ProfileCardProps = {
  title: string,
  subtitle: string | JSX.Element,
  photo?: ProfileCardPhotoDefinition,
  slug?: string,
  buttonLabel?: string,
  buttonVariant?: ButtonVariant,
  showTitle?: boolean,
  showSubtitle?: boolean,
  showPhoto?: boolean,
  showButton?: boolean,
  cardSx?: object,
  cardActionSx?: object,
  removeReveal?: boolean,
  gatsbyImageStyle?: object
  contentSx?: object
  mobileLayout?: boolean
};

export type ProfileCardDefinition = ProfileCardProps;

const translationKey = 'Components.ProfileCard';

const ProfileCard = ({
  title,
  subtitle,
  photo,
  buttonLabel,
  buttonVariant,
  showTitle = true,
  showSubtitle = true,
  showPhoto = false,
  showButton = false,
  slug,
  cardSx,
  cardActionSx,
  removeReveal,
  gatsbyImageStyle,
  contentSx,
  mobileLayout,
}: ProfileCardProps) => {
  const { t } = useTranslation();
  const displayPhoto = showPhoto && !isEmpty(photo);
  const marginTop = displayPhoto ? 15 : 1;
  const dynamicImage = useContentfulImage({
    image: {
      url: photo?.url.replace('https:', ''),
      width: 600,
      height: 600,
      quality: 100,
      resizingBehavior: 'crop',
    },
  });

  const textDecoration = 'none';
  const revealStyle = removeReveal ? '' : 'reveal';

  const handleCardClick = () => {
    if (mobileLayout && slug) {
      navigate(`/${slug}`);
    }
  };

  return (
    <div
      className={`hover-scale ${revealStyle} transition-ease-1`}
      style={{
        position: 'relative',
        marginTop: !mobileLayout && displayPhoto ? 78 : 0,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Card
        elevation={10}
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: theme.border.card,
          boxShadow: theme.palette?.shadow?.light,
          width: 280,
          ...cardSx,
        }}
        onClick={handleCardClick}
      >
        <CardContent sx={{ marginTop, pb: 0, ...contentSx }}>
          {displayPhoto && mobileLayout && (
            <SmartLink slug={slug} sx={{ textDecoration }}>
              <GatsbyImage
                image={dynamicImage ?? null}
                loading="lazy"
                alt={photo?.alt ?? ''}
                style={{
                  height: 185,
                  width: 185,
                  borderRadius: '50%',
                  ...gatsbyImageStyle,
                }}
              />
            </SmartLink>
          )}

          <Box>
            {showTitle && (
            <SmartLink
              slug={slug}
              sx={{ textDecoration }}
            >
              <Typography
                color={theme.palette.color?.impgraydark}
                mb={1}
                textAlign="center"
                variant={mobileLayout ? 'h6' : 'h4'}
              >
                {title}
              </Typography>
            </SmartLink>
            )}

            {showSubtitle && (
            <SmartLink
              slug={slug}
              sx={{ textDecoration, color: 'inherit' }}
            >
              <Typography
                color={theme.palette.color?.impgraydark}
                textAlign="center"
                variant="subtitle1"
              >
                {subtitle}
              </Typography>
            </SmartLink>
            )}
          </Box>

        </CardContent>

        {!mobileLayout && showButton && slug && (
          <CardActions
            sx={{
              justifyContent: 'center',
              pb: 3,
              ...cardActionSx,
            }}
          >
            <Grid item>
              <Button variant={buttonVariant as ButtonVariant} slug={slug}>
                <Typography variant="caption">
                  {buttonLabel ?? t(`${translationKey}.ReadMore`)}
                </Typography>
              </Button>
            </Grid>
          </CardActions>
        )}
      </Card>
      {!mobileLayout && displayPhoto && (
        <SmartLink slug={slug} sx={{ textDecoration }}>
          <GatsbyImage
            image={dynamicImage ?? null}
            loading="lazy"
            alt={photo?.alt ?? ''}
            style={{
              position: 'absolute',
              height: 207,
              width: 207,
              left: '50%',
              top: 0,
              transform: 'translate(-50%, -42%)',
              borderRadius: '50%',
              ...gatsbyImageStyle,
            }}
          />
        </SmartLink>
      )}
    </div>
  );
};

export default ProfileCard;
