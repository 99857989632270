import React from 'react';
import { Grid } from '@mui/material';
import {
  flow,
  map,
  slice,
} from 'lodash/fp';

import type { AnyContentfulCardDefinition } from '#contentful/ContentfulCard';
import ContentfulCard from '#contentful/ContentfulCard';
import type { CardsPerRow } from './types';

export default (
  cardsPerGroup: number = 3,
  cardGroups: number = 1,
  cardsPerRow: CardsPerRow = 3,
) => flow(
  slice(0, cardsPerGroup * cardGroups),
  map((card: AnyContentfulCardDefinition) => (
    <Grid
      key={card.id}
      item
      xs={12}
      px={{
        xs: 0,
        md: card.internal?.type === 'ContentfulComponentIconButton' ? 0 : 0.5,
        lg: cardsPerRow === 3 ? 2 : 1,
      }}
      md={12 / (cardsPerRow ?? 3)}
      minWidth={0}
    >
      <ContentfulCard card={card} />
    </Grid>
  )),
);
