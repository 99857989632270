export default (minHeight: string | undefined): string => {
  const height = minHeight ? minHeight.toLowerCase() : '';
  switch (height) {
    case 'medium':
      return '92px';
    case 'large':
      return '108px';
    case 'small':
    default:
      return '64px';
  }
};
