import React from 'react';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import type { BackgroundDefinition } from '#components/Section';
import ProfileCard from '#components/ProfileCard';
import SectionSettings from '#components/Section/SectionSettings';
import type { FlairPosition, FlairGraphic } from '#components/Flair/Flair';
import { spacing } from '#components/Layout/theme/spacing.theme';
import useResponsiveSpacing from '#hooks/useResponsiveSpacing';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';

import type { TeamPersonDefinition } from './PersonDefinition';

export type TeamDefinition = ContentfulComponentDefinition & {
  displayName?: string,
  background: BackgroundDefinition,
  flairGraphicComTeam?: FlairGraphic,
  flairPositionComTeam?: FlairPosition,
  marginBottomComTeam?: string,
  marginLeftComTeam?: string,
  marginRightComTeam?: string,
  marginTopComTeam?: string,
  paddingTopComTeam?: string,
  paddingBottomComTeam?: string,
  paddingLeftComTeam?: string,
  paddingRightComTeam?: string,
  showName: boolean,
  showTitle: boolean,
  showPhoto: boolean,
  showBioLink: boolean,
  people: TeamPersonDefinition[],
  internal: {
    type: 'ContentfulComponentTeam'
  },
};

export type TeamProps = {
  content: TeamDefinition,
};

const translationKey = 'Components.Team';

const Team = ({
  content: {
    displayName,
    background,
    flairGraphicComTeam,
    flairPositionComTeam,
    marginBottomComTeam,
    marginLeftComTeam,
    marginRightComTeam,
    marginTopComTeam,
    paddingTopComTeam,
    paddingBottomComTeam,
    paddingLeftComTeam,
    paddingRightComTeam,
    showName,
    showTitle,
    showPhoto,
    showBioLink,
    people,
  },
}: TeamProps) => {
  const { t } = useTranslation();
  const paddingLeft = useResponsiveSpacing({ xs: 2, md: paddingLeftComTeam ?? 5 });
  const paddingRight = useResponsiveSpacing({ xs: 2, md: paddingRightComTeam ?? 5 });

  return (
    <Box
      mt={spacing(marginTopComTeam)}
      mb={spacing(marginBottomComTeam)}
      ml={spacing(marginLeftComTeam)}
      mr={spacing(marginRightComTeam)}
    >
      {displayName && (
      <Typography variant="h2" sx={{ textAlign: 'center' }}>
        {displayName}
      </Typography>
      )}

      <SectionSettings
        {...background}
        flairGraphic={flairGraphicComTeam}
        flairPosition={flairPositionComTeam}
        pt={paddingTopComTeam}
        pb={paddingBottomComTeam}
        pl={paddingLeft}
        pr={paddingRight}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          rowSpacing={5}
          columnSpacing={{ xs: 0, lg: 3 }}
          my={4}
          pb={6}
        >
          {people?.length && people?.map(({
            id,
            name,
            title,
            image,
            profilePage,
          }) => (
            <Grid item key={id} xs={12} md={6} lg={4}>
              <ProfileCard
                title={name}
                subtitle={title}
                photo={image}
                buttonLabel={t(`${translationKey}.ReadBio`) ?? 'Read Bio'}
                slug={profilePage?.[0]?.composePage?.[0]?.slug}
                buttonVariant="bio"
                showTitle={showName}
                showSubtitle={showTitle}
                showPhoto={showPhoto}
                showButton={showBioLink}
              />
            </Grid>
          ))}
        </Grid>
      </SectionSettings>
    </Box>
  );
};

export default Team;
