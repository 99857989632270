import React from 'react';
import { Box } from '@mui/material';

import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import type { BackgroundDefinition } from '#components/Section/SectionSettings';
import type { IconButtonDefinition } from '#contentful/IconButton';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import type { ColumnDefinition } from '#components/Column';
import Column from '#components/Column';
import useBorder from '#hooks/useBorder';

export type ImageCarouselItemDefinition = ImageCarouselItemProps
& ContentfulComponentDefinition
& {
  indicatorIcon?: IconButtonDefinition
};

export type ImageCarouselItemContent = {
  column: ColumnDefinition,
  background?: BackgroundDefinition,
  border?: ContentfulBorderDefinition,
};

type ImageCarouselItemProps = ImageCarouselItemContent & {
  minHeight?: number,
};

const ImageCarouselItem = ({
  column,
  background,
  border,
}: ImageCarouselItemProps) => {
  const borderStyling = useBorder(border);

  return (
    <Box
      sx={{
        mb: 1,
        overflow: 'hidden',
        backgroundColor: background?.color?.value || 'transparent',
        backgroundImage: background?.image ? `url(${background?.image?.url})` : 'none',
        backgroundSize: 'cover',
        ...borderStyling,
      }}
    >
      <Column
        key={column.id}
        {...column}
        carouselType="imageCarousel"
      />
    </Box>
  );
};

export default ImageCarouselItem;
