import CourseCard from '#components/CourseCard';
import React from 'react';

export type ArloTrainingCardDefinition = ArloTrainingCardProps & {
  id: string,
  internal: {
    type: 'ArloTrainingClass'
  }
};

export type ArloTrainingCardProps = {
  name: string,
  start: string,
  end: string,
  slug: string,
  registrationUri: string
};

const ArloTrainingCard = ({
  name,
  start,
  end,
  slug,
  registrationUri,
}: ArloTrainingCardProps) => (
  <CourseCard
    title={name}
    start={start}
    end={end}
    slug={slug}
    registrationUrl={registrationUri}
  />
);

export default ArloTrainingCard;
