import React from 'react';
import { Tooltip } from '@mui/material';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import useBorder from '#hooks/useBorder';
import MapCard from '../MapCard';
import type { Marker } from '../InteractiveMap';

type InteractiveMapTooltipProps = {
  marker: Marker,
  open: string | null,
  handleTooltipOpen: (marker: Marker) => void,
  isMobile: boolean,
};

const InteractiveMapTooltip = ({
  marker,
  open,
  handleTooltipOpen,
  isMobile,
}: InteractiveMapTooltipProps) => {
  const { nonActiveColor, hoverColor, activeColor } = marker?.markerOptions ?? {};
  const markerPosition = JSON.parse(marker?.position?.internal?.raw ?? '{}');
  const borderSettings = useBorder(marker?.locationCard?.border);

  const isActive = open === marker?.id;

  return (
    <Tooltip
      PopperProps={{
        disablePortal: false,
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
            },
          },
        ],
      }}
      open={open === marker?.id}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={(
        <MapCard
          {...marker?.locationCard}
        />
      )}
      placement={isMobile ? 'top' : 'left'}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            ...borderSettings,
            padding: '16px',
            backgroundColor: marker?.locationCard?.background?.color?.value ?? '#fff',
            maxWidth: {
              xs: '300px',
              sm: '400px',
              lg: '450px',
            },
          },
        },
        popper: {
          sx: {
            zIndex: '1000',
          },
        },
        arrow: {
          sx: {
            fontSize: '40px',
            '&::before': {
              backgroundColor: marker?.locationCard?.background?.color?.value ?? '#fff',
              borderColor: marker?.locationCard?.border?.color?.value,
              borderWidth: '2px',
              borderStyle: 'solid',
              margin: '-2px',
            },
          },
        },
      }}
    >
      {markerPosition
      && (
      <LocationOnIcon
        width="100%"
        height="100%"
        onClick={() => handleTooltipOpen(marker)}
        sx={{
          position: 'absolute',
          top: `${markerPosition.y * 100}%`,
          left: `${markerPosition.x * 100}%`,
          transform: isActive ? 'translate(-50%, -100%) scale(1.5)' : 'translate(-50%, -100%)',
          color: isActive ? activeColor?.value : nonActiveColor?.value,
          fontSize: '24px',
          '&:hover': {
            color: isActive ? activeColor?.value : hoverColor?.value,
            transform: 'translate(-50%, -100%) scale(1.5)',
            cursor: 'pointer',
          },
        }}
      />
      )}
    </Tooltip>
  );
};

export default InteractiveMapTooltip;
