import React from 'react';

import BlogCardBase from '#components/BlogCard';
import type { GatsbyImageProps } from '#contentful/Image';
import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';

export type BlogCardDefinition = ContentfulComponentDefinition & {
  blogPage: {
    slug: string,
    content: {
      title: string,
      thumbnail: GatsbyImageProps,
      snippet: {
        text: string,
      }
      categories: {
        displayName: string,
      }[]
      id: string,
    }
  },
  contentful_id: string,
  internal: {
    type: 'ContentfulComponentBlogCard'
  }
};

export type BlogCardProps = {
  card: BlogCardDefinition,
  isBlog?: boolean,
};

const BlogCard = ({
  card: {
    blogPage: {
      slug,
      content: {
        title,
        thumbnail,
        snippet,
        categories,
      },
    },
  },
  isBlog,
}: BlogCardProps) => (
  <BlogCardBase
    image={thumbnail}
    category={categories[0].displayName}
    title={title}
    summary={snippet}
    slug={slug}
    isBlog={isBlog}
  />
);

export default BlogCard;
