import React from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import {
  flow,
  filter,
  isString,
  join,
} from 'lodash/fp';

import OpenQuoteMark, { CloseQuoteMark } from '#components/QuoteMark';
import type { ContentfulQuoteSize } from '#hooks/useQuoteSize';
import useQuoteSize from '#hooks/useQuoteSize';
import type { ContentfulSectionSpacingDefinition } from '#hooks/useSectionSpacing';
import useSectionSpacing from '#hooks/useSectionSpacing';
import SectionSettings from '#components/Section/SectionSettings';
import type {
  ContentfulComponentDefinition,
} from '../ContentfulComponentDefinition';

export type QuoteDefinition = ContentfulComponentDefinition & {
  quote: { text: string },
  quoteSize?: ContentfulQuoteSize,
  nameOfPerson?: string,
  titleOfPerson?: string,
  companyOfPerson?: string,
  spacingSettings?: ContentfulSectionSpacingDefinition,
  internal: {
    type: 'ContentfulComponentQuote'
  }
};

export type QuoteProps = {
  content: QuoteDefinition
};

const Quote = ({
  content: {
    quote,
    quoteSize,
    nameOfPerson,
    titleOfPerson,
    companyOfPerson,
    spacingSettings,
  },
}: QuoteProps) => {
  const quoteText = quote?.text;
  const openQuoteTopAdjustment = 80;
  const closeQuoteBottomAdjustment = 120;

  const quoteCredit = flow(
    filter(isString),
    join(', '),
  )([
    nameOfPerson,
    titleOfPerson,
    companyOfPerson,
  ]);

  const quoteVariant = useQuoteSize(quoteText, quoteSize);

  //  Calculate section settings
  const responsiveSectionSpacing = useSectionSpacing(
    {
      ...spacingSettings,
    },
    {
      mtmd: 0,
      mbmd: 0,
      mtlg: 0,
      mblg: 0,
      mllg: 0,
      mrlg: 0,
      ptmd: 0,
      pbmd: 0,
      plmd: 0,
      prmd: 0,
      ptlg: 0,
      pblg: 0,
      pllg: 0,
      prlg: 0,
      plxl: 0,
      prxl: 0,
      columnSpacinglg: 4,
    },
  );

  return (
    <SectionSettings {...responsiveSectionSpacing}>
      <Grid
        container
        direction="column"
        maxWidth="600px"
        textAlign="left"
        mx="auto"
        px={{ xs: 2, md: 0 }}
      >
        <Grid
          item
          sx={{
            left: { xs: 0, md: -80 },
            position: 'relative',
            top: openQuoteTopAdjustment,
            mt: `-${openQuoteTopAdjustment}px`,
            zIndex: 1,
          }}
        >
          <OpenQuoteMark />
        </Grid>
        <Grid item px={2}>
          <Typography
            variant={quoteVariant}
            sx={{
              position: 'relative',
              zIndex: 2,
            }}
          >
            {quoteText?.replaceAll('"', '')}
          </Typography>
        </Grid>
        <Grid
          item
          mt={4}
          sx={{
            'p.MuiTypography-body2': {
              px: '1rem !important', //  credit alignment must override rich-text.scss
            },
          }}
        >
          <Typography variant="body2">
            {quoteCredit}
          </Typography>
        </Grid>
        <Grid
          item
          alignSelf="flex-end"
          sx={{
            bottom: closeQuoteBottomAdjustment,
            mb: `-${closeQuoteBottomAdjustment}px`,
            position: 'relative',
            right: { xs: 0, md: -20 },
            zIndex: 1,
          }}
        >
          <CloseQuoteMark />
        </Grid>
      </Grid>
    </SectionSettings>
  );
};

export default Quote;
