import type { SVGProps } from 'react';
import React from 'react';

type CardBannerProps = SVGProps<SVGSVGElement> & {
  fill?: string,
};

const CardBanner = ({ fill, ...props }: CardBannerProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1"
    viewBox="0 0 600 66.75"
    {...props}
  >
    <defs>
      <clipPath id="a96ddefc7d">
        <path d="M1.121 0H598.88v66.5H1.12zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#a96ddefc7d)">
      <path
        fill={fill || '#202121'}
        d="M1.121 1.008v65.39h596.445s-29.984-20.32-51.171-33.085L598.816.124c-199.191 0-398.507.883-597.695.883zm0 0"
      />
    </g>
  </svg>
);

export default CardBanner;
