import React from 'react';
import type {
  ContentfulComponentDefinition,
} from '#components/ContentfulComponent';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import type { StepItemDefinition } from './StepItem/StepItem';
import StepItem from './StepItem/StepItem';

export type StepsDefinition = ContentfulComponentDefinition & {
  arrowColor: string,
  items: StepItemDefinition[],
  internal: {
    type: 'ContentfulComponentSteps'
  }
};

export type StatCardProps = {
  content: StepsDefinition;
};

const Steps = ({
  content,
}: StatCardProps) => {
  const { items, arrowColor } = content;

  const color = arrowColor.match(/\(#([a-fA-F0-9]{6})\)/)?.[1] ?? 'transparent';

  return (
    <div className="flex flex-col m-2 md:mb-5 ">
      {
        items.map((step, index) => (
          index === 0 ? (
            <StepItem key={step.id} content={step} />
          )
            : (
              <div key={step.id} className="flex flex-col md:flex-row">
                <div className="hidden md:block md:mt-4 md:mr-4" style={{ marginLeft: `${index === 1 ? '96' : (index * 144)}px` }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="85" zoomAndPan="magnify" viewBox="0 0 63.75 74.999997" height="100" preserveAspectRatio="xMidYMid meet" version="1.0">
                    <defs><clipPath id="fcafa8eff6"><path d="M 0 0.144531 L 62.753906 0.144531 L 62.753906 74.851562 L 0 74.851562 Z M 0 0.144531 " clipRule="nonzero" /></clipPath></defs>
                    <g clipPath="url(#fcafa8eff6)"><path fill={`#${color}`} d="M 62.3125 61.742188 L 53.90625 43.097656 C 53.753906 42.765625 53.554688 42.476562 53.3125 42.234375 C 52.476562 41.394531 51.183594 41.117188 50.042969 41.632812 C 48.574219 42.300781 47.921875 44.035156 48.585938 45.503906 L 53.964844 57.441406 C 38.109375 53.429688 27.605469 46.917969 20.550781 39.851562 C 12.554688 31.828125 8.933594 22.996094 7.292969 15.980469 C 5.660156 8.96875 6.050781 3.804688 6.089844 3.386719 C 6.089844 3.367188 6.089844 3.367188 6.089844 3.367188 L 6.09375 3.367188 C 6.183594 2.457031 5.847656 1.601562 5.25 1 C 4.792969 0.542969 4.179688 0.230469 3.480469 0.164062 C 1.878906 0 0.449219 1.171875 0.285156 2.777344 C 0.265625 2.996094 -0.289062 8.621094 1.4375 16.5625 C 3.167969 24.488281 7.222656 34.769531 16.421875 43.992188 C 24.246094 51.84375 35.753906 58.855469 52.398438 63.085938 L 40.335938 68.671875 C 38.875 69.347656 38.238281 71.085938 38.910156 72.554688 C 39.585938 74.023438 41.320312 74.664062 42.785156 73.984375 L 60.875 65.605469 C 62.328125 64.933594 62.96875 63.207031 62.3125 61.742188 Z M 62.3125 61.742188 " fillOpacity="1" fillRule="nonzero" /></g>
                  </svg>
                </div>
                <div className="flex md:hidden justify-center items-center">
                  <ArrowDownwardOutlinedIcon className="w-16 h-16" style={{ color }} />
                </div>
                <div className="flex-1 md:mt-12">
                  <StepItem content={step} />
                </div>
              </div>
            )
        ))
      }
    </div>
  );
};

export default Steps;
