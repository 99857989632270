import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Grid,
} from '@mui/material';

import FormFieldRadioOption from './_FormFieldRadioOption';
import type {
  MultiSelectOptionDefinition,
  MultiSelectProps,
} from '../types';

const FormFieldRadio = ({
  label,
  name,
  required,
  options,
  reset,
}: MultiSelectProps) => {
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    setValue(null);
  }, [reset]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl
      variant="filled"
      fullWidth
      required={required}
    >
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        aria-labelledby={`${name}-radio-group`}
        name={name}
        value={value}
        onChange={handleChange}
        row
      >
        <Grid container>
          {options.map((option: MultiSelectOptionDefinition) => (
            <Grid item xs={12} key={option.id}>
              <FormFieldRadioOption {...option} required={required} />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};
export default FormFieldRadio;
