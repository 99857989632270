import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { useMediaQuery, useTheme } from '@mui/material';

import type { RichTextOptions } from '#components/RichText';

const columnCountOptions = (columnCount?: number) => ({
  renderNode: {
    [BLOCKS.OL_LIST]: (_: any, children: any) => {
      const { breakpoints } = useTheme();
      const isDesktop = useMediaQuery(breakpoints.up('md'));

      return (
        <ol style={{ columnCount: isDesktop ? (columnCount ?? 2) : 1 }}>
          {children}
        </ol>
      );
    },
    [BLOCKS.UL_LIST]: (_: any, children: any) => {
      const { breakpoints } = useTheme();
      const isDesktop = useMediaQuery(breakpoints.up('md'));

      return (
        <ul style={{ columnCount: isDesktop ? (columnCount ?? 2) : 1 }}>
          {children}
        </ul>
      );
    },
  },
}) as RichTextOptions;

export default columnCountOptions;
