import React, { useRef } from 'react';
import {
  Popper,
  Paper,
  Typography,
  List,
  ListItemText,
  ListItem,
} from '@mui/material';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { useToggle } from '@react-shanties/core';

import { langs } from './languages';

const LanguageSelector = () => {
  const anchor = useRef(null);
  const [menuOpen,, { setOn, setOff }] = useToggle();

  const { language, languages, originalPath } = useI18next();

  const getPath = () => {
    if (originalPath === '/404/') {
      return '/404.html';
    }
    return originalPath;
  };

  return (
    <div
      ref={anchor}
      onMouseEnter={setOn}
      onMouseLeave={setOff}
      className="px-2 border border-solid border-impblue500 rounded-[1.25rem]"
      data-testid="language-selector-box"
    >
      <div className="grid grid-cols-2 gap-0.5 items-center">
        <div className="col-span-1 mt-1" data-testid="language-icon">
          <LanguageIcon color="primary" />
        </div>
        <div className="col-span-1 mb-0.5">
          <Typography
            variant="subtitle1"
            className="text-impblue500 text-center"
          >
            {language?.split('-')?.[0]}
          </Typography>
        </div>
      </div>
      {languages && anchor.current && (
        <Popper
          anchorEl={anchor.current}
          open={menuOpen}
          placement="bottom-start"
          keepMounted
          onResize={undefined}
          onResetCapture={undefined}
          className="z-[1000000]"
        >
          <Paper>
            <List disablePadding>
              {languages.map((lang) => (
                <ListItem key={lang} className="h-full w-[110px]">
                  <Link
                    to={getPath()}
                    language={lang}
                    className="no-underline"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <ListItemText className="my-0">
                      <Typography
                        variant="subtitle1"
                        className="text-impblue500"
                      >
                        {langs[lang]}
                      </Typography>
                    </ListItemText>
                  </Link>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Popper>
      )}
    </div>
  );
};

export default LanguageSelector;
