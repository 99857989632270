import type { Components } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    bio: true;
    primary: true;
    secondary: true;
  }
}

export default (): Components => ({
  MuiButton: {
    variants: [
      {
        props: { variant: 'bio' },
        style: {},
      },
    ],
  },
});
