import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  filter,
  first,
  flow,
  map,
} from 'lodash/fp';

type QueryPage = {
  arloVenueId: number,
  pageTemplate: {
    composePage: {
      slug: string,
    }[],
  }[],
  nodeLocale: string,
};

export type LocationPage = {
  arloVenueId: number,
  slug: string,
};

export default (
  language: string,
): LocationPage[] => {
  const data = useStaticQuery(graphql`
    {
      all: allContentfulComponentLocation(
        filter: {
          arloVenueId: {ne: null}, 
          page_template__location_page: {elemMatch: {
            compose__page: {elemMatch: {
              slug: {ne: null}
            }}
          }}
        }
      ) {
        locations: nodes {
          arloVenueId
          pageTemplate: page_template__location_page {
            composePage: compose__page {
              slug
            }
          }
          nodeLocale: node_locale
        }
      }
    }
  `);

  return useMemo(
    () => flow(
      filter({ nodeLocale: language }),
      map(
        ({
          arloVenueId,
          pageTemplate,
        }: QueryPage) => ({
          arloVenueId,
          slug: first(first(pageTemplate)!.composePage)!.slug,
        }) as LocationPage,
      ),
    )(data.all.locations),
    [data.all.locations, language],
  );
};
