import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';
import type {
  GridProps,
  TypographyProps,
} from '@mui/material';

import Skeleton from '#components/Skeleton';

export type GridItemTextProps = {
  variant: TypographyProps['variant'],
  text?: string,
  loading?: boolean,
  gridProps?: GridProps,
  typographyProps?: TypographyProps,
};

const GridItemText = ({
  variant,
  text,
  loading,
  gridProps,
  typographyProps,
}: GridItemTextProps) => (
  <Grid item {...gridProps} data-testid="grid-item-text">
    <Typography variant={variant} {...typographyProps}>
      {loading ? <Skeleton /> : text}
    </Typography>
  </Grid>
);

export default GridItemText;
