import type { Theme } from '@mui/material';
import {
  flow,
  keys,
  includes,
  isNumber,
} from 'lodash/fp';

const wordSpacing = {
  xxsmall: 1,
  xsmall: 2,
  small: 4,
  medium: 8,
  large: 10,
  xlarge: 13,
  xxlarge: 15,
  xxxlarge: 25,
  none: 0,
  default: 'auto',
  auto: 'auto',
};

const contentfulSpacing: { [k: string]: WordSpacing } = {
  'XX-Small': 'xxsmall',
  'X-Small': 'xsmall',
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
  'X-Large': 'xlarge',
  'XX-Large': 'xxlarge',
  'XXX-Large': 'xxxlarge',
  None: 'none',
  Default: 'default',
  Auto: 'auto',
};

export type WordSpacing = keyof typeof wordSpacing;
export type ContentfulSpacing = keyof typeof contentfulSpacing;

export type SpacingValue = ContentfulSpacing | WordSpacing | number;

/**
 * Convert the spacing string coming from Contentful into a pecetange value
 * customized for mobile.
 *
 * @param value The string coming from contentful.
 * @returns A string with a percentage.
 */
export const toMobileSpace = (
  value: string | number | undefined,
) => {
  if (!value) {
    return 0;
  }

  const spacingProp = contentfulSpacing[value];
  const spacingValue = spacingProp ? wordSpacing[spacingProp] : 0;

  if (spacingValue && spacingValue !== 'auto') {
    const mobileSpace = spacingValue as number + 2;
    return `${mobileSpace}%`;
  }
  return spacingValue;
};

export const isWordSpacing = (factor: SpacingValue): factor is WordSpacing => (
  flow(
    keys,
    includes(factor),
  )(wordSpacing)
);

export const isContentfulSpacing = (factor: SpacingValue): factor is ContentfulSpacing => (
  flow(
    keys,
    includes(factor),
  )(contentfulSpacing)
);

export const spacing = (factor: SpacingValue | undefined) => (theme: Theme) => (
  factor
    ? theme.spacing(factor)
    : '0'
);

export default (baseTheme: Theme) => (factor: SpacingValue) => {
  if (isNumber(factor)) {
    return baseTheme.spacing(factor);
  }

  if (isContentfulSpacing(factor)) {
    // eslint-disable-next-line no-param-reassign
    factor = contentfulSpacing[factor];
  }

  if (!isWordSpacing(factor)) {
    return factor;
  }

  const value = wordSpacing[factor];

  return isNumber(value)
    ? baseTheme.spacing(value)
    : value;
};

declare module '@mui/system/createTheme/createSpacing' {
  interface Spacing {
    (factor: SpacingValue): string;
  }
}
