import type { ArloAdvertisedOffer } from '#services/arlo';

export type FlatArloOffer = {
  basePrice: string | undefined,
  discountPrice: string | undefined,
  discountName: string | undefined,
};

export default (offers: ArloAdvertisedOffer[] | undefined): FlatArloOffer => {
  const baseOffer = offers?.find((o) => !o.IsDiscountOffer);
  const discountOffer = offers?.find((o) => o.Label === 'Early Bird');

  return {
    basePrice: baseOffer?.OfferAmount.FormattedAmountTaxExclusive,
    discountPrice: discountOffer?.OfferAmount.FormattedAmountTaxExclusive,
    discountName: discountOffer?.Label,
  };
};
