import { defaultsDeep } from 'lodash/fp';
import type { Breakpoint } from '@mui/system';

import useContentfulAlignment from '#hooks/useContentfulAlignment';
import useResponsiveAlignItems from '#hooks/useResponsiveAlignItems';
import useResponsiveJustifyContent from '#hooks/useResponsiveJustifyContent';
import useResponsiveSpacing from '#hooks/useResponsiveSpacing';

import type {
  ColumnSettingOptions,
  ContentfulColumnSettingsDefinition,
  ResponsiveColumnSettings,
} from './types';
import defaultOptions from './_defaultOptions';

export default (
  {
    paddingTop,
    paddingBottom,
    paddingRight,
    paddingLeft,
    itemsHorizontalAlignment,
    itemsVerticalAlignment,
  }: ContentfulColumnSettingsDefinition,
  flipBreakpoint?: Breakpoint,
  options?: ColumnSettingOptions,
): ResponsiveColumnSettings => {
  const {
    ptxs,
    ptsm,
    ptmd,
    ptlg,
    ptxl,
    pbxs,
    pbsm,
    pbmd,
    pblg,
    pbxl,
    prxs,
    prsm,
    prmd,
    prlg,
    prxl,
    plxs,
    plsm,
    plmd,
    pllg,
    plxl,
    verticalAlignment,
    horizontalAlignment,
    justifyContentxs,
    alignItemsxs,
    justifyContentsm,
    alignItemssm,
    justifyContentmd,
    alignItemsmd,
    justifyContentlg,
    alignItemslg,
    justifyContentxl,
    alignItemsxl,
  } = defaultsDeep(defaultOptions, options);

  const pt = useResponsiveSpacing({
    xs: ptxs,
    sm: ptsm,
    md: ptmd,
    lg: ptlg,
    xl: paddingTop ?? ptxl,
  })!;
  const pb = useResponsiveSpacing({
    xs: pbxs,
    sm: pbsm,
    md: pbmd,
    lg: pblg,
    xl: paddingBottom ?? pbxl,
  })!;
  const pr = useResponsiveSpacing({
    xs: prxs,
    sm: prsm,
    md: prmd,
    lg: prlg,
    xl: paddingRight ?? prxl,
  })!;
  const pl = useResponsiveSpacing({
    xs: plxs,
    sm: plsm,
    md: plmd,
    lg: pllg,
    xl: paddingLeft ?? plxl,
  })!;

  const plainJustifyContent = useContentfulAlignment(
    itemsVerticalAlignment ?? verticalAlignment,
  );
  const plainAlignItems = useContentfulAlignment(
    itemsHorizontalAlignment ?? horizontalAlignment,
  );
  const justifyContent = useResponsiveJustifyContent({
    xs: justifyContentxs,
    sm: justifyContentsm,
    md: plainJustifyContent ?? justifyContentmd,
    lg: plainJustifyContent ?? justifyContentlg,
    xl: plainJustifyContent ?? justifyContentxl,
  });
  const alignItems = useResponsiveAlignItems({
    xs: alignItemsxs,
    sm: alignItemssm,
    md: plainAlignItems ?? alignItemsmd,
    lg: plainAlignItems ?? alignItemslg,
    xl: plainAlignItems ?? alignItemsxl,
  });

  return {
    pt,
    pb,
    pr,
    pl,
    justifyContent,
    alignItems,
  };
};
