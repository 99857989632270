import React from 'react';
import { Grid, Typography } from '@mui/material';
import loadable from '@loadable/component';

import SocialSharing, {
  type SocialSharingDefinition,
} from '#contentful/SocialSharing';
import SmartLink from '#components/SmartLink';

const WhiteLogo = loadable(() => import('#components/ImprovingWhiteLogo'));

export type BrandBlockProps = {
  motto: string,
  socials: SocialSharingDefinition
};

const BrandBlock = ({ motto, socials }: BrandBlockProps) => (
  <Grid
    container
    alignItems="flex-start"
    rowSpacing={4}
    width="332px"
  >
    <Grid item xs={12}>
      <SmartLink slug="/" ariaLabel="Improving Home Page">
        <WhiteLogo />
      </SmartLink>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="caption">
        {motto}
      </Typography>
    </Grid>

    <Grid item xs={7}>
      <SocialSharing {...socials} />
    </Grid>
  </Grid>
);

export default BrandBlock;
