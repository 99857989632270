import { graphql, useStaticQuery } from 'gatsby';
import { filter } from 'lodash/fp';
import { useMemo } from 'react';

import type {
  AnyContentfulCardDefinition,
} from '#contentful/ContentfulCard';

/**
 * Query top blogs with the 'Newsroom' category
 */
export default (language: string): AnyContentfulCardDefinition[] => {
  const data = useStaticQuery(graphql`
      query {
        all: allContentfulPageTemplateBlogPost(
          sort: {datePublished: DESC}
          filter: {
            compose__page: {
              elemMatch: {
                slug: {ne: null},
              }
            },
            category: {
              elemMatch: {
                displayName: {eq: "Newsroom"}
              }
            }
          }
        ) {
          cards: nodes {
            ...BlogCardInfo
          }
        }
      }
    `);

  const filteredCards = useMemo(
    () => filter({ nodeLocale: language }, data.all.cards),
    [data, language],
  );

  return filteredCards;
};
