import GeolocationProvider from '#providers/GeolocationProvider';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { withProviders } from '@react-shanties/core';
import Layout from './Layout';
import baseTheme from './theme';

export default withProviders([
  // injects styles at top of document, allowing the tailwind CSS file to come after (and take priority).
  [StyledEngineProvider, { injectFirst: true }],
  [ThemeProvider, { theme: baseTheme }],
  GeolocationProvider,
], Layout);

export { baseTheme as theme };

export type { ColorIndex } from './theme/palette.theme';
