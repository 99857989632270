import React from 'react';
import { Grid, Typography } from '@mui/material';
import { map } from 'lodash/fp';

import SectionColumn from '#components/SectionColumn';
import SectionSettings from '#components/Section/SectionSettings';
import useColor from '#hooks/useColor';
import useSectionSpacing from '#hooks/useSectionSpacing';
import useResponsiveColumnSettings from '#hooks/useResponsiveColumnSettings';
import type { AnyContentfulComponentDefinition } from '#contentful/ContentfulComponent';
import ContentfulComponent from '#contentful/ContentfulComponent';

import type {
  ThreeColumnSectionDefinition,
} from './ThreeColumnSectionDefinition';

export type Sec131313Props = {
  content: ThreeColumnSectionDefinition<'131313'>
};

const Sec131313 = ({
  content: {
    id,
    customAnchorTag,
    background,
    title,
    titleFontColor,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    sectionColumnsVerticalAlignment,
    sectionColumnsHorizontalAlignment,
    columnGap,
    borderRadiusTop,
    borderRadiusBottom,
    border,
    column1,
    column1Background,
    column1Settings,
    column2,
    column2Background,
    column2Settings,
    column3,
    column3Background,
    column3Settings,
    spacingSettings,
  },
}: Sec131313Props) => {
  const titleFontPaletteColor = useColor('color', titleFontColor, '');

  //  Calculate section settings
  const {
    mt,
    mb,
    ml,
    mr,
    pt,
    pb,
    pl,
    pr,
    responsiveJustifyContent,
    responsiveAlignItems,
    responsiveColumnSpacing,
  } = useSectionSpacing(
    {
      ...spacingSettings,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      sectionColumnsHorizontalAlignment,
      sectionColumnsVerticalAlignment,
      columnSpacing: columnGap,
    },
    {
      columnSpacingxs: 2,
      columnSpacingmd: 3,
    },
  );

  const column1ResponsiveSettings = useResponsiveColumnSettings({
    ...column1Settings,
  });
  const column2ResponsiveSettings = useResponsiveColumnSettings({
    ...column2Settings,
  });
  const column3ResponsiveSettings = useResponsiveColumnSettings({
    ...column3Settings,
  });

  return (
    <SectionSettings
      anchorTagId={customAnchorTag ?? id}
      {...background}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
      borderRadiusTop={borderRadiusTop}
      borderRadiusBottom={borderRadiusBottom}
      border={border}
    >
      {title && (
        <Typography
          color={titleFontPaletteColor}
          variant="h2"
          textAlign="center"
        >
          {title}
        </Typography>
      )}

      <Grid
        container
        flexWrap={{ xs: 'wrap', xl: 'nowrap' }}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={responsiveJustifyContent}
        alignItems={responsiveAlignItems}
        columnSpacing={responsiveColumnSpacing}
      >
        <Grid item xs={12} md={10} lg={8} xl={4}>
          <SectionColumn
            {...column1ResponsiveSettings}
            color={column1Background?.color}
            image={column1Background?.image}
          >
            {map((component: AnyContentfulComponentDefinition) => (
              <Grid item key={component.id} height="100%">
                <ContentfulComponent content={component} />
              </Grid>
            ), column1)}
          </SectionColumn>
        </Grid>
        <Grid item xs={12} md={10} lg={8} xl={4}>
          <SectionColumn
            {...column2ResponsiveSettings}
            color={column2Background?.color}
            image={column2Background?.image}
          >
            {map((component: AnyContentfulComponentDefinition) => (
              <Grid item key={component.id} height="100%">
                <ContentfulComponent content={component} />
              </Grid>
            ), column2)}
          </SectionColumn>
        </Grid>
        <Grid item xs={12} md={10} lg={8} xl={4}>
          <SectionColumn
            {...column3ResponsiveSettings}
            color={column3Background?.color}
            image={column3Background?.image}
          >
            {map((component: AnyContentfulComponentDefinition) => (
              <Grid item key={component.id} height="100%">
                <ContentfulComponent content={component} />
              </Grid>
            ), column3)}
          </SectionColumn>
        </Grid>
      </Grid>
    </SectionSettings>
  );
};

export default Sec131313;
