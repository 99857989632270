import { Box, styled } from '@mui/material';

export const CardBox = styled(Box)({
  borderRadius: '15px',
  boxShadow: '0 3px 24px 0 rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  '.gatsby-image-wrapper img': {
    borderRadius: '15px',
  },
});
