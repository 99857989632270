import React from 'react';
import { Divider, useTheme } from '@mui/material';
import type { DividerProps as MuiDividerProps } from '@mui/material';

export type DividerProps = MuiDividerProps;

const ColumnDivider = ({ ...props }: DividerProps) => {
  const theme = useTheme();

  return (
    <Divider
      orientation="vertical"
      flexItem
      sx={{
        bgcolor: theme.palette.color?.impgray900,
        mr: '-1px',
      }}
      {...props}
    />
  );
};

export default ColumnDivider;
