import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import FullWidth from '#components/Section/Sections/FullWidth';

import { getMarginClass, getPaddingClass, type SpacingValue } from '#components/Layout/theme/spacing.tailwind';
import type { TimeItemLabel, TimeLineItemProps } from './TimeLine/TimeLine';
import TimeLine from './TimeLine/TimeLine';
import type { TableContentItemDefinition } from './TableContentItem/TableContentItem';

export type TableContentDefinition = ContentfulComponentDefinition & {
  title: string,
  tableContentItemTitleColor: TableColor,
  tableContentItemOverlineColor: TableColor,
  tableContentItemDotFillColor: TableColor,
  tableContentItemDotHoverColor: TableColor,
  tableContentItemTimelineColor: TableColor,
  marginTop?: SpacingValue,
  marginLeft?: SpacingValue,
  marginRight?: SpacingValue,
  marginBottom?: SpacingValue,
  paddingTop?: SpacingValue,
  paddingLeft?: SpacingValue,
  paddingRight?: SpacingValue,
  paddingBottom?: SpacingValue,
  items: TableContentItemDefinition[],
  internal: {
    type: 'ContentfulComponentTableContent',
  },
};

type TableContentProps = {
  content: TableContentDefinition;
};

export type TableColor = {
  value: string,
};

const TableContent = ({ content }: TableContentProps) => {
  const {
    items,
    tableContentItemDotFillColor,
    tableContentItemDotHoverColor,
    tableContentItemOverlineColor,
    tableContentItemTimelineColor,
    tableContentItemTitleColor,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  } = content;

  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const handleTimelineItemClick = (index: number) => {
    sectionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
    setSelectedItem(index);
  };

  const [timeLineContents, setTimeLineContents] = useState<TimeLineItemProps>({
    labels: [],
    dotFillColor: 'red',
    dotHoverColor: 'red',
    lineColor: 'red',
    overlineColor: 'red',
    titleColor: 'red',
    onItemClick: handleTimelineItemClick,
    selectedItem,
  });
  const [isTopFixed, setIsTopFixed] = useState(false);
  const [isBottomFixed, setIsBottomFixed] = useState(false);

  const handleScroll = () => {
    const contentElement = document.getElementById('content');
    const lastSection = sectionRefs.current[sectionRefs.current.length - 1];

    if (contentElement && lastSection) {
      const scrollPosition = window.scrollY;
      const triggerPoint = contentElement.offsetTop - 20; // 20px offset for sticky effect
      const lastSectionBottom = lastSection.offsetTop + lastSection.offsetHeight;
      const viewportHeight = window.innerHeight;

      if (scrollPosition + viewportHeight >= lastSectionBottom) {
        setIsTopFixed(false);
        setIsBottomFixed(true);
      } else if (scrollPosition > triggerPoint) {
        setIsTopFixed(true);
        setIsBottomFixed(false);
      } else {
        setIsTopFixed(false);
        setIsBottomFixed(false);
      }
    }

    const sections = sectionRefs.current;
    const scrollPositionMiddle = window.scrollY + window.innerHeight / 2;

    sections.forEach((section, index) => {
      if (section) {
        const { offsetTop, offsetHeight } = section;
        if (scrollPositionMiddle > offsetTop && scrollPositionMiddle < offsetTop + offsetHeight) {
          setSelectedItem(index);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const labels: TimeItemLabel[] = [];
    items.forEach((element) => {
      labels.push({ textOverline: element.textOverline, titleContent: element.titleContent });
    });
    setTimeLineContents({
      labels,
      dotFillColor: tableContentItemDotFillColor.value,
      dotHoverColor: tableContentItemDotHoverColor.value,
      overlineColor: tableContentItemOverlineColor.value,
      lineColor: tableContentItemTimelineColor.value,
      titleColor: tableContentItemTitleColor.value,
      onItemClick: handleTimelineItemClick,
      selectedItem,
    });
  }, [items,
    tableContentItemDotFillColor,
    tableContentItemDotHoverColor,
    tableContentItemOverlineColor,
    tableContentItemTimelineColor,
    tableContentItemTitleColor,
    selectedItem]);

  return (
    <Grid container>
      <Grid
        item
        className={`flex flex-wrap content-end max-w-[20%] w-full ${getMarginClass({
          mt: marginTop, mb: marginBottom, ml: marginLeft, mr: marginRight,
        })}`}
      >
        <Box
          id="timeline"
          className={`${getPaddingClass({
            pt: paddingTop, pb: paddingBottom, pl: paddingLeft, pr: paddingRight,
          })} ${isTopFixed ? 'fixed top-20' : 'static'} ${isBottomFixed ? 'bottom-0' : 'bottom-[initial]'} ${isTopFixed || isBottomFixed ? 'h-auto' : 'h-full'} overflow-y-auto bg-white z-10`}
        >
          <TimeLine
            {...timeLineContents}
            selectedItem={selectedItem}
            onItemClick={handleTimelineItemClick}
          />
        </Box>
      </Grid>
      <Grid item id="content">
        {items.map((component, index) => (
          <Box
            key={component.id}
            ref={(el: HTMLDivElement) => {
              sectionRefs.current[index] = el;
              return sectionRefs;
            }}
            className="p-4"
          >
            <FullWidth content={component.component} />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default TableContent;
