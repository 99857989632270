import React, { useMemo } from 'react';
import {
  createTheme,
  Grid, ThemeProvider, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { map } from 'lodash/fp';

import SectionColumn from '#components/SectionColumn';
import useColor from '#hooks/useColor';
import useResponsiveColumnSettings from '#hooks/useResponsiveColumnSettings';
import useSectionSpacing from '#hooks/useSectionSpacing';
import type { AnyContentfulComponentDefinition } from '#contentful/ContentfulComponent';
import ContentfulComponent from '#contentful/ContentfulComponent';

import type {
  FourColumnSectionDefinition,
} from './FourColumnSectionDefinition';
import SectionSettings from '../SectionSettings';
import ColumnDivider from './ColumnDivider';

export type Sec14141414Props = {
  content: FourColumnSectionDefinition<'14141414'>,
};

const Sec14141414 = ({
  content: {
    id,
    customAnchorTag,
    heading,
    headingVariant = 'h2',
    headingFontColor = 'implightblue100',
    background,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    sectionColumnsHorizontalAlignment,
    sectionColumnsVerticalAlignment,
    columnGap,
    columnDivider = false,
    flairGraphic,
    flairPosition,
    borderRadiusTop,
    borderRadiusBottom,
    border,
    column1,
    column1Background,
    column1Settings,
    column2,
    column2Background,
    column2Settings,
    column3,
    column3Background,
    column3Settings,
    column4,
    column4Background,
    column4Settings,
    spacingSettings,
  },
}: Sec14141414Props) => {
  const baseTheme = useTheme();
  const headingColor = useColor('color', headingFontColor);

  const theme = useMemo(() => createTheme(baseTheme, {
    typography: {
      body2: {
        fontSize: 14,
      },
    },
  }), [baseTheme]);

  //  Calculate section settings
  const {
    mt,
    mb,
    ml,
    mr,
    pt,
    pb,
    pl,
    pr,
    responsiveJustifyContent,
    responsiveAlignItems,
    responsiveColumnSpacing,
  } = useSectionSpacing(
    {
      ...spacingSettings,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      sectionColumnsHorizontalAlignment,
      sectionColumnsVerticalAlignment,
      columnSpacing: columnGap,
    },
    {
      columnSpacingsm: 0,
    },
  );

  const column1ResponsiveSettings = useResponsiveColumnSettings({
    ...column1Settings,
  });
  const column2ResponsiveSettings = useResponsiveColumnSettings({
    ...column2Settings,
  });
  const column3ResponsiveSettings = useResponsiveColumnSettings({
    ...column3Settings,
  });
  const column4ResponsiveSettings = useResponsiveColumnSettings({
    ...column4Settings,
  });

  //  divider display
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('xl'));

  return (
    <SectionSettings
      anchorTagId={customAnchorTag ?? id}
      {...background}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
      flairGraphic={flairGraphic}
      flairPosition={flairPosition}
      borderRadiusTop={borderRadiusTop}
      borderRadiusBottom={borderRadiusBottom}
      border={border}
    >
      {heading && (
      <Typography
        variant={headingVariant}
        textAlign="center"
        sx={{ color: headingColor }}
      >
        {heading}
      </Typography>
      )}

      <Grid
        container
        flexWrap={{ xs: 'wrap', xl: 'nowrap' }}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={responsiveJustifyContent}
        alignItems={responsiveAlignItems}
        columnSpacing={responsiveColumnSpacing}
      >
        <ThemeProvider theme={theme}>

          <Grid item xs={12} md={6} lg={6} xl={3}>
            <SectionColumn
              {...column1ResponsiveSettings}
              alignItems="center"
              color={column1Background?.color}
              image={column1Background?.image}
            >
              {map((component: AnyContentfulComponentDefinition) => (
                <Grid item key={component.id} height="100%">
                  <ContentfulComponent content={component} />
                </Grid>
              ), column1)}
            </SectionColumn>
          </Grid>
          {columnDivider && <ColumnDivider />}
          <Grid item xs={12} md={6} lg={6} xl={3}>
            <SectionColumn
              {...column2ResponsiveSettings}
              alignItems="center"
              color={column2Background?.color}
              image={column2Background?.image}
            >
              {map((component: AnyContentfulComponentDefinition) => (
                <Grid item key={component.id} height="100%">
                  <ContentfulComponent content={component} />
                </Grid>
              ), column2)}
            </SectionColumn>
          </Grid>
          {columnDivider && <ColumnDivider flexItem={isDesktop} />}
          <Grid item xs={12} md={6} lg={6} xl={3}>
            <SectionColumn
              {...column3ResponsiveSettings}
              alignItems="center"
              color={column3Background?.color}
              image={column3Background?.image}
            >
              {map((component: AnyContentfulComponentDefinition) => (
                <Grid item key={component.id} height="100%">
                  <ContentfulComponent content={component} />
                </Grid>
              ), column3)}
            </SectionColumn>
          </Grid>
          {columnDivider && <ColumnDivider />}
          <Grid item xs={12} md={6} lg={6} xl={3}>
            <SectionColumn
              {...column4ResponsiveSettings}
              alignItems="center"
              color={column4Background?.color}
              image={column4Background?.image}
            >
              {map((component: AnyContentfulComponentDefinition) => (
                <Grid item key={component.id} height="100%">
                  <ContentfulComponent content={component} />
                </Grid>
              ), column4)}
            </SectionColumn>
          </Grid>
        </ThemeProvider>
      </Grid>
    </SectionSettings>
  );
};

export default Sec14141414;
