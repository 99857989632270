import type { FC } from 'react';
import React from 'react';
import type { SxProps } from '@mui/material';
import { useMediaQuery, useTheme, Box } from '@mui/material';
import loadable from '@loadable/component';

import getLowerCaseLetters from '#utils/getLowerCaseLetters';
import { reduce } from 'lodash/fp';
import { isArray } from 'lodash';

const FlairBlobBlue = loadable(() => import('./FlairGraphic/FlairBlobBlue'));
const FlairBlobGreen = loadable(() => import('./FlairGraphic/FlairBlobGreen'));
const FlairDotsBlue = loadable(() => import('./FlairGraphic/FlairDotsBlue'));
const FlairDotsGreen = loadable(() => import('./FlairGraphic/FlairDotsGreen'));
const FlairLinesBlue = loadable(() => import('./FlairGraphic/FlairLinesBlue'));
const FlairLinesGreen = loadable(() => import('./FlairGraphic/FlairLinesGreen'));
const FlairCirclePink = loadable(() => import('./FlairGraphic/FlairCirclePink'));
const FlairRectBlue = loadable(() => import('./FlairGraphic/FlairRectBlue'));

export type FlairPosition = 'Top Left' | 'Top Right' | 'Bottom Left' | 'Bottom Right';
export type FlairGraphic = 'Blob Blue' | 'Blob Green' | 'Cicle Pink' | 'Dots Blue' | 'Dots Green' | 'Lines Blue' | 'Lines Green' | 'Rect Blue';

interface Props {
  position?: FlairPosition;
  graphic?: FlairGraphic;
}

type PositionIndex = 'bottomleft' | 'bottomright' | 'topleft' | 'topright';

const offset = 0;
const titleSpace = 68;
const baseStyle = {
  filter: 'hue-rotate(3.142rad)',
  opacity: 0,
  position: 'absolute',
  zIndex: -101,
};

const animationStyleBuilder = (axisDistance: string) => ({ transform: `translateX(${axisDistance}) scale(1.2)` });

const animationStyle = {
  bottomleft: animationStyleBuilder('-50%'),
  bottomright: animationStyleBuilder('50%'),
  topleft: animationStyleBuilder('-50%'),
  topleftwithtitle: animationStyleBuilder('-50%'),
  topright: animationStyleBuilder('50%'),
};

type OffsetFieldDef = string | [string, number];
const buildStyle = (...fields: OffsetFieldDef[]) => reduce(
  (memo: SxProps<any>, field: OffsetFieldDef) => {
    if (isArray(field)) {
      return {
        ...memo,
        [field[0]]: `${field[1]}px`,
      } as SxProps<any>;
    }

    return {
      ...memo,
      [field]: `${offset}px`,
    } as SxProps<any>;
  },
  baseStyle,
  fields,
);

const stylePosition = {
  bottomleft: buildStyle('bottom', 'left'),
  bottomright: buildStyle('bottom', 'right'),
  topleft: buildStyle('top', 'left'),
  topleftwithtitle: buildStyle(['top', titleSpace], 'left'),
  topright: buildStyle('top', 'right'),
  toprightwithtitle: buildStyle(['top', titleSpace], 'right'),
};

type DesignIndex = 'blobblue' | 'blobgreen' | 'circlepink' | 'dotsblue' | 'dotsgreen' | 'linesblue' | 'linesgreen' | 'rectblue';

const designs = {
  blobblue: <FlairBlobBlue />,
  blobgreen: <FlairBlobGreen />,
  circlepink: <FlairCirclePink />,
  dotsblue: <FlairDotsBlue />,
  dotsgreen: <FlairDotsGreen />,
  linesblue: <FlairLinesBlue />,
  linesgreen: <FlairLinesGreen />,
  rectblue: <FlairRectBlue />,
};

const Flair: FC<Props> = ({
  position,
  graphic,
}) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('lg'));

  let pos = position;
  if (isMobile) {
    pos = position?.replace('Top', 'Bottom') as FlairPosition;
    if (pos?.includes('with Title')) {
      pos = pos?.replace('with Title', '')?.trim() as FlairPosition;
    }
  }

  const location = getLowerCaseLetters(pos);
  const picture = getLowerCaseLetters(graphic);
  if (!location || !picture) {
    return null;
  }

  return (
    <Box
      sx={{
        ...stylePosition[location as PositionIndex],
        ...animationStyle[location as PositionIndex],
      }}
      className="flair transition-ease-1"
    >
      {designs[picture as DesignIndex]}
    </Box>
  );
};

export default Flair;
