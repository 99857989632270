export interface ArloPagedRequest {
  skip: number;
  top: number;
}

export interface ArloEventsFilter {
  eventId?: Array<number>;
  venueId?: Array<number>;
  searchEventsStartMin?: Date;
  searchEventsStartMax?: Date;
  courseTemplateId?: Array<number>;
  presenterId?: Array<number>;
}

export type ArloRegionCodes = 'us' | 'mx' | 'ca' | 'uk';
const AllowedArloRegions = ['us', 'mx', 'ca', 'uk'];
export function GetArlowRegionCode(countryCode: string) :ArloRegionCodes {
  // GB is considered UK here
  const fixedCountryCode = countryCode.toLowerCase().replace('gb', 'uk');

  return AllowedArloRegions.includes(fixedCountryCode)
    ? fixedCountryCode as ArloRegionCodes : 'us';
}

export type CurrencyCode = 'USD' | 'CAD' | 'MXN' | 'GBP';
