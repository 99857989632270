import React from 'react';
import { Typography } from '@mui/material';

import formatDateTime from '#utils/formatDateTime';

import formatTime from './formatTime';
import Time from './Time';

export type TimeRangeProps = {
  start: Date,
  end: Date,
};

const TimeRange = ({
  start,
  end,
}: TimeRangeProps) => {
  const startTime = formatTime(start);
  const endTime = formatTime(end);
  const timeZone = formatDateTime(
    end!,
    {
      month: undefined,
      day: undefined,
      timeZoneName: 'short',
    },
  ).match(/([A-Z])+/g)?.[0];

  return (
    <div className="w-full flex gap-0.5 justify-between flex-wrap">
      <div>
        <Time time={startTime} />
      </div>
      <div>
        -
      </div>
      <div>
        <Time time={endTime} />
      </div>
      <div className="w-full xl:w-10 pl-0 xl:pl-2">
        <Typography variant="body3" className="italic">
          {timeZone}
        </Typography>
      </div>
    </div>
  );
};

export default TimeRange;
