import type { RowGap } from '#components/CardHolder/types';

const BASE_UNIT = 16;

export default (rowGap: RowGap = 'None') => {
  switch (rowGap) {
    case 'None':
      return '0px';
    case 'Small':
      return `${BASE_UNIT}px`;
    case 'Medium':
      return `${2 * BASE_UNIT}px`;
    case 'Large':
      return `${3 * BASE_UNIT}px`;
    case 'X-Large':
      return `${4 * BASE_UNIT}px`;
    default:
      return `${BASE_UNIT}px`;
  }
};
