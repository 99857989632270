import React from 'react';
import {
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import type { GatsbyImageProps, ImageDefinition } from '#components/ContentfulComponent/Image';
import type { RichTextContent } from '#components/RichText';
import type { ContentfulAlignment } from '#hooks/useContentfulAlignment';
import RichText from '#components/RichText';
import Button from '#components/Button';
import type { BackgroundDefinition } from '#components/Section/SectionSettings';
import SectionSettings from '#components/Section/SectionSettings';
import useColor from '#hooks/useColor';
import type { ResponsiveTextAlign } from '#hooks/useResponsiveTextAlign';
import useContentfulAlignment from '#hooks/useContentfulAlignment';
import useResponsiveTextAlign from '#hooks/useResponsiveTextAlign';
import useResponsiveSpacing from '#hooks/useResponsiveSpacing';
import type { ContentfulSpacing } from '#components/Layout/theme/spacing.theme';
import useResponsiveJustifyContent from '#hooks/useResponsiveJustifyContent';
import Image from '#contentful/Image';

export type HeadingVariant = 'h2' | 'h3';

export type BaseBlockProps = {
  background?: BackgroundDefinition,
  alignment?: ContentfulAlignment,
  overline?: string,
  badgeIcon?: GatsbyImageProps,
  image?: ImageDefinition,
  imagePlacement?: string,
  heading?: string,
  headingFontColor?: string,
  headingVariant?: HeadingVariant,
  subHeading?: string,
  subheadingFontColor?: string,
  info?: RichTextContent,
  richTextFontColor?: string
  ctaLabel?: string,
  href?: string,
  slug?: string,
  pt?: ContentfulSpacing,
  pb?: ContentfulSpacing,
  pl?: ContentfulSpacing,
  pr?: ContentfulSpacing,
  mt?: ContentfulSpacing,
  mb?: ContentfulSpacing,
  ml?: ContentfulSpacing,
  mr?: ContentfulSpacing,
  cornerRounding?: boolean,
};

const translationKey = 'Components.BaseBlock';

const BaseBlock = ({
  background,
  alignment,
  overline,
  image,
  imagePlacement,
  heading,
  headingFontColor,
  headingVariant = 'h2',
  subHeading,
  subheadingFontColor,
  info,
  richTextFontColor,
  ctaLabel,
  href,
  slug,
  pt,
  pb,
  pl,
  pr,
  mt,
  mb,
  ml,
  mr,
  cornerRounding,
}: BaseBlockProps) => {
  const { t } = useTranslation();
  const { spacing } = useTheme();

  const headingTextColor = useColor('color', headingFontColor);
  const subheadingTextColor = useColor('color', subheadingFontColor);
  const richTextTextColor = useColor('color', richTextFontColor);

  const textAlign = (alignment as ResponsiveTextAlign); //  values are the same
  const responsiveTextAlign = useResponsiveTextAlign({
    xs: 'left',
    sm: textAlign ?? 'center',
  });
  const alignItems = useContentfulAlignment(alignment ?? 'center');
  const responsiveJustifyContent = useResponsiveJustifyContent({
    xs: 'flex-start',
    sm: alignItems ?? 'center',
  });

  const paddingLeft = useResponsiveSpacing({ xs: 2, md: pl ?? 2 });
  const paddingRight = useResponsiveSpacing({ xs: 2, md: pr ?? 2 });

  const marginLeft = useResponsiveSpacing({ xs: 0, md: ml ?? 0 });
  const marginRight = useResponsiveSpacing({ xs: 0, md: mr ?? 0 });

  const renderImage = () => {
    if (!image) {
      return null;
    }

    return (
      <Grid
        item
        xs={12}
        sx={{ mb: 1 }}
      >
        <Image
          content={image}
        />
      </Grid>
    );
  };

  return (
    <SectionSettings
      {...background}
      mt={spacing(mt ?? 1)}
      mb={spacing(mb ?? 1)}
      ml={marginLeft}
      mr={marginRight}
      pt={spacing(pt ?? 2)}
      pb={spacing(pb ?? 2)}
      pl={paddingLeft}
      pr={paddingRight}
      borderRadiusTop={cornerRounding}
      borderRadiusBottom={cornerRounding}
      textAlign={responsiveTextAlign}
      height="100%"
    >
      <Grid
        container
        justifyContent={responsiveJustifyContent}
        maxWidth="780px"
        mx="auto"
      >
        {overline && (
          <Grid item xs={12}>
            <Typography
              variant="overline2"
              sx={{ color: subheadingTextColor }}
            >
              {overline}
            </Typography>
          </Grid>
        )}

        {(imagePlacement === 'Above Heading' || !imagePlacement)
          && renderImage()}

        {heading && (
          <Grid item xs={12}>
            <Typography
              variant={headingVariant ?? 'h2'}
              sx={{ color: headingTextColor }}
            >
              {heading}
            </Typography>
          </Grid>
        )}

        {(imagePlacement === 'Below Heading' || !imagePlacement)
          && renderImage()}

        {subHeading && (
          <Grid item xs={12}>
            <Typography
              variant="subheading2"
              sx={{ color: subheadingTextColor }}
            >
              {subHeading}
            </Typography>
          </Grid>
        )}

        {(imagePlacement === 'Below Subheading' || !imagePlacement)
          && renderImage()}

        {info && (
          <Grid
            item
            xs={12}
            mt={1}
            sx={{ color: richTextTextColor }}
          >
            <RichText content={info} />
          </Grid>
        )}

        {(imagePlacement === 'Below Text' || !imagePlacement)
          && renderImage()}

        {(href || slug) && (
          <Grid item xs={12} mt={1}>
            <Button
              variant="primary"
              href={href}
              slug={slug}
            >
              {ctaLabel ?? t(`${translationKey}.ReadMore`)}
            </Button>
          </Grid>
        )}
      </Grid>
    </SectionSettings>
  );
};

export default BaseBlock;
