import type { ChangeEvent } from 'react';
import React, { useCallback, useContext } from 'react';
import {
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import type { ArloRegionCodes } from '#services/arlo';
import { GeolocationContext } from '#providers/GeolocationProvider';

type Region = {
  value: ArloRegionCodes,
  label: string,
};

const currencies: Region[] = [
  {
    value: 'us',
    label: 'United States',
  },
  {
    value: 'ca',
    label: 'Canada',
  },
  {
    value: 'mx',
    label: 'Mexico',
  },
  {
    value: 'uk',
    label: 'United Kingdom',
  },
];

export type RegionSelectorProps = {
  defaultValue: ArloRegionCodes | null
};

const translationKey = 'Components.RegionSelector';

const RegionSelector = ({
  defaultValue,
}: RegionSelectorProps) => {
  const { region, setRegion } = useContext(GeolocationContext);
  const { t } = useTranslation();

  const regionChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => (
      setRegion(e.target.value as ArloRegionCodes)
    ),
    [setRegion],
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      maxWidth={200}
      data-testid="region-selector"
    >
      <Grid item>
        <Typography variant="caption" my={1}>
          <b>
            {t(`${translationKey}.ResidentOf`)}
          </b>
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          id="outlined-select-Region"
          size="small"
          select
          value={region ?? defaultValue ?? currencies[0].value}
          onChange={regionChangeHandler}
          sx={{
            width: 200,
            height: 40,
            textAlign: 'left',
          }}
          inputProps={
            {
              'data-testid': 'region-selector-input',
            }
          }
        >
          {currencies.map((option: Region) => (
            <MenuItem key={option.value} value={option.value} data-testid={`region-option-${option.value}`}>
              <Typography variant="body3">
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default RegionSelector;
