import React, { useEffect, useState } from 'react';
import type { PropsWithChildren } from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { Box, styled } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';
import type { SectionDefinition } from '#components/Section';
import { slashySlug } from '#components/SmartLink';

enum DecorationType {
  None = 'None',
  Underline = 'Underline',
  Italic = 'Italic',
}

enum FontWeight {
  Default = 'Default',
  Bold = 'Bold',
}

export type ContentfulComponentLinkDefinition = ContentfulComponentDefinition & {
  label?: string,
  contentfulId?: string,
  openInNewWindow?: boolean,
  href?: string,
  page?: {
    slug: string
  },
  linkStyle?: 'Default' | 'Arrow',
  anchorTag?: SectionDefinition,
  manualAnchorTag?: string,
  decoration: DecorationType,
  fontWeight: FontWeight,
  internal: {
    type: 'ContentfulComponentLink'
  }
};

export type LinkProps = PropsWithChildren<{
  content: ContentfulComponentLinkDefinition,
  styles?: any,
  props?: any,
  className?: string,
}>;

const getTextStyle = (decoration: DecorationType, fontWeight: FontWeight, isHovered: boolean) => ({
  fontStyle: decoration === DecorationType.Italic ? 'italic' : 'normal',
  fontWeight: fontWeight === FontWeight.Bold ? 'bold' : 'normal',
  textDecoration: (decoration === DecorationType.Underline || isHovered) ? 'underline' : 'none',
});

const ContentfulLink = ({
  content: {
    openInNewWindow = false,
    label,
    href,
    page,
    anchorTag,
    manualAnchorTag,
    linkStyle,
    decoration,
    fontWeight,
  },
  styles = { color: '#5bc2a7' },
  props,
  className,
  children,
}: LinkProps) => {
  const { language } = useI18next();
  const [isHovered, setIsHovered] = useState(false);
  const [textStyle, setTextStyle] = useState(getTextStyle(decoration, fontWeight, isHovered));

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setTextStyle(getTextStyle(decoration, fontWeight, isHovered));
  }, [decoration, fontWeight, isHovered]);

  const renderContent = () => (
    <>
      {linkStyle === 'Arrow' && <EastIcon style={{ verticalAlign: 'middle', marginRight: '4px' }} />}
      {label ?? children}
    </>
  );

  if (anchorTag) {
    return (
      <Box className={className}>
        <a
          href={anchorTag.customAnchorTag || anchorTag.id || manualAnchorTag}
          style={{ lineHeight: 2, ...textStyle, ...styles }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {renderContent()}
        </a>
      </Box>
    );
  }

  return (
    <Box className={className}>
      {openInNewWindow
        ? (
          <a
            className="ContentfulLink"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              lineHeight: 2,
              ...textStyle,
              ...styles,
            }}
            {...props}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {renderContent()}
          </a>
        )
        : (
          <Link
            className="ContentfulLink"
            to={slashySlug(page?.slug)}
            language={language}
            style={{
              lineHeight: 2,
              ...styles,
              ...textStyle,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...props}
          >
            {renderContent()}
          </Link>
        )}
    </Box>
  );
};

export default styled(ContentfulLink)({});
