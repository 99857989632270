import React from 'react';
import {
  Card, CardContent, Grid, Box, Typography,
} from '@mui/material';

import useBorder from '#hooks/useBorder';
import ExpertiseCardContent from './ExpertiseCardContent';
import type { InteractiveExpertiseCard } from '../InteractiveExpertiseGraphic';
import CardBanner from './CardBanner';

type ExpertiseCardProps = {
  card: InteractiveExpertiseCard,
  bannerColor: string,
};

const ExpertiseCard = ({
  card,
  bannerColor,
}: ExpertiseCardProps) => {
  const { border, content, title } = card || {};
  const borderSettings = useBorder(border);

  return (
    <Card
      sx={{
        ...borderSettings,
        backgroundColor: 'rgba(237, 242, 246, 0.8)',
        opacity: 1,
        backgroundSize: 'cover',
        zIndex: 1,
        paddingX: '1.2em',
        paddingTop: '1.2em',
        marginTop: '1.8em',
        position: 'relative',
      }}
    >
      <CardContent sx={{
        paddingTop: '5px',
      }}
      >
        <Box sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '12px',
          position: 'relative',
        }}
        >
          <CardBanner
            fill={bannerColor}
            style={{
              marginLeft: '-40px',
              width: '100%',
            }}
          />
          <Typography
            variant="h3"
            sx={{
              color: '#fff',
              margin: 0,
              position: 'absolute',
              backgroundColor: 'transparent',
              fontSize: {
                xs: '1.1rem !important',
                md: '1.3rem !important',
                lg: '1.75rem',
              },
            }}
          >
            {title}
          </Typography>
        </Box>
        <Grid
          container
          marginBottom="1em"
          sx={{
            position: 'relative',
          }}
        >
          {content && content.map((item) => (
            <Grid item xs={12} key={item.id}>
              <ExpertiseCardContent content={item} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExpertiseCard;
