import { graphql, useStaticQuery } from 'gatsby';
import type {
  ContentfulBlogCardDefinition,
} from '#components/BlogCard';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { filter } from 'lodash/fp';
import { useMemo } from 'react';

export type BlogAuthor = {
  authors: {
    contentfulId: string,
  }[],
};

export type BlogCardWithAuthor = ContentfulBlogCardDefinition & BlogAuthor;

export default (): BlogCardWithAuthor[] => {
  const data = useStaticQuery(graphql`
    query {
      all: allContentfulPageTemplateBlogPost(
        sort: { datePublished: DESC }
        filter: {
          compose__page: {
            elemMatch: {
              slug: { ne: null },
            }
          },
          category: {
            elemMatch: {
              displayName: { ne: "Newsroom" }
            }
          }
        }
      ) {
        cards: nodes {
          ...BlogCardInfo
          authors {
            contentfulId: contentful_id
          }
        }
      }
    }
  `);

  const { language } = useI18next();

  const filteredCards = useMemo(() => filter(
    ({ nodeLocale }) => nodeLocale === language,
    data.all.cards,
  ), [data, language]);

  return filteredCards;
};
