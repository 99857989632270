import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useContentfulImage } from 'gatsby-source-contentful/hooks';

import Button from '#components/Button';
import type { GatsbyImageProps } from '#contentful/Image';
import type {
  ContentfulComponentDefinition,
} from '#components/ContentfulComponent';
import SmartLink from '#components/SmartLink';
import type { BlogCategoryDefinition } from '#components/CardHolder';
import useScrollState from '#hooks/useScrollState';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export type ContentfulBlogCardDefinition = ContentfulComponentDefinition & {
  categories: BlogCategoryDefinition[],
  title: string,
  snippet: {
    text: string,
  }
  thumbnail: GatsbyImageProps,
  composePage: {
    slug: string
  }[],
  contentfulId: string,
  nodeLocale: string,
  internal: {
    type: 'ContentfulPageTemplateBlogPost'
  }
};

export type BlogCardProps = {
  image: GatsbyImageProps,
  category: string,
  title: string,
  summary: {
    text: string,
  }
  slug: string,
  isBlog?: boolean,
};

const translationKey = 'Components.BlogCard';

const BlogCard = ({
  image,
  category,
  title,
  summary,
  slug,
  isBlog,
}: BlogCardProps) => {
  const { setScrollPosition } = useScrollState();
  const { t } = useTranslation();

  const url = image?.url ?? '';
  const alt = image?.alt ?? '';

  const dynamicImage = useContentfulImage({
    image: {
      url: url.replace('https:', ''),
      width: 600,
      height: 375,
      resizingBehavior: 'crop',
      quality: 100,
    },
  });

  const updateScrollPosition = () => {
    const scrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
    setScrollPosition(window.location.pathname, scrollPosition);
  };

  const getButtonLabel = () => {
    if (isBlog) {
      return category === 'Newsroom' ? t(`${translationKey}.NewsroomButton`) : t(`${translationKey}.BlogButton`);
    }
    return t(`${translationKey}.CaseButton`);
  };

  return (
    <Card
      elevation={0}
      className="hover-scale transition-ease-1"
    >
      <SmartLink sx={{ textDecoration: 'none' }} slug={slug}>
        <GatsbyImage
          loading="lazy"
          image={dynamicImage}
          alt={alt}
          style={{ width: '100%' }}
        />
        <CardContent
          sx={{
            minHeight: 'calc(100% - 336px)',
            color: 'color.impgray900',
          }}
        >
          <Typography
            variant="overline2"
          >
            {category?.toUpperCase()}
          </Typography>

          <Typography
            variant="h4"
            sx={{
              mt: 0.5,
              mb: 1,
              wordBreak: 'break-word',
            }}
            color="color.impblue300"
          >
            {title}
          </Typography>

          <Typography variant="body2">
            {summary?.text}
          </Typography>
        </CardContent>
      </SmartLink>

      <CardActions>
        <Button
          variant="primary"
          slug={slug}
          onClick={updateScrollPosition}
        >
          {getButtonLabel()}
        </Button>
      </CardActions>
    </Card>
  );
};

export default BlogCard;
