import { isArray, isEmpty } from 'lodash/fp';

import type { SectionChildren } from '#components/Section';

export type ReverseDirection = string;

export default (
  leftColumn: SectionChildren,
  rightColumn: SectionChildren,
): ReverseDirection => {
  if (isEmpty(leftColumn) || !isArray(leftColumn) || !isArray(rightColumn)) {
    return '';
  }

  const validReverse = leftColumn.length === 1
    && leftColumn[0].internal.type === 'ContentfulComponentImage'
    && !rightColumn.find((col) => col.internal.type === 'ContentfulComponentImage');

  if (validReverse) {
    return '-reverse';
  }

  return '';
};
