import React from 'react';
import {
  Box, Grid,
} from '@mui/material';

import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import type { LinkDefinition } from '#contentful/Link';
import ContentfulLink from '#contentful/Link';
import ExpertiseGraphic from './ExpertiseGraphic';
import type { ExpertiseCardContentDefinition } from './ExpertiseCard/ExpertiseCardContent';
import ExpertiseCard from './ExpertiseCard';

export type InteractiveExpertiseGraphicDefinition = InteractiveExpertiseGraphicProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentInteractiveExpertiseGraphic',
  },
};

type BackgroundColor = {
  value: string,
};

export type InteractiveExpertiseCard = {
  content: ExpertiseCardContentDefinition[],
  border: ContentfulBorderDefinition,
  title: String,
};

export type InteractiveExpertiseSettings = {
  activeBackgroundColor: BackgroundColor,
  hoverBackgroundColor: BackgroundColor,
  nonActiveBackgroundColor: BackgroundColor,
  interactiveExpertiseCard: InteractiveExpertiseCard,
};

export type InteractiveExpertiseGraphicProps = {
  platformEngineeringSettings: InteractiveExpertiseSettings,
  aiMlSettings: InteractiveExpertiseSettings,
  modernDataSettings: InteractiveExpertiseSettings,
  applicationModernizationSettings: InteractiveExpertiseSettings,
  businessAgilitySettings: InteractiveExpertiseSettings,
  modernCollaborationSettings: InteractiveExpertiseSettings,
  businessProcessAutomationSettings: InteractiveExpertiseSettings,
  productDeliverySettings: InteractiveExpertiseSettings,
  link: LinkDefinition,
  id: string,
  internal: {
    type: 'ContentfulComponentInteractiveExpertiseGraphic',
  },
};

const InteractiveExpertiseGraphic = (
  props
  : InteractiveExpertiseGraphicProps,
) => {
  const {
    id,
    internal,
    link,
    ...expertiseSettings
  } = props;

  const [activeElement, setActiveElement] = React.useState<string | null>(`platformEngineeringSettings${id}`);

  const settingKey = activeElement?.replace(id, '');
  const setting = expertiseSettings[settingKey as keyof typeof expertiseSettings];

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
        marginTop: '1em',
      }}
    >
      <Grid
        container
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        {link && (
        <Grid
          item
          xs={12}
          pr={{ xs: 2, md: 0 }}
          width={{ xs: '100%', sm: 400 }}
          mb={{ xs: '-1em' }}
          zIndex={2}
        >
          <Box display="flex" justifyContent="flex-end">
            <ContentfulLink
              content={link}
            >
              {link.label}
            </ContentfulLink>
          </Box>
        </Grid>
        )}
        <Grid item xs={12} lg={6}>
          {activeElement && (
            <ExpertiseGraphic
              activeElement={activeElement}
              setActiveElement={setActiveElement}
              {...props}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {setting && (
            <ExpertiseCard
              card={setting?.interactiveExpertiseCard}
              bannerColor={setting?.activeBackgroundColor?.value}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default InteractiveExpertiseGraphic;
