import React from 'react';
import type { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import type { GatsbyImageProps } from '#contentful/Image';
import Flair from '#components/Flair/Flair';
import type { FlairPosition, FlairGraphic } from '#components/Flair/Flair';
import type { ResponsiveTextAlign } from '#hooks/useResponsiveTextAlign';
import type { SpacingValue } from '#components/Layout/theme/spacing.theme';
import useBorderRadius from '#hooks/useBorderRadius';
import { spacing } from '#components/Layout/theme/spacing.theme';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import useBorder from '#hooks/useBorder';
import { GatsbyImage } from 'gatsby-plugin-image';

export type BackgroundDefinition = PropsWithChildren<{
  color?: {
    value: string,
  },
  image?: GatsbyImageProps,
  mt?: SpacingValue,
  mb?: SpacingValue,
  ml?: SpacingValue,
  mr?: SpacingValue,
  pt?: SpacingValue,
  pb?: SpacingValue,
  pl?: SpacingValue,
  pr?: SpacingValue,
  textAlign?: ResponsiveTextAlign,
  maxWidth?: string,
  flairGraphic?: FlairGraphic,
  flairPosition?: FlairPosition,
  borderRadiusTop?: boolean,
  borderRadiusBottom?: boolean,
  border?: ContentfulBorderDefinition,
  anchorTagId?: string,
  height?: string,
}>;

const SectionSettings = ({
  color,
  image,
  mt,
  mb,
  ml,
  mr,
  pt,
  pb,
  pl,
  pr,
  textAlign,
  maxWidth,
  flairGraphic,
  flairPosition,
  borderRadiusTop = false,
  borderRadiusBottom = false,
  border,
  anchorTagId,
  children,
  height,
}: BackgroundDefinition) => {
  const imageData = image?.gatsbyImageData;
  const topRoundBorder = useBorderRadius(borderRadiusTop ?? border?.roundTopBorder);
  const bottomRoundBorder = useBorderRadius(borderRadiusBottom ?? border?.roundBottomBorder);
  const hasBorder = borderRadiusTop || borderRadiusBottom || border;
  const borderStyles = useBorder(border);

  return (
    <>
      <div id={anchorTagId} />
      <Box
        maxWidth={maxWidth || '100%'}
        sx={{
          mt: spacing(mt),
          mb: spacing(mb),
          ml: spacing(ml),
          mr: spacing(mr),
          borderTopLeftRadius: topRoundBorder,
          borderTopRightRadius: topRoundBorder,
          borderBottomLeftRadius: bottomRoundBorder,
          borderBottomRightRadius: bottomRoundBorder,
          overflowY: {
            xs: 'clip',
            md: hasBorder ? 'hidden' : 'visible',
          },
          overflowX: {
            xs: 'hidden',
            md: hasBorder ? 'hidden' : 'visible',
          },
          position: 'relative',
          height: height ?? 'auto',
        }}
        className="section-settings"
      >

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: color?.value,
            zIndex: -1,
            '::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: color?.value,
              zIndex: -1,
            },
          }}
        >
          {imageData && (
          <GatsbyImage
            image={imageData}
            loading="lazy"
            alt="Background Image"
            style={{ position: 'relative', width: '100%', height: '100%' }}
          />
          )}
        </Box>
        <Flair position={flairPosition} graphic={flairGraphic} />
        <Box
          sx={{
            pt: spacing(pt),
            pb: spacing(pb),
            pl: spacing(pl),
            pr: spacing(pr),
            textAlign,
            position: 'relative',
            ...borderStyles,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default SectionSettings;
