import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';

export type TrainingCourseCardDefinition = ContentfulComponentDefinition & {
  title: string,
  isVirtual: boolean,
  startDate: Date,
  endDate: Date,
  startTime: string,
  endTime: string,
  timeZone: {
    name: string,
    abbreviation: string,
    uctOffset: number,
  },
};

export type TrainingCourseCardProps = {
  card: TrainingCourseCardDefinition
};

const translationKey = 'Components.TrainingCourseCard';

const TrainingCourseCard = ({
  card: {
    title,
    isVirtual,
    startDate,
    endDate,
    startTime,
    endTime,
    timeZone,
  },
}: TrainingCourseCardProps) => {
  const { t } = useTranslation();

  return (
    <Card
      className="course-card rounded"
      sx={{ maxWidth: 400 }}
    >
      <CardContent>
        <Typography variant="h4">
          {
          isVirtual
            ? (
              <>
                <b>
                  {t(`${translationKey}.Online`)}
                  :
                  {' '}
                </b>
                {' '}
                {t(`${translationKey}.LiveClass`)}
              </>
            )
            : <>{t(`${translationKey}.Place`)}</>
          }
        </Typography>

        <Typography>
          {title}
        </Typography>

        <div className="line" />

        <Typography variant="body1">
          <>
            {startDate}
            {endDate ? ` - ${endDate}` : ''}
          </>
        </Typography>

        <Typography variant="body2">
          {startTime}
          {' '}
          -
          {endTime}
          {' '}
          {timeZone.abbreviation}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TrainingCourseCard;
