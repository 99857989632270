import React, { useMemo } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  CardMedia,
} from '@mui/material';

import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';
import SmartLink from '#components/SmartLink';
import WebinarCtaButton from '#components/WebinarCtaButton';

import placeholderImage from '#images/comingSoon.jpg';
import formatDateTime from '#utils/formatDateTime';

export type ContentfulWebinarCardDefinition = ContentfulComponentDefinition & {
  title: string,
  snippet: {
    text: string,
  }
  startDateTime: string,
  endDateTime: string,
  webinarPage: {
    composePage: {
      slug: string
    }[],
  }[],
  registrationUrl: {
    text: string,
  },
  videoUrl: {
    text: string,
  },
  nodeLocale: string,
  internal: {
    type: 'ContentfulComponentWebinar'
  }
};

export type WebinarCardProps = {
  title: string,
  snippet: {
    text: string,
  }
  startDateTime: string,
  slug: string | undefined,
  registrationUrl: string | undefined,
  videoUrl: string | undefined,
};

const WebinarCard = ({
  title,
  snippet,
  startDateTime,
  slug,
  registrationUrl,
  videoUrl,
}: WebinarCardProps) => {
  const isFutureCard = useMemo(
    () => Date.now() < Date.parse(startDateTime),
    [startDateTime],
  );
  const youtubeEmbeddedUrl = useMemo(
    () => videoUrl && `${videoUrl.replace('youtu.be/', 'youtube.com/embed/')}?showinfo=0&modestbranding=1`,
    [videoUrl],
  );

  return (
    <Card
      className="hover-scale transition-ease-1"
      elevation={0}
      sx={{
        px: 0,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        bgcolor: 'inherit',
      }}
    >
      {!isFutureCard && (
        <CardMedia
          component={videoUrl ? 'iframe' : 'img'}
          src={youtubeEmbeddedUrl ?? placeholderImage}
          sx={{
            height: { xs: '200px', sm: '400px', md: '200px' },
            border: 'none',
          }}
          allowFullScreen={!!videoUrl}
          title={videoUrl ? `${title} youtube video` : 'video coming soon'}
        />
      )}

      <CardContent
        sx={{
          p: 2,
          mb: { xs: 0, lg: 2 },
          color: 'color.impgray900',
        }}
      >
        <SmartLink
          slug={slug}
          sx={{
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <Typography variant="overline2">
            {formatDateTime(new Date(startDateTime), {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: 'numeric',
              hourCycle: 'h12',
              minute: 'numeric',
              timeZoneName: 'short',
            })}
          </Typography>

          <Typography
            variant="h4"
            color={
              (isFutureCard || !videoUrl)
                ? 'color.grayMedium'
                : 'color.impblue300'
            }
            mt={0.5}
            mb={1}
          >
            {title}
          </Typography>

          <Typography variant="body2">
            {snippet?.text}
          </Typography>
        </SmartLink>
      </CardContent>
      <CardActions
        sx={{
          position: 'relative',
          bottom: 0,
          justifyContent: 'space-between',
        }}
      >
        <WebinarCtaButton
          isFutureWebinar={isFutureCard}
          registrationUrl={registrationUrl}
          videoUrl={videoUrl}
        />
      </CardActions>
    </Card>
  );
};

export default WebinarCard;
