import React, { useEffect, useState, useCallback } from 'react';
import { TextField, Grid, FormHelperText } from '@mui/material';

import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import { isEmpty } from 'lodash';

export type FormFieldTextDefinition = FormFieldTextProps
& ContentfulComponentDefinition
& {
  defaultValue?: string,
  internal: {
    type: 'ContentfulSubComponentFormFieldText',
  },
};

export type FormFieldTextProps = {
  name: string,
  type: string,
  customName: string,
  displayName: string,
  defaultValue?: string,
  placeholder: string,
  helperText: string,
  required: boolean,
  multiline: boolean,
  maxCharacterCount?: number,
  reset?: number,
};

const FormFieldText = ({
  name,
  type,
  customName,
  displayName,
  placeholder,
  defaultValue,
  helperText = ' ',
  required = false,
  multiline = false,
  maxCharacterCount,
  reset,
}: FormFieldTextProps) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [currentCounter, setCurrentCounter] = useState<number>(0);

  useEffect(() => {
    setHasError(false);
    setCurrentCounter(0);
  }, [reset]);

  const counterHandler = useCallback(
    (value = 0) => ({
      validity: maxCharacterCount ? maxCharacterCount >= value : true,
      printer: maxCharacterCount ? `${currentCounter}/${maxCharacterCount}` : '',
    }),
    [maxCharacterCount, currentCounter],
  );

  const setError = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const element = event?.target;
    const isFilledProperly = required ? !isEmpty(event.target.value.trim()) : true;
    setCurrentCounter(event.target.value.length);

    element.checkValidity()
    && counterHandler(event.target.value.length).validity
    && isFilledProperly
      ? setHasError(false)
      : setHasError(true);
  };

  return (
    <>
      <TextField
        onChange={setError}
        onBlur={setError}
        error={hasError}
        variant="filled"
        color="primary"
        name={name !== 'Other' ? name : customName}
        type={type}
        label={displayName}
        placeholder={placeholder}
        fullWidth
        required={required}
        multiline={multiline}
        rows={multiline ? 4 : 1}
        defaultValue={defaultValue}
      />
      <FormHelperText error={hasError} component="div" variant="filled">
        <Grid container justifyContent="space-between">
          <Grid item>{helperText}</Grid>
          <Grid item>{counterHandler().printer}</Grid>
        </Grid>
      </FormHelperText>
    </>
  );
};

export default FormFieldText;
