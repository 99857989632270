import React from 'react';
import { Grid } from '@mui/material';
import { map } from 'lodash/fp';

import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import type {
  ResponsiveSectionSpacing,
} from '#hooks/useSectionSpacing/types';
import type { BackgroundDefinition } from '#components/Section/SectionSettings';
import SectionSettings from '#components/Section/SectionSettings';
import type { IconButtonDefinition } from '#contentful/IconButton';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import useViewport from '#hooks/useViewport';
import type { ColumnDefinition } from '#components/Column';
import Column from '#components/Column';

export type CarouselItemDefinition = CarouselItemProps
& ContentfulComponentDefinition
& {
  indicatorIcon?: IconButtonDefinition
};

export type CarouselItemContent = {
  columns: ColumnDefinition[],
  background?: BackgroundDefinition,
  border?: ContentfulBorderDefinition,
};

type CarouselItemProps = CarouselItemContent & {
  minHeight?: number,
  responsiveSectionSpacing: ResponsiveSectionSpacing,
};

const CarouselItem = ({
  columns,
  minHeight,
  responsiveSectionSpacing,
  background,
  border,
}: CarouselItemProps) => {
  const isMobile = useViewport() === 'xs';

  const settings = {
    border,
    ...background,
    ...responsiveSectionSpacing,
  };

  return (
    <SectionSettings {...settings}>
      <Grid
        container
        flexWrap="nowrap"
        rowSpacing={{ xs: 0, sm: 2, md: 0 }}
        direction={{
          xs: 'column',
          lg: 'row',
        }}
        justifyContent={responsiveSectionSpacing.responsiveJustifyContent}
        alignItems={responsiveSectionSpacing.responsiveAlignItems}
        columnSpacing={responsiveSectionSpacing.responsiveColumnSpacing}
        sx={{
          mb: 1,
          minHeight: isMobile ? undefined : minHeight,
          overflow: 'hidden',
        }}
      >
        {map((column: ColumnDefinition) => (
          <Column key={column.id} {...column} />
        ), columns)}
      </Grid>
    </SectionSettings>
  );
};

export default CarouselItem;
