import React, {
  createContext,
  useMemo,
  useState,
} from 'react';
import type {
  PropsWithChildren,
} from 'react';
import { noop } from 'lodash/fp';

import type { ArloRegionCodes } from '#services/arlo';
import useQueryRegion from './_useQueryRegion';
import useDeviceRegion from './_useDeviceRegion';

export type GeolocationState = {
  region: ArloRegionCodes | null;
  setRegion: (newRegion: ArloRegionCodes | null) => any;
};

export const GeolocationContext = createContext<GeolocationState>({
  region: null,
  setRegion: () => {
    noop();
  },
});

const GeolocationProvider = ({ children }: PropsWithChildren<{}>) => {
  const [userRegion, setUserRegion] = useState<ArloRegionCodes | null>(null);
  const queryRegion = useQueryRegion();
  const deviceRegion = useDeviceRegion();

  const region = useMemo(
    () => userRegion ?? queryRegion ?? deviceRegion,
    [userRegion, queryRegion, deviceRegion],
  );

  const value = useMemo(
    () => ({ region, setRegion: setUserRegion }),
    [region],
  );

  return (
    <GeolocationContext.Provider value={value}>
      {children}
    </GeolocationContext.Provider>
  );
};

export default GeolocationProvider;
