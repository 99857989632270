export type ContentfulQuoteSize = 'small' | 'medium' | 'large';
export type QuoteSize = 'quoteSm' | 'quoteMd' | 'quoteLg';

const autosize = (
  text: string,
): ContentfulQuoteSize => {
  if (text?.length < 60) {
    return 'large';
  }
  if (text?.length < 150) {
    return 'medium';
  }
  return 'small';
};

export default (
  text: string,
  size?: ContentfulQuoteSize,
): QuoteSize => {
  const textSize = size ?? autosize(text);

  switch (textSize) {
    case 'small':
      return 'quoteSm';
    case 'medium':
      return 'quoteMd';
    case 'large':
      return 'quoteLg';
    default:
      return 'quoteMd';
  }
};
