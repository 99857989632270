import {
  Box,
  styled,
  Typography,
} from '@mui/material';

export const ColorBackground = styled(Box)({
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  zIndex: -10,
});

export const TabletColorOverlayBackground = styled(Box)({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  minHeight: '240px',
  zIndex: -10,
});

export const MobileColorOverlayBackground = styled(Box)({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  minHeight: '135px',
  zIndex: -10,
});

const textShadowSize = '1px';
const textShadowColor = 'white';

export const OutlinedTypography = styled(Typography)({
  textShadow: `${textShadowSize} ${textShadowSize} ${textShadowColor}, -${textShadowSize} -${textShadowSize} ${textShadowColor}, ${textShadowSize} -${textShadowSize} ${textShadowColor}, -${textShadowSize} ${textShadowSize} ${textShadowColor}`,
});
