import React from 'react';
import { Typography } from '@mui/material';

import DateRange from '#components/DataTable/ScheduleCell/DateRange';
import TimeRange from '#components/DataTable/ScheduleCell/TimeRange';
import useDate from './_useDate';

export type ScheduleCellProps = {
  startDateTime?: string | Date,
  endDateTime?: string | Date,
  duration?: string,
};

const ScheduleCell = ({
  startDateTime,
  endDateTime,
  duration,
}: ScheduleCellProps) => {
  const isDateRange = startDateTime && endDateTime;
  const durationText = duration ? `Duration: ${duration}` : '';

  const start = useDate(startDateTime ?? '');
  const end = useDate(endDateTime ?? '');

  return (
    <Typography
      variant="subtitle1"
      className="w-full text-impgray900 py-3 md:py-6 text-left"
    >
      <div>
        <strong>
          {isDateRange ? (
            <DateRange start={start} end={end} />
          ) : (
            <>
              Privately Offered
            </>
          )}
        </strong>
      </div>
      <div>
        {isDateRange ? (
          <TimeRange start={start} end={end} />
        ) : durationText}
      </div>
    </Typography>
  );
};

export default ScheduleCell;
