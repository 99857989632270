import React from 'react';
import type { PropsWithChildren } from 'react';
import { Box, useTheme } from '@mui/material';

import type { SocialIconColors } from '#components/Layout/theme/palette.theme';
import SmartLink from '#components/SmartLink';

export type SocialLinkProps = PropsWithChildren<{
  href: string,
  ariaLabel?: string,
  onHoverColor?: SocialIconColors,
}>;

const SocialLink = ({
  href,
  ariaLabel,
  onHoverColor,
  children,
}: SocialLinkProps) => {
  const { palette } = useTheme();

  return (
    <SmartLink
      href={href}
      openInNewWindow
      ariaLabel={ariaLabel}
    >
      <Box
        sx={{
          background: '#444444',
          borderRadius: '50%',
          overflow: 'hidden',
          maxWidth: 40,
          maxHeight: 40,
          mx: { xs: 'auto', lg: 'none' },
          '&:hover': {
            background: onHoverColor
              ? palette.social[onHoverColor]
              : 'color.impgray900',
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 40,
            height: 40,
            color: 'color.impgray100',
          }}
        >
          {children}
        </Box>
      </Box>
    </SmartLink>
  );
};

export default SocialLink;
