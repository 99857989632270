import React from 'react';
import type { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

export type VerticalTableHeaderProps = PropsWithChildren<{}>;

const VerticalTableHeader = ({
  children,
}: VerticalTableHeaderProps) => (
  <Typography variant="caption">
    <b>
      {children}
    </b>
  </Typography>
);

export default VerticalTableHeader;
