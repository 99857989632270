import formatDateTime from '#utils/formatDateTime';

export default (
  rawTime: Date,
  options?: Intl.DateTimeFormatOptions,
) => formatDateTime(
  rawTime,
  {
    hour: 'numeric',
    minute: undefined,
    hour12: true,
    ...options,
  },
).replace(/\s/, '');
