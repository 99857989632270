import { filter } from 'lodash/fp';
import type { LinkDefinition } from '#contentful/Link';
import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';
import { graphql, useStaticQuery } from 'gatsby';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useMemo } from 'react';
import type { ExpandedNavigationDefinition } from '#contentful/ExpandedNavigation';

export type NavigationItem = ContentfulComponentDefinition & {
  name: string,
  item: LinkDefinition,
  subItems: NavigationItem[],
  expandedItem: ExpandedNavigationDefinition,
};

export type NavLogoDefinition = {
  id: string,
  alt: string,
  gatsbyImageData: IGatsbyImageData,
};

export type NavMenuDefinition = ContentfulComponentDefinition & {
  items: NavigationItem[]
};

export type ContentfulHeaderDefinition = {
  brandLogo: NavLogoDefinition,
  menuNav: NavMenuDefinition,
};

export default (): ContentfulHeaderDefinition => {
  const { language } = useI18next();

  const data = useStaticQuery(graphql`
    query{
      all: allContentfulComposeHeader(
        filter: {
          content: {
            brandLogo: {
              id: {ne: null}
            }
            mainNav: {
              items: {
                elemMatch: {
                  item: {
                    id: {ne: null}
                  }
                }
              }
            }
          }
        }
      ) {
        headers: nodes {
          content {
            brandLogo {
              id
              alt: title
              gatsbyImageData (layout: CONSTRAINED, height: 48, placeholder: NONE)
            }
            menuNav: mainNav {
              items {
                item {
                  id
                  contentfulId: contentful_id
                  label
                  openInNewWindow
                  href
                  page {
                    slug
                  }
                }
                subItems {
                  item {
                    id
                    label
                    openInNewWindow
                    href
                    page {
                      slug
                    }
                  }
                }
              }
            }
          }
          nodeLocale: node_locale
        }
      }
    }
  `);

  const filteredHeader = useMemo(() => filter(
    ({ nodeLocale }) => nodeLocale === language,
    data.all.headers,
  ), [data, language]);

  return filteredHeader[0].content;
};
