import React from 'react';
import {
  Card, CardContent, Grid, Typography,
} from '@mui/material';

import Button from '#contentful/Button';
import Image from '#contentful/Image';
import Text from '#contentful/Text';
import PhoneLink from '#components/PhoneLink';
import type { LocationCard } from '../InteractiveMap';

const MapCard = ({
  title,
  address,
  phone,
  image,
  text,
  button,
  background,
}: LocationCard) => {
  const imageData = background?.image?.gatsbyImageData;

  return (
    <Card
      sx={{
        backgroundColor: background?.color?.value || '#fff',
        backgroundImage: imageData?.images?.fallback?.src ? `url(${imageData?.images?.fallback?.src})` : 'none',
        backgroundSize: 'cover',
        zIndex: 1,
        border: 'none',
        boxShadow: 'none',
        padding: '0',
      }}
    >
      <CardContent>
        <Grid
          container
          marginBottom="1em"
          sx={{
            position: 'relative',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            xs={image ? 6 : 12}
            sx={{
              alignItems: 'space-between',
            }}
          >
            {title && (
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: '0',
                  fontSize: {
                    xs: '1.25rem',
                  },
                }}
              >
                {title}
              </Typography>
            </Grid>
            )}
            {address && (
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="body2"
                component="p"
                sx={{
                  color: 'color.impgraydark',
                  marginBottom: '0',
                  fontSize: '.750rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'wrap',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {address}
              </Typography>
            </Grid>
            )}
            {phone && (
            <Grid
              item
              xs={12}
            >
              <Typography
                component="p"
                sx={{
                  fontSize: '.825rem',
                  '& .ContentfulLink': {
                    color: 'color.impgreen500',
                  },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'wrap',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                <PhoneLink phoneNumber={phone} />
              </Typography>
            </Grid>
            )}
          </Grid>
          {image && (
            <Grid
              item
              xs={6}
            >
              <Image content={image} />
            </Grid>
          )}
        </Grid>
        {text && <Text content={text?.[0]} maxWidth="100%" />}
        <Button content={button} />
      </CardContent>
    </Card>
  );
};

export default MapCard;
