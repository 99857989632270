import React from 'react';
import {
  Grid,
} from '@mui/material';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import type { BorderRounding } from '#hooks/useBorderRoundingToPx/useBorderRoundingToPx';
import useBorderRoundingToPx from '#hooks/useBorderRoundingToPx/useBorderRoundingToPx';
import type { IconListDivider, IconListItemContent } from './IconListItem/IconListItem';
import IconListItem from './IconListItem/IconListItem';

export type IconListDefinition = IconListProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentIconList',
  },
};

export type IconListProps = {
  items: IconListItemContent[],
  divider?: IconListDivider,
  cornerRoundingSize: BorderRounding
};

const IconList = ({
  items,
  divider,
  cornerRoundingSize,
}: IconListProps) => {
  const { color, size } = divider ?? {};

  const borderRadious = useBorderRoundingToPx(cornerRoundingSize);

  return (
    <Grid
      container
      direction="column"
      borderRadius={borderRadious}
      overflow="hidden"
    >
      {items?.map((item, index) => (
        <IconListItem
          key={item?.id}
          {...item}
          index={index}
          length={items?.length}
          color={color}
          size={size}
        />
      ))}
    </Grid>
  );
};

export default IconList;
