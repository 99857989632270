import type { ReactNode } from 'react';
import React from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  Box, useMediaQuery, useTheme,
} from '@mui/material';

import SmartLink from '#components/SmartLink';
import RichTextInlineComponent from '#components/RichTextInlineComponent';
import useComposePage from '#hooks/useComposePage';

import type { RichTextOptions } from './RichText';
import type { ImageReference } from './_richTextReferences';
import richTextReferences from './_richTextReferences';
import renderTypography from './renderTypography';

const createSpanFromMatches = (fullText: string, restProps = {}) => {
  // Regex to match the pattern ((text))[#color]
  const regex = /\(\((.*?)\)\)\[\[(#\w+)\]\]/g;
  let lastIndex = 0;
  const elements = [];

  fullText.replace(regex, (match, text, color, index) => {
    // Add text before the match
    if (index > lastIndex) {
      elements.push(fullText.slice(lastIndex, index));
    }
    // Add the colored span
    elements.push(<span {...restProps} style={{ color }}>{text}</span>);
    lastIndex = index + match.length;
    return match;
  });

  // Add any remaining text after the last match
  if (lastIndex < fullText.length) {
    elements.push(fullText.slice(lastIndex));
  }

  return elements;
};

const addColor = (children: ReactNode = []) => {
  // Convert children to an array using React.Children.toArray
  const childArray = React.Children.toArray(children);
  return childArray.flatMap((child) => {
    if (typeof child === 'string') {
      return createSpanFromMatches(child);
    } if (React.isValidElement(child) && typeof child.props.children === 'string') {
      const newChildren = createSpanFromMatches(child.props.children, child.props);
      return React.cloneElement(child, { ...child.props }, newChildren);
    }
    return child;
  });
};

const defaults: RichTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text: any) => <b>{text}</b>,
    [MARKS.CODE]: (text: any) => (
      <code>
        {text}
      </code>
    ),
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => renderTypography('h1')(node, addColor(children)),
    [BLOCKS.HEADING_2]: (node, children) => renderTypography('h2')(node, addColor(children)),
    [BLOCKS.HEADING_3]: (node, children) => renderTypography('h3')(node, addColor(children)),
    [BLOCKS.HEADING_4]: (node, children) => renderTypography('h4')(node, addColor(children)),
    [BLOCKS.HEADING_5]: (node, children) => renderTypography('h5')(node, addColor(children)),
    [BLOCKS.HEADING_6]: (node, children) => renderTypography('h6')(node, addColor(children)),
    [BLOCKS.PARAGRAPH]: (node, children) => renderTypography('body2')(node, addColor(children)),
    [BLOCKS.EMBEDDED_ASSET]: ({ data }: any) => {
      const {
        image,
        alt,
        boxProps,
      } = (
        richTextReferences[data.target.sys.id] ?? { image: '', alt: '' }
      ) as ImageReference;

      return (
        <Box
          display="flex"
          justifyContent="center"
          my={8}
          {...boxProps}
        >
          <GatsbyImage
            loading="lazy"
            image={image}
            alt={alt}
          />
        </Box>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: ({ data }: any) => (
      <RichTextInlineComponent content={data.target} />
    ),
    [BLOCKS.OL_LIST]: (_: any, children: any) => {
      const { breakpoints } = useTheme();
      const isDesktop = useMediaQuery(breakpoints.up('md'));

      return (
        <ol style={{ columnCount: isDesktop ? 2 : 1 }}>
          {children}
        </ol>
      );
    },
    [BLOCKS.UL_LIST]: (_: any, children: any) => {
      const { breakpoints } = useTheme();
      const isDesktop = useMediaQuery(breakpoints.up('md'));

      return (
        <ul style={{ columnCount: isDesktop ? 2 : 1 }}>
          {children}
        </ul>
      );
    },
    [BLOCKS.LIST_ITEM]: (_: any, children: any) => (
      <li className="custom-marker">
        {children}
      </li>
    ),
    [BLOCKS.HR]: () => (
      <hr style={{ marginBottom: '1rem' }} />
    ),
    [INLINES.HYPERLINK]: ({ data }: any, children: any) => {
      const { uri } = data;

      return (
        <SmartLink href={uri}>
          {addColor(children)}
        </SmartLink>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: ({ data, content }: any) => {
      const { slug } = useComposePage(data?.target?.sys?.id)!;

      return (
        <SmartLink slug={slug}>
          {content[0].value}
        </SmartLink>
      );
    },
    [INLINES.EMBEDDED_ENTRY]: ({ data }: any) => (
      <RichTextInlineComponent content={data.target} />
    ),
    [INLINES.ASSET_HYPERLINK]: ({
      data: { target: { url, alt } },
      content,
    }: any) => (
      <SmartLink
        href={url}
        ariaLabel={alt}
        openInNewWindow
        sx={{
          textDecoration: 'underline',
          color: 'palette.color?.link',
        }}
      >
        {(content as any[])[0].value}
      </SmartLink>
    ),
  },
};

export default defaults;
