import { useMediaQuery, useTheme } from '@mui/material';

import useColor from '#hooks/useColor';

type HeroFontColors = {
  textColor: string,
  subheadingColor: string,
};

export default (fontColor: string = 'impblue500'): HeroFontColors => {
  const { breakpoints, palette } = useTheme();
  const isLaptop = useMediaQuery(breakpoints.up('lg'));
  const textColor = useColor('color', fontColor);

  if (isLaptop) {
    return {
      textColor,
      subheadingColor: textColor === palette.color?.impblue500
        ? palette.color?.impgray900
        : textColor,
    };
  }

  return {
    textColor: palette.color?.impblue500!,
    subheadingColor: palette.color?.impgray900!,
  };
};
