import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { first } from 'lodash/fp';

import Button from '#components/Button';
import type { LinkDefinition } from '#contentful/Link';
import type { AnyContentfulCardDefinition } from '#contentful/ContentfulCard';
import ContentfulLink from '#contentful/Link';

import useContentfulAlignment from '#hooks/useContentfulAlignment';
import useResponsiveJustifyContent from '#hooks/useResponsiveJustifyContent';
import { StyledWidthGrid } from '#components/WebinarCardHolder';
import type { Storage } from '#hooks/useScrollState';
import useScrollState from '#hooks/useScrollState';

import useRowGapToPx from '#hooks/useRowGapToPx';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import renderCardSet from './renderCardSet';
import type { CardHolderListingOptions } from './types';

const blockTypes = [
  'ContentfulComponentFullImageCard',
  'ContentfulComponentTrainingCourseCard',
  'ContentfulComponentTrainingCourse',
  'CosmosUserGroup',
  'ContentfulComponentIconButton',
  'ContentfulComponentContentCard',
];

const translationKey = 'Components.CardHolder';

export type CardHolderProps = {
  heading?: string,
  headingVariant?: 'h2' | 'h3',
  text?: string,
  linkToMoreContent?: LinkDefinition,
  cards: AnyContentfulCardDefinition[],
  listMoreButton?: boolean,
  pageSlug?: string
  useStoredData?: boolean
} & Omit<CardHolderListingOptions, 'autofill'>;

const CardHolder = ({
  heading,
  headingVariant = 'h2',
  text,
  linkToMoreContent,
  cards,
  cardsPerRow = 3,
  rowsPerGroup = 1,
  rowGap = 'Small',
  listMoreButton = false,
  horizontalAlignment,
  pageSlug,
  useStoredData,
}: CardHolderProps) => {
  const [paginationNumber, setPaginationNumber] = useState(1);
  const itemsPerPagination = (cardsPerRow * rowsPerGroup);
  const [storeData, setStoreData] = useState<Storage | null>(null);
  const { getStoredData, updatePaginationNumber } = useScrollState();
  const { t } = useTranslation();

  useEffect(() => {
    if (!useStoredData || !pageSlug) {
      return;
    }

    const storedData = getStoredData(pageSlug);
    if (storedData) {
      setStoreData(storedData as any);
    }
    if (storedData && storedData.paginationNumber) {
      setPaginationNumber(storedData.paginationNumber);
    }
  }, [pageSlug, useStoredData, getStoredData]);

  useEffect(() => {
    if (useStoredData && pageSlug && paginationNumber !== 1) {
      updatePaginationNumber(pageSlug, paginationNumber);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationNumber]);

  useEffect(() => {
    if (storeData && storeData.scrollPosition) {
      window.scrollTo(0, storeData.scrollPosition);
    }
  }, [storeData]);

  const sectionJustifyContent = useContentfulAlignment(horizontalAlignment ?? 'center');
  const responsiveJustifyContent = useResponsiveJustifyContent({
    xs: 'flex-start',
    md: horizontalAlignment?.toLowerCase() === 'full width' ? 'space-around' : sectionJustifyContent,
  });

  const showListMore: boolean = useMemo(
    () => listMoreButton && (itemsPerPagination * paginationNumber < cards.length),
    [listMoreButton, cards, itemsPerPagination, paginationNumber],
  );

  const listCards = useMemo(
    () => renderCardSet(itemsPerPagination, paginationNumber, cardsPerRow)(cards),
    [itemsPerPagination, paginationNumber, cardsPerRow, cards],
  );

  const handleListMore = useCallback(
    () => setPaginationNumber((oldValue) => oldValue + 1),
    [],
  );

  const isBlock = useMemo(
    () => {
      if (cards && cards.length) {
        return !!blockTypes.find(
          (blockType) => first(cards)!.internal.type === blockType,
        );
      }

      return undefined;
    },
    [cards],
  );

  const rowGapInPx = useRowGapToPx(rowGap);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      textAlign="center"
      rowGap={2}
    >
      <StyledWidthGrid
        item
        container
        direction="column"
        alignItems="center"
        textAlign="center"
      >
        {heading && (
          <Grid
            item
            px={1}
            mb={(text || linkToMoreContent) ? 0 : 4}
          >
            <Typography variant={headingVariant ?? 'h2'}>
              {heading}
            </Typography>
          </Grid>
        )}

        {text && (
          <Grid item px={1}>
            <Typography
              variant="subheading2"
              sx={{
                maxWidth: '46rem',
                display: 'block',
              }}
            >
              {text}
            </Typography>
          </Grid>
        )}

      </StyledWidthGrid>

      {linkToMoreContent && (
        <Grid
          item
          alignSelf={{ xs: isBlock ? 'center' : 'end', md: 'end' }}
          pr={{ xs: 2, md: 3.5 }}
          width={{ xs: '100%', sm: 400 }}
        >
          <Box display="flex" justifyContent="flex-end">
            <ContentfulLink
              content={linkToMoreContent}
            >
              {linkToMoreContent.label}
            </ContentfulLink>
          </Box>
        </Grid>
      )}

      <Grid item width="100%">
        <Grid
          container
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={responsiveJustifyContent}
          alignItems={{ xs: isBlock ? 'center' : 'stretch', md: 'flex-start' }}
          rowGap={{ xs: 2, md: rowGapInPx }}
          textAlign="left"
          mb={4}
          px={{ xs: isBlock ? 2 : 0, md: 0 }}
        >
          {listCards}
        </Grid>
      </Grid>

      {showListMore && (
        <StyledWidthGrid
          item
          container
          direction="column"
          alignItems="center"
          textAlign="center"
          mt={6}
        >
          <Grid item onClick={handleListMore} mb={{ xs: 8, md: 0 }}>
            <Button variant="secondary">
              {t(`${translationKey}.ShowMoreText`)}
            </Button>
          </Grid>
        </StyledWidthGrid>
      )}
    </Grid>
  );
};

export default CardHolder;
