import { graphql, useStaticQuery } from 'gatsby';
import { filter } from 'lodash/fp';
import { useMemo } from 'react';

import type {
  AnyContentfulCardDefinition,
} from '#contentful/ContentfulCard';

/**
 * Query top case studies
 */
export default (language: string): AnyContentfulCardDefinition[] => {
  const data = useStaticQuery(graphql`
      query {
        all: allContentfulPageTemplateCaseStudyPost(
          sort: {datePublished: DESC}
          filter: {
            compose__page: {
              elemMatch: {
                slug: {ne: null},
              }
            }
          }
        ) {
          cards: nodes {
            ...CaseStudyCardInfo
          }
        }
      }
    `);

  const filteredCards = useMemo(
    () => filter({ nodeLocale: language }, data.all.cards),
    [data, language],
  );

  return filteredCards;
};
