import { first } from 'lodash/fp';

import type {
  ArloCourseData,
  CurrencyCode,
} from '#services/arlo';

export type FlatCourseData = {
  id: number,
  name: string,
  duration: string,
  basePrice: string,
  currencyCode: CurrencyCode,
  registrationUri: string,
};

export default ({
  TemplateID,
  Name,
  AdvertisedDuration,
  BestAdvertisedOffers,
  RegisterInterestUri,
}: ArloCourseData): FlatCourseData => ({
  id: TemplateID,
  name: Name,
  duration: AdvertisedDuration,
  basePrice: first(BestAdvertisedOffers)?.OfferAmount.FormattedAmountTaxExclusive ?? '',
  currencyCode: first(BestAdvertisedOffers)?.OfferAmount.CurrencyCode as CurrencyCode,
  registrationUri: RegisterInterestUri,
});
