import React from 'react';
import type { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

import SmartLink from '#components/SmartLink';

export type FooterLinkProps = PropsWithChildren<{
  uri: string,
}>;

export const BlockLink = ({
  uri,
  children,
}: FooterLinkProps) => (
  <SmartLink
    href={uri}
    sx={{
      display: 'block',
      textDecoration: 'none',
      color: '#EDEEEE',
    }}
  >
    <Typography variant="caption">
      {children}
    </Typography>
  </SmartLink>
);

export const LegalLink = ({
  uri,
  children,
}: FooterLinkProps) => (
  <SmartLink
    slug={uri}
    sx={{
      textDecoration: 'none',
      color: 'inherit',
    }}
  >
    <Typography variant="caption">
      {children}
    </Typography>
  </SmartLink>
);
