import React from 'react';
import type { TableContentDefinition } from '#contentful/TableContent';
import TableContent from '#contentful/TableContent';
import FullWidth from './Sections/FullWidth';
import Sec131313 from './Sections/Sec131313';
import Sec14141414 from './Sections/Sec14141414';
import type { FullWidthSection } from './Sections/FullWidth';
import type { TwoColumnSectionDefinition } from './Sections/TwoColumnSectionDefinition';
import type { ThreeColumnSectionDefinition } from './Sections/ThreeColumnSectionDefinition';
import type { FourColumnSectionDefinition } from './Sections/FourColumnSectionDefinition';
import GenericTwoColumnSection from './Sections/GenericTwoColumnSection';

export type AnySectionDefinition = (
  FullWidthSection
  | TwoColumnSectionDefinition<string>
  | ThreeColumnSectionDefinition<string>
  | FourColumnSectionDefinition<string>
  | TableContentDefinition
);

export type SectionProps = {
  section: AnySectionDefinition,
};

const Section = ({
  section,
}: SectionProps) => {
  switch (section.internal?.type) {
    case 'ContentfulSectionFullWidth':
      return <FullWidth key={section.id} content={section as FullWidthSection} />;
    case 'ContentfulSection1212':
      return <GenericTwoColumnSection<'1212'> key={section.id} content={section as TwoColumnSectionDefinition<'1212'>} leftSpace={6} rightSpace={6} />;
    case 'ContentfulSection1323':
      return <GenericTwoColumnSection<'1323'> key={section.id} content={section as TwoColumnSectionDefinition<'1323'>} leftSpace={4} rightSpace={8} />;
    case 'ContentfulSection2313':
      return <GenericTwoColumnSection<'2313'> key={section.id} content={section as TwoColumnSectionDefinition<'2313'>} leftSpace={8} rightSpace={4} />;
    case 'ContentfulSection1434':
      return <GenericTwoColumnSection<'1434'> key={section.id} content={section as TwoColumnSectionDefinition<'1434'>} leftSpace={3} rightSpace={9} />;
    case 'ContentfulSection131313':
      return <Sec131313 key={section.id} content={section as ThreeColumnSectionDefinition<'131313'>} />;
    case 'ContentfulSection14141414':
      return <Sec14141414 key={section.id} content={section as FourColumnSectionDefinition<'14141414'>} />;
    case 'ContentfulComponentTableContent':
      return <TableContent key={section.id} content={section as TableContentDefinition} />;
    default:
      throw new Error(`Unexpected section type: ${section.internal?.type}`);
  }
};

export default Section;
