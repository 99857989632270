import { find } from 'lodash/fp';

import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

export type QueryAllComposePages = {
  allCompose: {
    pages: ComposePage[]
  }
};

export type ComposePage = {
  contentfulId: string,
  slug: string,
} | undefined;

export default (id: string): ComposePage => {
  const data = useStaticQuery<QueryAllComposePages>(graphql`
  {
    allCompose: allContentfulComposePage {
      pages: nodes {
        contentfulId: contentful_id
        slug
      }
    }
  }
`);

  return useMemo(() => find(
    { contentfulId: id },
    data.allCompose.pages,
  ), [id, data]);
};
