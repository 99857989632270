import { useContentfulImage } from 'gatsby-source-contentful/hooks';

import type { GatsbyImageProps } from '#contentful/Image';

import type { HeroDimentions } from './_useDimentions';

export default (
  { width, height }: HeroDimentions,
  image?: GatsbyImageProps,
) => {
  const dynamicImage = useContentfulImage({
    image: {
      url: image?.url.replace('https:', ''),
      width,
      height,
    },
  });
  return dynamicImage;
};
