import React from 'react';
import { Box, styled, Typography } from '@mui/material';

import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import type { LinkDefinition } from '#contentful/Link';
import { SquarishButton } from '#components/Button/styles';
import SmartLink from '#components/SmartLink';

export type SquaredButtonProps = {
  title?: string,
  link?: LinkDefinition,
  className?: string,
};

export type SquaredButtonDefinition =
SquaredButtonProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentButton'
  }
};

const SquaredButton = ({
  title,
  link,
  className,
}: SquaredButtonProps) => (
  <Box
    display="flex"
    justifyContent="center"
    className={className}
  >
    <SmartLink
      href={link?.href}
      slug={link?.page?.slug}
      anchorTag={link?.anchorTag?.id}
      sx={{ textDecoration: 'none' }}
    >
      <SquarishButton
        sx={{
          backgroundColor: 'color.impblue500',
        }}
        variant="contained"
        color="primary"
      >
        <Typography variant="subheading2">
          {title}
        </Typography>
      </SquarishButton>
    </SmartLink>
  </Box>
);

export default styled(SquaredButton)({});
