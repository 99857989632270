import {
  flow,
  toLower,
  includes,
  curry,
} from 'lodash/fp';

export default curry((a: string, b: string) => flow(
  toLower,
  includes(toLower(b)),
)(a));
