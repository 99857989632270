import type { ArloRegionCodes } from '#services/arlo';

export type QueryRegion = ArloRegionCodes;

export default (): QueryRegion => {
  const queryParams = new URLSearchParams(
    typeof window === 'undefined'
      ? undefined
      : window.location.search,
  );
  return queryParams.get('region') as ArloRegionCodes;
};
