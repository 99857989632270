import React from 'react';
import { Grid } from '@mui/material';
import { map } from 'lodash/fp';

import type { BackgroundDefinition } from '#components/Section/SectionSettings';
import SectionSettings from '#components/Section/SectionSettings';
import type { FlairPosition, FlairGraphic } from '#components/Flair';
import type {
  HorizontalContentfulAlignment,
  VerticalContentfulAlignment,
} from '#hooks/useContentfulAlignment';
import SectionColumn from '#components/SectionColumn';
import type { ContentfulSpacing } from '#components/Layout/theme';
import type { ContentfulTextAlign } from '#hooks/useResponsiveTextAlign';
import useContentfulAlignment from '#hooks/useContentfulAlignment';
import useResponsiveJustifyContent from '#hooks/useResponsiveJustifyContent';
import useResponsiveAlignItems from '#hooks/useResponsiveAlignItems';
import useResponsiveSpacing from '#hooks/useResponsiveSpacing';
import useResponsiveTextAlign from '#hooks/useResponsiveTextAlign';
import type { ContentfulSectionSpacingDefinition } from '#hooks/useSectionSpacing';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import type { AnyContentfulComponentDefinition } from '#contentful/ContentfulComponent';
import ContentfulComponent from '#contentful/ContentfulComponent';

import type { SectionChildren, SectionDefinition } from '..';

export type FullWidthSection = SectionDefinition & {
  background: BackgroundDefinition,
  components: SectionChildren,
  marginTop?: ContentfulSpacing,
  marginBottom?: ContentfulSpacing,
  marginLeft?: ContentfulSpacing,
  marginRight?: ContentfulSpacing,
  paddingTop?: ContentfulSpacing,
  paddingBottom?: ContentfulSpacing,
  paddingLeft?: ContentfulSpacing,
  paddingRight?: ContentfulSpacing,
  flairPosition?: FlairPosition,
  flairGraphic?: FlairGraphic,
  textAlignment?: ContentfulTextAlign,
  sectionMaximumWidth?: string,
  sectionMinimumHeight?: number,
  componentsHorizontalAlignment?: HorizontalContentfulAlignment,
  componentsVerticalAlignment?: VerticalContentfulAlignment,
  borderRadiusTop?: boolean,
  borderRadiusBottom?: boolean,
  spacingSettings: ContentfulSectionSpacingDefinition,
  border?: ContentfulBorderDefinition,
  internal: {
    type: 'ContentfulSectionFullWidth',
  }
};

export type FullWidthProps = {
  content: FullWidthSection,
};

/**
 * - Create a wrapper container that expand 100% of the available width.
 * - Add minor styling to this container like padding, margin, etc.  Some of these
 *   styling are based on user selected options in Contentful.
 * - Render the Contentful component
 *
 * Contentful
 * - Name: "Section: Full Width"
 * - Content Modal: https://app.contentful.com/spaces/0vvalmm98slw/content_types/sectionFullWidth/fields
 *
 * @param content An object with the fields and components set on Contentful.
 * @returns JSX.Element
 */
const FullWidth = ({
  content: {
    id,
    customAnchorTag,
    background,
    components,
    flairGraphic,
    flairPosition,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    textAlignment,
    sectionMaximumWidth,
    // sectionMinimumHeight,
    componentsHorizontalAlignment,
    componentsVerticalAlignment,
    borderRadiusTop,
    borderRadiusBottom,
    spacingSettings,
    border,
  },
}: FullWidthProps) => {
  const mt = useResponsiveSpacing({
    xs: 0,
    md: spacingSettings?.marginTop ?? marginTop ?? 2,
  });
  const mb = useResponsiveSpacing({
    xs: 0,
    md: spacingSettings?.marginBottom ?? marginBottom ?? 2,
  });
  const ml = useResponsiveSpacing({
    xs: 0,
    lg: spacingSettings?.marginLeft ?? marginLeft ?? 0,
  });
  const mr = useResponsiveSpacing({
    xs: 0,
    lg: spacingSettings?.marginRight ?? marginRight ?? 0,
  });

  const pt = useResponsiveSpacing({
    xs: 4,
    md: spacingSettings?.paddingTop ?? paddingTop ?? 2,
  });
  const pb = useResponsiveSpacing({
    xs: 4,
    md: spacingSettings?.paddingBottom ?? paddingBottom ?? 2,
  });
  const pl = useResponsiveSpacing({
    xs: 0,
    md: spacingSettings?.paddingLeft ?? paddingLeft ?? 2,
  });
  const pr = useResponsiveSpacing({
    xs: 0,
    md: spacingSettings?.paddingRight ?? paddingRight ?? 2,
  });

  const sectionJustifyContent = useContentfulAlignment(
    spacingSettings?.sectionColumnsVerticalAlignment
    ?? componentsVerticalAlignment
    ?? 'top',
  );
  const sectionAlignItems = useContentfulAlignment(
    spacingSettings?.sectionColumnsHorizontalAlignment
      ?? componentsHorizontalAlignment
      ?? 'full width',
  );
  const responsiveJustifyContent = useResponsiveJustifyContent({
    xs: 'flex-start',
    md: sectionJustifyContent,
  });
  const responsiveAlignItems = useResponsiveAlignItems({
    xs: 'stretch',
    md: sectionAlignItems,
  });

  const maxWidth = spacingSettings?.sectionMaximumWidth ?? sectionMaximumWidth;
  const responsiveWidth = useResponsiveSpacing({
    xs: '100%',
    lg: maxWidth ? `${maxWidth}%` : '100%',
  });

  const textAlign = spacingSettings?.textAlignment ?? textAlignment;
  const correctedTextAlignment = textAlign === 'Default' ? 'inherit' : textAlign;
  const responsiveTextAlign = useResponsiveTextAlign({
    xs: 'left',
    sm: (correctedTextAlignment ?? 'center'),
  });

  return (
    <SectionSettings
      anchorTagId={customAnchorTag ?? id}
      {...background}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
      textAlign={responsiveTextAlign}
      maxWidth={responsiveWidth as string}
      flairGraphic={flairGraphic}
      flairPosition={flairPosition}
      borderRadiusTop={borderRadiusTop}
      borderRadiusBottom={borderRadiusBottom}
      border={border}
    >
      <SectionColumn
        justifyContent={responsiveJustifyContent}
        alignItems={responsiveAlignItems}
      >
        {map((component: AnyContentfulComponentDefinition) => (
          <Grid
            item
            key={component.id}
            width={
            component?.internal?.type === 'ContentfulComponentButton'
              ? '100%'
              : 'auto'
            }
          >
            <ContentfulComponent content={component} />
          </Grid>
        ), components)}
      </SectionColumn>
    </SectionSettings>
  );
};

export default FullWidth;
