import { useMemo } from 'react';
import {
  filter,
  find,
} from 'lodash/fp';
import includesIgnoreCase from '#utils/includesIgnoreCase';

export default <T>(
  items: T[],
  searchableColumns: string[],
  searchTerm: string,
): T[] => useMemo(
  () => filter(
    (item) => find(
      (col: string) => includesIgnoreCase(
        (item as unknown as { [k: string]: string })[col],
        searchTerm,
      ),
      searchableColumns,
    ),
    items,
  ) as T[],
  [items, searchTerm, searchableColumns],
);
