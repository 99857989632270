import { useTheme } from '@mui/material';

export type ContentfulBorderDefinition = {
  width: number,
  color?: {
    value: string,
  },
  removeTopBorder?: boolean,
  removeBottomBorder?: boolean,
  roundTopBorder?: boolean,
  roundBottomBorder?: boolean,
};

export type BorderDefinition = {
  border?: number,
  borderColor?: string,
  borderTop?: number,
  borderBottom?: number,
  borderTopLeftRadius?: string,
  borderTopRightRadius?: string,
  borderBottomLeftRadius?: string,
  borderBottomRightRadius?: string,
};

export default (
  border?: ContentfulBorderDefinition,
): BorderDefinition | undefined => {
  const {
    palette,
    border: borderRadius,
  } = useTheme();

  if (!border) {
    return undefined;
  }

  const {
    width,
    color,
    removeTopBorder,
    removeBottomBorder,
    roundTopBorder,
    roundBottomBorder,
  } = border;

  return {
    border: width,
    ...(removeTopBorder && { borderTop: 0 }),
    ...(removeBottomBorder && { borderBottom: 0 }),
    ...(roundTopBorder && {
      borderTopLeftRadius: borderRadius.section,
      borderTopRightRadius: borderRadius.section,
    }),
    ...(roundBottomBorder && {
      borderBottomLeftRadius: borderRadius.section,
      borderBottomRightRadius: borderRadius.section,
    }),
    borderColor: color?.value ?? palette.color?.grayLight,
  };
};
