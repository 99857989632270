import { GetArlowRegionCode } from '#services/arlo';
import type { ArloRegionCodes } from '#services/arlo';

import client from './client';
import type { GeolocationResult } from './types';
import { config } from '../../_netlify/edge/geolocation';

export default async (): Promise<ArloRegionCodes> => client
  .get<GeolocationResult>(config.path)
  .then((x) => GetArlowRegionCode(x.data.countryCode));
