import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import 'prismjs/themes/prism-okaidia.css';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import { getLanguageObject, usePrismHighlight } from '#hooks/usePrismHighlight';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export type CodeBlockDefinition = ContentfulComponentDefinition
& CodeBlockProps & {
  internal: {
    type: 'ContentfulComponentCodeBlock',
  }
};

export type CodeBlockProps = {
  language?: string,
  code: {
    text: string,
  },
};

const translationKey = 'Components.CodeBlock';

const CodeBlock = ({
  language,
  code,
}: CodeBlockProps) => {
  const { t } = useTranslation();
  usePrismHighlight(language, code.text);
  const languageObject = getLanguageObject(language);
  const [showButtons, setShowButtons] = useState(false);
  const [copyText, setCopyText] = useState(t(`${translationKey}.Copy`) as string);

  const languageClass = `language-${languageObject?.value ?? 'markup'}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(code.text)
      .then(() => {
        setCopyText(t(`${translationKey}.Copied`) as string);
        setTimeout(() => {
          setCopyText(t(`${translationKey}.Copy`) as string);
        }, 2000);
      });
  };

  const handleDownload = () => {
    const fileExtension = languageObject?.fileExtension || 'txt';
    const element = document.createElement('a');
    const file = new Blob([code.text], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `code.${fileExtension}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Box sx={{ my: 3 }}>

      <pre
        style={{
          padding: '0.5rem 1.5rem',
          margin: 0,
          whiteSpace: 'pre-wrap',
          overflow: 'auto',
          wordBreak: 'break-word',
          position: 'relative',
        }}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
      >
        {showButtons && (
        <Box sx={{
          position: 'absolute', right: '10px', display: 'flex', justifyContent: 'flex-end', mb: 1,
        }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={handleCopy}
            sx={{ mr: 1 }}
          >
            {copyText}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleDownload}
          >
            {t(`${translationKey}.Download`)}
          </Button>
        </Box>
        )}
        <code
          className={languageClass}
          style={{
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
            wordBreak: 'break-word',
          }}
        >
          {code.text}
        </code>
      </pre>
    </Box>
  );
};

export default CodeBlock;
