import { graphql, useStaticQuery } from 'gatsby';

export type ContactPageData = {
  page: {
    slug: string
  }
};

export default (): ContactPageData => useStaticQuery(graphql`
  {
    page: contentfulComposePage(
      contentful_id: {eq: "4jzhkAzWnLN1cW9c4KUP5E"}
    ) {
      slug
    }
  }
`);
