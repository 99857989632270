import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import type { CurrencyCode } from '#services/arlo';

export type PriceCellProps = {
  basePrice: string,
  discountPrice?: string,
  currencyCode?: CurrencyCode,
};

const translationKey = 'Components.PriceCell';

const PriceCell = ({
  basePrice,
  discountPrice,
  currencyCode = 'USD',
}: PriceCellProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col items-start text-impgray900">
      {discountPrice && (
        <Typography variant="body3" className="line-through mb-0">
          {`${basePrice} ${currencyCode}`}
        </Typography>
      )}

      <Typography variant="subtitle1">
        <strong>
          {`${discountPrice ?? basePrice} ${currencyCode}`}
        </strong>
      </Typography>
      {discountPrice && (
      <Typography variant="body3">
        <em>
          {t(`${translationKey}.EarlyBird`)}
        </em>
      </Typography>
      )}
    </div>
  );
};

export default PriceCell;
