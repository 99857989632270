import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { filter } from 'lodash/fp';
import { useMemo } from 'react';

export default () => {
  const { language } = useI18next();
  const data = useStaticQuery(graphql`
    {
      allLocations: allContentfulComponentLocation {
        locations: nodes {
          name
          nodeLocale: node_locale
          pageTemplate: page_template__location_page {
            composePage: compose__page {
              slug
            }
          }
        }
      }
    }
  `);

  return useMemo(
    () => filter(
      ({ nodeLocale: language }),
      data.allLocations.locations,
    ),
    [
      data.allLocations.locations,
      language,
    ],
  );
};
