import React from 'react';

export type PhoneLinkProps = {
  phoneNumber: string,
};

const PhoneLink = ({
  phoneNumber,
}: PhoneLinkProps) => {
  const phoneLink = phoneNumber
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
    .replaceAll(' ', '-');

  return (
    <a
      href={`tel:${phoneLink}`}
      className="ContentfulLink"
    >
      {phoneNumber}
    </a>
  );
};

export default PhoneLink;
