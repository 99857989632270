import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { filter } from 'lodash/fp';
import { useMemo } from 'react';

import type {
  TrainingPageCardDefinition,
} from '#contentful/ContentfulCard/TrainingPageCard';

export default (): TrainingPageCardDefinition[] => {
  const { language } = useI18next();

  const data = useStaticQuery(graphql`
    query {
      all: allContentfulComponentTrainingCourse(
        filter: {
          arloCourseTemplateId: {ne: null}
          page_template__training_page: {
            elemMatch: { compose__page: {
              elemMatch: { slug: {ne: null}}
            }}
          }
        }
      ) {
        cards: nodes {
          ...TrainingCourseCard
        }
      }
    }
  `);

  const filteredCards = useMemo(() => filter(
    ({ nodeLocale }) => nodeLocale === language,
    data.all.cards,
  ), [data, language]);

  return filteredCards;
};
