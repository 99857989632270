import type { Dictionary } from 'lodash';

import type { VideoDefinition } from '#contentful/Video';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import type { BoxProps } from '@mui/material';

export type RichTextReference = any | VideoDefinition & {
  contentful_id: string,
  internal: {
    type: string,
  }
};

export type ImageReference = {
  image: IGatsbyImageData,
  alt: string,
  boxProps: BoxProps,
};

export type CodeBlockReference = {
  code: {
    text: string,
  },
  language?: string,
};

const richTextReferences: Dictionary<ImageReference | CodeBlockReference> = {};

export default richTextReferences;
