import React from 'react';
import { Box, styled } from '@mui/material';
import cn from 'classnames';

import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';
import type { RichTextContent } from '#components/RichText';
import RichText from '#components/RichText';
import theme from '#components/Layout/theme';
import useColor from '#hooks/useColor';
import type { ResponsiveTextAlign } from '#hooks/useResponsiveTextAlign';
import useResponsiveTextAlign from '#hooks/useResponsiveTextAlign';
import columnCountOptions from '#utils/columnCountOptions';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import useBorder from '#hooks/useBorder';
import GridItemText from '#components/GridItemText';
import { useFontColors } from '#components/Hero';

export type TextDefinition = ContentfulComponentDefinition & {
  content: RichTextContent,
  overline?: string,
  fontColorComponentText?: string,
  fontSizeComText?: string,
  textAlignment?: ResponsiveTextAlign,
  listColumnCount?: number,
  border?: ContentfulBorderDefinition,
  marginTopOnFirstHeader?: boolean,
  firstLineBorderBottom?: {
    value: string,
  }
  internal: {
    type: 'ContentfulComponentText'
  },
};

export type TextProps = {
  content: TextDefinition,
  className?: string,
  maxWidth?: string,
  minWidth?: string,
};

/**
 * Contentful
 * - Name: "Component: Text"
 * - Content Model: https://app.contentful.com/spaces/0vvalmm98slw/content_types/componentText/fields
 */
const Text = ({
  content, className, maxWidth = 'auto', minWidth = '280px',
}: TextProps) => {
  const {
    content: componentContent = {},
    fontColorComponentText,
    fontSizeComText,
    textAlignment,
    listColumnCount,
    border,
    marginTopOnFirstHeader = true,
    firstLineBorderBottom,
    overline,
  } = content;

  const { subheadingColor } = useFontColors(fontColorComponentText);

  // Temporary Hack until we understand better how to work with all the Contentful scenarios.
  const fontColorSpanHack = fontColorComponentText
    ? `font-color_${fontColorComponentText}`
    : '';
  const fontColorBox = useColor(
    'color',
    fontColorComponentText,
    theme.palette.text.secondary,
  );
  const fontSize = fontSizeComText
    ? `rich-text__font-${fontSizeComText.toLowerCase()}`
    : '';
  const responsiveTextAlign = useResponsiveTextAlign({
    xs: 'left' as ResponsiveTextAlign,
    sm: textAlignment ?? 'left' as ResponsiveTextAlign,
  });

  const options = columnCountOptions(listColumnCount);
  const borderStyles = useBorder(border);
  const removeMarginTopOnFirstHeader = marginTopOnFirstHeader === false;

  return (
    <Box
      className={`${cn(fontColorSpanHack, fontSize)} ${className}`}
      sx={{
        color: fontColorBox,
        textAlign: responsiveTextAlign,
        '& span:first-of-type': {
          borderBottom: `${firstLineBorderBottom ? `2px solid ${firstLineBorderBottom.value}` : 'none'}`,
        },
        ...borderStyles,
        ...(removeMarginTopOnFirstHeader ? {
          '& h1:first-of-type, & h2:first-of-type, & h3:first-of-type, & h4:first-of-type, & h5:first-of-type, & h6:first-of-type': {
            marginTop: '0 !important',
          },
        } : {}),
      }}
      maxWidth={maxWidth}
      minWidth={{ md: minWidth }}
      mx="auto"
    >
      {(overline) && (
        <GridItemText
          variant="overline1"
          text={overline}
          typographyProps={{
            textAlign: 'left',
            color: subheadingColor,
            sx: { zIndex: 20, textTransform: 'uppercase' },
          }}
        />
      )}
      <RichText content={componentContent} options={options} />
    </Box>
  );
};
export default styled(Text)({});
