import {
  identity,
  isArray,
  mapValues,
  flow,
  join,
  map,
  pickBy,
} from 'lodash/fp';
import { formatISO } from 'date-fns/fp';
import type {
  ArloEventsFilter,
  ArloPagedRequest,
  ArloRegionCodes,
} from './models/arlo';
import arloClient from './arlo';
import type { ArloEventsResult } from './models/events';

export default async (
  eventsFilter?: ArloEventsFilter,
  pagedRequest: ArloPagedRequest = { skip: 0, top: 100 },
  region: ArloRegionCodes = 'us',
): Promise<ArloEventsResult> => {
  const params = new URLSearchParams([
    ['includeTotalCount', 'true'],
    ['top', `${pagedRequest.top}`],
    ['skip', `${pagedRequest.skip}`],
    ['region', region],
    ['fields', 'RegistrationInfo,EventID,Name,StartDateTime,Summary,EndDateTime,TimeZone,TimeZoneID,Location,Sessions,IsFull,Provider,Description,EventTemplateID,AdvertisedOffers,Presenters'],
  ]);

  if (eventsFilter) {
    const filter = flow(
      pickBy(identity),
      mapValues((x) => (isArray(x)
        ? JSON.stringify(x)
        : formatISO(x as Date))),
      (map as any).convert({
        cap: false,
      })(
        (v: string, k:string) => `${k}=${v}`,
      ),
      join(','),
    )(eventsFilter);

    params.append('filter', filter);
  }

  return arloClient.get('/eventsearch', { params })
    .then((x) => x.data);
};
