import type { SectionSpacingOptions } from './types';

const defaults: SectionSpacingOptions = {
  mtxs: 0,
  mbxs: 0,
  mlxs: 0,
  mrxs: 0,
  mtmd: 2,
  mbmd: 2,
  mlmd: 0,
  mrmd: 0,
  ptxs: 4,
  pbxs: 4,
  plxs: 0,
  prxs: 0,
  ptmd: 2,
  pbmd: 2,
  plmd: 2,
  prmd: 2,
  horizontalAlignment: 'center',
  verticalAlignment: 'top',
  justifyContentxs: 'flex-start',
  alignItemsxs: 'stretch',
  columnSpacingxs: 0,
  columnSpacingsm: 2,
  columnSpacingmd: 4,
  widthxs: '100%',
  widthlg: '100%',
  textAlignmentxs: 'left',
  textAlignmentsm: 'center',
};

export default defaults;
