import React from 'react';

import type { FormFieldTextDefinition } from './FormFieldText';
import FormFieldText from './FormFieldText';
import type { MultiSelectDefinition } from './FormFieldMultiSelect/types';
import FormFieldMultiSelect from './FormFieldMultiSelect';

export type AnyFormFieldDefinition = FormFieldTextDefinition
| MultiSelectDefinition;

export type FormFieldProps = {
  field: AnyFormFieldDefinition,
  reset: number,
};

const FormField = ({
  field,
  reset,
}: FormFieldProps) => {
  switch (field?.internal?.type) {
    case 'ContentfulSubComponentFormFieldText':
      return (
        <FormFieldText
          reset={reset}
          {...field as FormFieldTextDefinition}
        />
      );
    case 'ContentfulSubComponentFormFieldMultiSelect':
      return (
        <FormFieldMultiSelect
          reset={reset}
          {...field as MultiSelectDefinition}
        />
      );
    default:
      throw new Error(`Form field type ${field.internal} not recognized.`);
  }
};

export default FormField;
