import React from 'react';

import formatDateTime from '#utils/formatDateTime';
import { Typography } from '@mui/material';
import paletteTheme from '#components/Layout/theme/palette.theme';
import { isString } from 'lodash/fp';

export type ClassScheduleProps = {
  startDateTime?: string | Date,
  endDateTime?: string | Date,
  duration?: string,
};

const ClassSchedule = ({
  startDateTime,
  endDateTime,
  duration,
}: ClassScheduleProps) => {
  if (startDateTime) {
    const start = isString(startDateTime) ? new Date(startDateTime) : startDateTime;
    const end = isString(endDateTime) ? new Date(endDateTime) : endDateTime;

    const noEndTime = !end;

    const date = (): string => {
      if (noEndTime || start.getDate() === end.getDate()) {
        return formatDateTime(start);
      }

      return `
      ${formatDateTime(start)}
      - 
      ${formatDateTime(end)}
      `;
    };

    const time = (): string => {
      const formatTime = (
        rawTime: Date,
        options?: Intl.DateTimeFormatOptions,
      ) => formatDateTime(
        rawTime,
        {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
          ...options,
        },
      );

      return `${
        formatTime(start)
      }${noEndTime
        ? ''
        : ` - ${formatTime(
          end,
          { timeZoneName: 'short' },
        )}`
      }`;
    };

    return (
      <Typography
        variant="subtitle1"
        sx={{ color: paletteTheme().color.impgray900 }}
        textAlign="left"
      >
        <b>{date()}</b>
        <br />
        {`${time()}`}
      </Typography>
    );
  }

  return (
    <Typography
      variant="subtitle1"
      sx={{ color: paletteTheme().color.impgray900 }}
      textAlign="left"
    >
      <b>Privately Offered</b>
      <br />
      {duration ? `Duration: ${duration}` : ''}
    </Typography>
  );
};

export default ClassSchedule;
