import React from 'react';
import { curry } from 'lodash/fp';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Typography } from '@mui/material';

import type { RichTextOptions } from '#components/RichText';
import useComposePage from '#hooks/useComposePage';

import { BlockLink, LegalLink } from './_FooterLink';

const renderTypography = curry((
  variant: Parameters<(typeof Typography)>[0]['variant'] | '',
  _: any,
  children: any,
) => (
  <Typography
    display="block"
    variant={variant || undefined}
    color="#EDEEEE"
    marginBottom={1.5}
  >
    {children}
  </Typography>
));

const defaults: RichTextOptions = {
  renderNode: {
    [BLOCKS.HEADING_5]: renderTypography('h5'),
    [BLOCKS.HEADING_6]: renderTypography('h6'),
    [BLOCKS.PARAGRAPH]: renderTypography('caption'),
    [INLINES.HYPERLINK]: ({ data }: any, children: any) => {
      const { uri } = data;

      return (
        <BlockLink uri={uri}>
          {children}
        </BlockLink>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: ({ data, content }: any) => {
      const { slug } = useComposePage(data?.target?.sys?.id)!;

      return (
        <LegalLink uri={slug}>
          {content[0].value}
        </LegalLink>
      );
    },
  },
};

export default defaults;
