import { filter, some, flow } from 'lodash/fp';
import { graphql, useStaticQuery } from 'gatsby';
import type {
  AnyContentfulCardDefinition,
} from '#contentful/ContentfulCard';
import { useMemo } from 'react';

/**
 * Query top blogs except those with the 'Newsroom' category
 */
export default (language: string, categoryId?: string): AnyContentfulCardDefinition[] => {
  const data = useStaticQuery(graphql`
    query allBlogPostsByLatest {
      all: allContentfulPageTemplateBlogPost(
        sort: {datePublished: DESC}
        filter: {
          compose__page: { elemMatch: { slug: { ne: null } } },
          category: { elemMatch: { displayName: { ne: "Newsroom" } } }
        }
      ) {
        cards: nodes {
          ...BlogCardInfo
        }
      }
    }
  `);

  const filteredCards = useMemo(() => flow(
    filter(({ nodeLocale }: AnyContentfulCardDefinition) => nodeLocale === language),
    filter(({ categories }: AnyContentfulCardDefinition) => (
      !categoryId || some({ contentfulId: categoryId }, categories)
    )),
  )(data.all.cards), [categoryId, data, language]);

  return filteredCards;
};
