import type { JobTableDefinition } from '../models/JobTableDefinition';

export default ({
  guid,
  title,
  category,
  jobdescription,
  pubDate,
  jobtype,
  location,
  country,
  emailAddress,
  applicationUrl,
  link,
}: any): JobTableDefinition => ({
  id: guid._text,
  guid: guid._text,
  title: title._text,
  category: category._text,
  description: jobdescription._text,
  pubDate: pubDate._text,
  jobType: jobtype._text,
  location: location._text,
  country: country?._text,
  emailAddress: emailAddress._text,
  applicationUrl: applicationUrl._text,
  link: link._text,
});
