import {
  curry,
  find,
  first,
  map,
} from 'lodash/fp';

import type {
  CurrencyCode,
  ArloEventData,
  ArloPresenter,
} from '#services/arlo';
import flattenOffers from '#utils/flattenAdvertisedOffers';
import type { LocationPage } from '#hooks/useLocationPages';
import type { TrainingProfilePage } from '#hooks/useTrainingProfilePages';

export type InstructorDefinition = {
  id: number,
  name: string,
  slug?: string,
};

export type TrainingCourseTableData = {
  id: number,
  eventTemplateId: number,
  date: string,
  start: string,
  end: string,
  course: string,
  location: string,
  locationSlug?: string,
  instructors: InstructorDefinition[],
  instructorNames: string,
  basePrice: string,
  discountPrice?: string,
  currencyCode: CurrencyCode,
  registrationUri: string,
};

export default curry((
  locationPages: LocationPage[],
  trainingProfilePages: TrainingProfilePage[],
  arloEvent: ArloEventData,
) => {
  const {
    EventID,
    EventTemplateID,
    StartDateTime,
    EndDateTime,
    Name,
    Location,
    Presenters,
    RegistrationInfo,
    AdvertisedOffers,
  } = arloEvent;
  const flatOffer = flattenOffers(AdvertisedOffers);

  return {
    id: EventID,
    eventTemplateId: EventTemplateID,
    date: `${StartDateTime} - ${EndDateTime}`,
    start: StartDateTime,
    end: EndDateTime,
    course: Name,
    location: Location.Name,
    locationSlug: find(
      ({ arloVenueId: Location.VenueID }),
      locationPages,
    )?.slug,
    instructors: map(
      ({
        PresenterID,
        Name: instructorName,
      }: ArloPresenter) => ({
        id: PresenterID,
        name: instructorName,
        slug: find(
          ({ arloPresenterId: PresenterID }),
          trainingProfilePages,
        )?.slug,
      }) as any,
      Presenters,
    ) as InstructorDefinition[],
    instructorNames: map('Name', Presenters).join(','),
    basePrice: flatOffer.basePrice ?? '',
    discountPrice: flatOffer.discountPrice,
    currencyCode: first(AdvertisedOffers)!.OfferAmount.CurrencyCode as CurrencyCode,
    registrationUri: RegistrationInfo.RegisterUri,
  };
});
