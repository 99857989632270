import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  useTheme,
} from '@mui/material';

import SmartLink from '#components/SmartLink';
import type { LinkDefinition } from '#contentful/Link';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import useBorder from '#hooks/useBorder';
import type { TextVariantDefinition } from '#contentful/TextVariant';
import TextVariant from '#contentful/TextVariant';
import type { GatsbyImageProps } from '#contentful/Image';
import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import useColor from '#hooks/useColor';

export type FullImageCardDefinition = ContentfulComponentDefinition & {
  link: LinkDefinition,
  image: GatsbyImageProps,
  border?: ContentfulBorderDefinition,
  imageTitle?: TextVariantDefinition,
  internal: {
    type: 'ContentfulComponentFullImageCard'
  }
};

export type FullImageCardProps = {
  card: FullImageCardDefinition,
};

const FullImageCard = ({
  card: {
    link: {
      href,
      page,
      anchorTag,
      openInNewWindow,
    },
    image: {
      url,
      alt,
    },
    border,
    imageTitle,
  },
}: FullImageCardProps) => {
  const {
    border: borderRadius,
    shadow,
    palette,
  } = useTheme();
  const borderStyles = useBorder(border);

  const impColor = useColor(
    'color',
    imageTitle?.color,
    palette.color?.impblue500,
  );

  return (
    <SmartLink
      href={href}
      slug={page?.slug}
      anchorTag={anchorTag?.id}
      openInNewWindow={openInNewWindow}
      sx={{ color: impColor }}
    >
      <Card
        sx={{
          borderRadius: borderRadius.card,
          boxShadow: shadow.card,
          ...borderStyles,
        }}
      >
        {imageTitle && (
          <CardContent sx={{ textAlign: 'center' }}>
            <TextVariant {...imageTitle} />
          </CardContent>
        )}
        <CardActionArea>
          <CardMedia
            component="img"
            image={url}
            alt={alt}
            height={234}
          />
        </CardActionArea>
      </Card>
    </SmartLink>
  );
};

export default FullImageCard;
