import React from 'react';

import type { LinkDefinition } from '#contentful/Link';
import type {
  InitialRowCount,
  TrainingCourseCategory,
} from '#components/DataTable';
import DataTable from '#components/DataTable';
import useQueryAllCourseCards from '#hooks/useQueryAllCourseCards';
import useTrainingCategoryFilter from '#hooks/useTrainingCategoryFilter';
import type {
  TrainingPageCardDefinition,
} from '#contentful/ContentfulCard/TrainingPageCard';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import columns from './_columns';

export type CourseTableProps = {
  heading?: string,
  text?: string,
  linkToMoreContent?: LinkDefinition,
  initialRowCount?: InitialRowCount,
  filterValue?: TrainingCourseCategory,
};

const CourseTable = ({
  heading,
  text,
  linkToMoreContent,
  initialRowCount,
  filterValue,
}: CourseTableProps) => {
  const courses = useTrainingCategoryFilter(filterValue!)(
    useQueryAllCourseCards(),
  ) as TrainingPageCardDefinition[];
  const { t } = useTranslation();

  return (
    <DataTable
      columns={columns(t)}
      items={courses}
      noItemsCopy={t('Components.CourseTable.NoCourses')}
      heading={heading}
      text={text}
      href={linkToMoreContent?.href}
      slug={linkToMoreContent?.page?.slug}
      anchorTag={linkToMoreContent?.anchorTag?.id}
      searchable
      initialRowCount={initialRowCount}
    />
  );
};

export default CourseTable;
