import {
  flow,
  find,
  map,
  curry,
} from 'lodash/fp';

import type { TrainingCourseCategory } from '#components/DataTable';

export type ContentfulTrainingPage = {
  arloCourseId: number,
  title: string,
  categories: TrainingCourseCategory[],
  pageTemplate: {
    composePage: {
      slug: string,
    }[],
  }[],
};

export type FlatTrainingPage = {
  arloCourseId: number,
  slug: string,
};

const getTrainingPage = curry((
  id: number,
) => flow(
  map(({ arloCourseId, pageTemplate }: ContentfulTrainingPage) => ({
    arloCourseId,
    slug: pageTemplate?.[0]?.composePage?.[0]?.slug,
  })),
  find(({ arloCourseId }: FlatTrainingPage) => (
    arloCourseId === id
  )),
));

export default getTrainingPage;
