export type BorderRounding = 'None' | 'Small' | 'Medium' | 'Large';

const BASE_UNIT = 8;

export default (borderRounding: BorderRounding = 'None') => {
  switch (borderRounding) {
    case 'None':
      return '0px';
    case 'Small':
      return `${BASE_UNIT}px`;
    case 'Medium':
      return `${2 * BASE_UNIT}px`;
    case 'Large':
      return `${3 * BASE_UNIT}px`;
    default:
      return '0px';
  }
};
