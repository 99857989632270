import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import type { IconButtonDefinition } from '#contentful/IconButton';
import IconButton from '#contentful/IconButton';
import type { ContentfulSectionSpacingDefinition } from '#hooks/useSectionSpacing';
import type { SuggestedContentTabsContentDefinition } from '../SuggestedContentTabsGroup';

export type SuggestedContentTabsTabProps = {
  tab: SuggestedContentTabsTabDefinition,
  activeTab: string,
  setActiveTab: (id: string) => void
};

export type SuggestedContentTabsTabDefinition = {
  id: string,
  icon: IconButtonDefinition,
  activeIcon: IconButtonDefinition,
  hoverIcon: IconButtonDefinition,
  content: SuggestedContentTabsContentDefinition,
  spacingOptions: ContentfulSectionSpacingDefinition,
};

const SuggestedContentTabsTab = ({
  tab,
  activeTab,
  setActiveTab,
}: SuggestedContentTabsTabProps) => {
  const [currentIcon, setCurrentIcon] = React.useState(tab?.icon);

  const handleMouseEnter = () => {
    if (activeTab !== tab?.id && tab?.hoverIcon) {
      setCurrentIcon(tab?.hoverIcon);
    }
  };

  const handleMouseLeave = () => {
    if (activeTab !== tab?.id) {
      setCurrentIcon(tab?.icon);
    }
  };

  useEffect(
    () => {
      if (activeTab === tab?.id && tab?.activeIcon) {
        setCurrentIcon(tab?.activeIcon);
      }
      if (activeTab !== tab?.id) {
        setCurrentIcon(tab?.icon);
      }
    },
    [activeTab, tab],
  );

  return (
    <Grid
      item
      key={tab?.id}
      onClick={() => setActiveTab(tab?.id)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        cursor: 'pointer',
      }}
    >
      <IconButton
        {...currentIcon}
        preloadImage
        link={undefined}
      />
    </Grid>
  );
};

export default SuggestedContentTabsTab;
