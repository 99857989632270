import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { filter } from 'lodash/fp';

import type { ContentfulTrainingPage } from '#utils/getTrainingPage';

export default (
  language: string,
): ContentfulTrainingPage[] => {
  const data = useStaticQuery(graphql`
    {
      allTraining: allContentfulComponentTrainingCourse(
        filter: {
          arloCourseTemplateId: {ne: null}
        }
      ) {
        courses: nodes {
          arloCourseId: arloCourseTemplateId
          title
          categories: categoriesV2 {
            ...TrainingCourseCategory
          }
          pageTemplate: page_template__training_page {
            composePage: compose__page {
              slug
            }
          }
          nodeLocale: node_locale
        }
      }
    }
  `);

  return useMemo(
    () => filter(
      ({ nodeLocale: language }),
      data.allTraining.courses,
    ),
    [
      data.allTraining.courses,
      language,
    ],
  );
};
