import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Button from '#components/Button';
import ClassSchedule from '#components/ClassSchedule/ClassSchedule';
import type { FlatClassData } from '#utils/flattenArloEventIntoClassData';
import LocationCell from '#components/DataTable/LocationCell';
import TableHeaderCell from '#components/DataTable/TableHeaderCell';
import type { InstructorDefinition } from '#components/Listing/FullClassScheduleTable';
import LinkCell from '#components/DataTable/LinkCell';
import PriceCell from '#components/DataTable/PriceCell';
import { isEmpty } from 'lodash/fp';
import type { ButtonDefinition } from '#contentful/Button';
import paletteTheme from '#components/Layout/theme/palette.theme';
import ContentfulButton from '#contentful/Button';

const translationKey = 'Components.NextClassDesktop';

const columnHeaders: string[] = [
  'Date',
  'Location',
  'Instructors',
  'StartingAt',
  'Button',
];

export type NextClassDesktopProps = {
  class: FlatClassData,
  defaults?: {
    defaultDate: string,
    defaultLocation: string,
    defaultInstructor: string,
    defaultStartingAt: string,
    defaultButton: ButtonDefinition,
  },
};

const NextClassDesktop = ({
  class: classData,
  defaults,
}: NextClassDesktopProps) => {
  const { t } = useTranslation();

  const {
    start, end, location, instructors, basePrice, discountPrice, currencyCode, registrationUri,
  } = classData ?? {};
  const {
    defaultDate, defaultLocation, defaultInstructor, defaultStartingAt, defaultButton,
  } = defaults ?? {};

  const getDefaultButton = () => {
    if (defaultButton) {
      return <ContentfulButton content={defaultButton} />;
    }

    return t(`${translationKey}.RegistrationClosed`);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        background: 'rgba(0,0,0,0)',
        '& .MuiTableCell-body': {
          py: 1.6,
          px: 1,
        },
      }}
      elevation={0}
    >
      <Table aria-label="training courses">
        <TableHead>
          <TableRow>
            {columnHeaders.map((header: string) => (
              <TableCell key={header} sx={{ p: 0, pl: 1 }}>
                <TableHeaderCell title={t(`${translationKey}.Headers.${header}`)} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {start && end ? (
                <ClassSchedule
                  startDateTime={start}
                  endDateTime={end}
                />
              ) : (
                <Typography
                  variant="h6"
                  sx={{ color: paletteTheme().color.impgray900, fontWeight: 'bold' }}
                  textAlign="left"
                >
                  {defaultDate}
                </Typography>
              )}
            </TableCell>
            <TableCell component="th" scope="row">
              {!isEmpty(location) ? (
                <LinkCell slug={location.slug}>
                  <LocationCell {...location} />
                </LinkCell>
              ) : (
                <Typography
                  variant="subtitle1"
                  color="color.impgray900"
                >
                  {defaultLocation}
                </Typography>
              )}
            </TableCell>
            <TableCell component="th" scope="row">
              {isEmpty(instructors) ? (
                <Typography
                  variant="subtitle1"
                  color="color.impgray900"
                >
                  {defaultInstructor ?? 'TBA'}
                </Typography>
              ) : instructors.map(({
                id,
                name,
                slug,
              }: InstructorDefinition) => (
                <LinkCell key={id} slug={slug}>
                  {name}
                </LinkCell>
              ))}
            </TableCell>
            <TableCell component="th" scope="row">
              {basePrice ? (
                <PriceCell
                  basePrice={basePrice}
                  discountPrice={discountPrice}
                  currencyCode={currencyCode}
                />
              ) : (
                <Typography
                  variant="subtitle1"
                  color="color.impgray900"
                >
                  {defaultStartingAt}
                </Typography>
              )}
            </TableCell>
            <TableCell component="th" scope="row">
              {Date.parse(start) > Date.now()
                ? (
                  <Button
                    variant="bio"
                    isInTable
                    href={registrationUri}
                  >
                    {t(`${translationKey}.RegisterButton`)}
                  </Button>
                )
                : (
                  getDefaultButton()
                )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NextClassDesktop;
