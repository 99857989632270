/**
 * Returns a string formatted to the Intl.DateTimeFormat.
 *
 * @param {Date}                        date - Date to be formatted
 * @param {string | string[]}           locales - Date formatting language
 * (default: 'default')
 * @param {Intl.DateTimeFormatOptions}  options - "Style" or formatting options
 * (default: { month: 'short', day: 'numeric' })
 * @returns {string}                    Formatted string.
 */
export default (
  date: Date,
  options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' },
  locales: string | string[] = 'default',
) => new Intl.DateTimeFormat(locales, options).format(date);
