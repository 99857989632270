import React from 'react';
import { Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GlassDoorIcon from '#components/GlassDoorIcon';
import TikTokIcon from '#components/TikTokIcon';
import XIcon from '#components/XIcon';

import type {
  ContentfulComponentDefinition,
} from '../ContentfulComponentDefinition';
import SocialLink from './_SocialLink';

export type SocialSharingDefinition = SocialSharingProps &
ContentfulComponentDefinition & {
  internal: {
    type: 'ContentfulComponentSocialSharing'
  }
};

export type SocialSharingProps = {
  email: string,
  linkedIn: string,
  twitter: string,
  facebook: string,
  instagram: string,
  glassdoor: string,
  youtube: string,
  tikTok: string,
};

const SocialSharing = ({
  email,
  linkedIn,
  twitter,
  facebook,
  instagram,
  glassdoor,
  youtube,
  tikTok,
}: SocialSharingProps) => (
  <Grid
    container
    justifyContent={{ xs: 'flex-start' }}
    columnSpacing={1}
    rowSpacing={1}
  >
    {email && (
      <Grid item lg={3} xs={3} md={3}>
        <SocialLink
          href={email}
          ariaLabel="link which opens default email service"
          onHoverColor="email"
        >
          <EmailIcon />
        </SocialLink>
      </Grid>
    )}

    {linkedIn && (
      <Grid item lg={3} xs={3} md={3}>
        <SocialLink
          href={linkedIn}
          ariaLabel="link to improving LinkedIn page"
          onHoverColor="linkedIn"
        >
          <LinkedInIcon />
        </SocialLink>
      </Grid>
    )}

    {twitter && (
      <Grid item lg={3} xs={3} md={3}>
        <SocialLink
          href={twitter}
          ariaLabel="link to improving Twitter page"
          onHoverColor="twitter"
        >
          <XIcon />
        </SocialLink>
      </Grid>
    )}

    {facebook && (
      <Grid item lg={3} xs={3} md={3}>
        <SocialLink
          href={facebook}
          ariaLabel="link to improving Facebook page"
          onHoverColor="facebook"
        >
          <FacebookIcon />
        </SocialLink>
      </Grid>
    )}

    {instagram && (
      <Grid item lg={3} xs={3} md={3}>
        <SocialLink
          href={instagram}
          ariaLabel="link to improving Instagram page"
          onHoverColor="instagram"
        >
          <InstagramIcon />
        </SocialLink>
      </Grid>
    )}

    {glassdoor && (
      <Grid item lg={3} xs={3} md={3}>
        <SocialLink
          href={glassdoor}
          ariaLabel="link to improving Glassdoor page"
          onHoverColor="glassdoor"
        >
          <GlassDoorIcon />
        </SocialLink>
      </Grid>
    )}

    {youtube && (
      <Grid item lg={3} xs={3} md={3}>
        <SocialLink
          href={youtube}
          ariaLabel="link to improving YouTube page"
          onHoverColor="youTube"
        >
          <YouTubeIcon />
        </SocialLink>
      </Grid>
    )}

    {tikTok && (
      <Grid item lg={3} xs={3} md={3}>
        <SocialLink
          href={tikTok}
          ariaLabel="link to improving TikTok page"
          onHoverColor="tiktok"
        >
          <TikTokIcon />
        </SocialLink>
      </Grid>
    )}
  </Grid>
);

export default SocialSharing;
