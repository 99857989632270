import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import {
  filter,
  find,
  flow,
  get,
  map,
} from 'lodash/fp';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

import type { LinkDefinition } from '#contentful/Link';
import { getEvents } from '#services/arlo';
import DataTable from '#components/DataTable';
import type {
  InitialRowCount,
  TrainingCourseCategory,
} from '#components/DataTable';
import useCourseIdInfo from '#hooks/useCourseIdInfo';
import useLocationPages from '#hooks/useLocationPages';
import { GeolocationContext } from '#providers/GeolocationProvider';

import useTrainingProfilePages from '#hooks/useTrainingProfilePages';
import type { TrainingCourseTableData } from './_convertArloEventToTableData';
import convertArloEventToTableData from './_convertArloEventToTableData';
import columns from './_columns';

export type FullClassScheduleTableProps = {
  heading?: string,
  text?: string,
  linkToMoreContent?: LinkDefinition,
  venueId?: number,
  onlineOnly?: boolean,
  initialRowCount?: InitialRowCount,
  filterValue?: TrainingCourseCategory,
};

const FullClassScheduleTable = ({
  heading,
  text,
  linkToMoreContent,
  venueId,
  onlineOnly = false,
  initialRowCount,
  filterValue,
}: FullClassScheduleTableProps) => {
  const { language } = useI18next();
  const locationPages = useLocationPages(language);
  const trainingProfilePages = useTrainingProfilePages(language);
  const { t } = useTranslation();

  const courseIdInfo = useCourseIdInfo(filterValue);

  const courseIds = useMemo(
    () => map(
      ({ arloCourseId }) => arloCourseId,
      courseIdInfo,
    ),
    [courseIdInfo],
  );

  //  get filter state based on query params
  //  must check to ensure the window exists
  const queryParams = useMemo(
    () => new URLSearchParams(
      typeof window === 'undefined'
        ? undefined
        : window.location.search,
    ),
    [],
  );

  const { region } = useContext(GeolocationContext);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<TrainingCourseTableData[]>([]);
  const [includeOnline, setIncludeOnline] = useState(
    queryParams.get('online') !== 'false',
  );

  useEffect(() => {
    if (!courseIdInfo.length) {
      return;
    }
    getEvents({}, { skip: 0, top: 100 }, region ?? undefined)
      .then(
        flow(
          get('Items'),
          filter(({ Location }) => {
            if (venueId) {
              return Location.VenueID === venueId
                || (includeOnline && Location.Name === 'Online');
            }

            if (onlineOnly) {
              return includeOnline && Location.Name === 'Online';
            }

            return includeOnline || Location.Name !== 'Online';
          }),
          map(convertArloEventToTableData(
            locationPages,
            trainingProfilePages,
          )),
          filter(({ eventTemplateId }: TrainingCourseTableData) => (
            !filterValue || courseIds?.includes(eventTemplateId)
          )),
          map((e: TrainingCourseTableData) => ({
            ...e,
            course: find(
              { arloCourseId: e.eventTemplateId },
              courseIdInfo,
            )?.title,
          })),
          setEvents,
          () => setLoading(false),
        ),
      );
  }, [
    region,
    venueId,
    onlineOnly,
    includeOnline,
    filterValue,
    courseIdInfo,
    courseIds,
    locationPages,
    trainingProfilePages,
  ]);

  return (
    <DataTable
      columns={columns(t)}
      items={events}
      noItemsCopy="There are no scheduled classes currently available. Contact us to schedule private training."
      heading={heading}
      text={text}
      href={linkToMoreContent?.href}
      slug={linkToMoreContent?.page?.slug}
      anchorTag={linkToMoreContent?.anchorTag?.id}
      searchable
      initialRowCount={initialRowCount}
      hasRegionSelector
      toggleIncludeOnline
      includeOnline={includeOnline}
      setIncludeOnline={setIncludeOnline}
      viewAllLinkLabel={linkToMoreContent?.label}
      loadingItems={loading}
    />
  );
};

export default FullClassScheduleTable;
