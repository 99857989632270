import { useMemo } from 'react';
import { useTheme } from '@mui/material';

import useViewport, { viewport } from '#hooks/useViewport';

export type HeroDimentions = {
  width: number,
  height: number,
};

export default (isSectionTallest: boolean): HeroDimentions => {
  const { breakpoints } = useTheme();
  const activeViewport = useViewport();

  return useMemo(() => {
    switch (activeViewport) {
      case viewport.mobile:
        return {
          width: breakpoints.values.sm,
          height: 400,
        };
      case viewport.smallTablet:
        return {
          width: breakpoints.values.md,
          height: 336,
        };
      case viewport.largeTablet:
        return {
          width: breakpoints.values.lg,
          height: 360,
        };
      case viewport.desktop:
      case viewport.laptop:
      default:
        return {
          width: breakpoints.values.xl,
          height: isSectionTallest ? 640 : 400,
        };
    }
  }, [activeViewport, breakpoints, isSectionTallest]);
};
