import React from 'react';
import type { GridProps } from '@mui/material';
import { Grid } from '@mui/material';
import { map } from 'lodash/fp';

import SectionColumn from '#components/SectionColumn';
import SectionSettings from '#components/Section/SectionSettings';
import useReverseDirection from '#hooks/useReverseDirection';
import useSectionSpacing from '#hooks/useSectionSpacing';
import useResponsiveColumnSettings from '#hooks/useResponsiveColumnSettings';
import type { AnyContentfulComponentDefinition } from '#contentful/ContentfulComponent';
import ContentfulComponent from '#contentful/ContentfulComponent';

import type { TwoColumnSectionDefinition } from './TwoColumnSectionDefinition';

export type GenericTwoColumnSectionProps<ContentfulType extends string> = {
  content: TwoColumnSectionDefinition<ContentfulType>,
  leftSpace: number,
  rightSpace: number
};

const GenericTwoColumnSection = <ContentfulType extends string>({
  content: {
    id,
    customAnchorTag,
    background,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    sectionColumnsHorizontalAlignment,
    sectionColumnsVerticalAlignment,
    columnGap,
    flairGraphic,
    flairPosition,
    borderRadiusTop,
    borderRadiusBottom,
    border,
    leftColumnBackground,
    columnLeftPaddingTop,
    columnLeftPaddingBottom,
    columnLeftPaddingRight,
    columnLeftPaddingLeft,
    columnLeftItemsHorizontalAlignment,
    columnLeftItemsVerticalAlignment,
    leftSide,
    leftColumnSettings,
    rightColumnBackground,
    columnRightPaddingTop,
    columnRightPaddingBottom,
    columnRightPaddingLeft,
    columnRightPaddingRight,
    columnRightItemsHorizontalAlignment,
    columnRightItemsVerticalAlignment,
    rightSide,
    rightColumnSettings,
    spacingSettings,
    invertColumns = false,
  },
  leftSpace,
  rightSpace,
}: GenericTwoColumnSectionProps<ContentfulType>) => {
  //  Calculate section settings
  const {
    mt,
    mb,
    ml,
    mr,
    pt,
    pb,
    pl,
    pr,
    responsiveJustifyContent,
    responsiveAlignItems,
    responsiveColumnSpacing,
  } = useSectionSpacing(
    {
      ...spacingSettings,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      sectionColumnsHorizontalAlignment,
      sectionColumnsVerticalAlignment,
      columnSpacing: columnGap,
    },
    {
      columnSpacingsm: 0,
    },
  );

  //  Calculate left column settings
  const responsiveLeftColumnSettings = useResponsiveColumnSettings({
    ...leftColumnSettings,
    paddingTop: columnLeftPaddingTop,
    paddingBottom: columnLeftPaddingBottom,
    paddingLeft: columnLeftPaddingLeft,
    paddingRight: columnLeftPaddingRight,
    itemsHorizontalAlignment: columnLeftItemsHorizontalAlignment,
    itemsVerticalAlignment: columnLeftItemsVerticalAlignment,
  });

  //  Calculate right column settings
  const responsiveRightColumnSettings = useResponsiveColumnSettings({
    ...rightColumnSettings,
    paddingTop: columnRightPaddingTop,
    paddingBottom: columnRightPaddingBottom,
    paddingLeft: columnRightPaddingLeft,
    paddingRight: columnRightPaddingRight,
    itemsHorizontalAlignment: columnRightItemsHorizontalAlignment,
    itemsVerticalAlignment: columnRightItemsVerticalAlignment,
  });

  //  resolve whether or not the column should be reversed based on column content
  const mobileDirectionSuffix = useReverseDirection(leftSide, rightSide);
  const direction = {
    xs: `column${mobileDirectionSuffix}` as string,
    lg: 'row',
  } as GridProps['direction'];

  return (
    <SectionSettings
      anchorTagId={customAnchorTag ?? id}
      {...background}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
      flairGraphic={flairGraphic}
      flairPosition={flairPosition}
      borderRadiusTop={borderRadiusTop}
      borderRadiusBottom={borderRadiusBottom}
      border={border}
    >
      <Grid
        container
        flexWrap="nowrap"
        rowSpacing={{ xs: 0, sm: 2, md: 0 }}
        direction={direction}
        justifyContent={responsiveJustifyContent}
        alignItems={responsiveAlignItems}
        columnSpacing={responsiveColumnSpacing}
      >
        <Grid
          xs={12}
          lg={invertColumns ? rightSpace : leftSpace}
          item
        >
          <SectionColumn
            {...responsiveLeftColumnSettings}
            color={leftColumnBackground?.color}
            image={leftColumnBackground?.image}
          >
            {map((component: AnyContentfulComponentDefinition) => (
              <Grid item key={component.id} height="100%">
                <ContentfulComponent content={component} />
              </Grid>
            ), leftSide)}
          </SectionColumn>
        </Grid>
        <Grid
          item
          xs={12}
          lg={invertColumns ? leftSpace : rightSpace}
        >
          <SectionColumn
            {...responsiveRightColumnSettings}
            color={rightColumnBackground?.color}
            image={rightColumnBackground?.image}
          >
            {map((component: AnyContentfulComponentDefinition) => (
              <Grid item key={component.id} height="100%">
                <ContentfulComponent content={component} />
              </Grid>
            ), rightSide)}
          </SectionColumn>
        </Grid>
      </Grid>
    </SectionSettings>
  );
};

export default GenericTwoColumnSection;
