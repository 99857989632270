import React from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import ProfileCard from '#components/ProfileCard';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';

export type PersonComponentDefinition = ContentfulComponentDefinition & {
  personName: string,
  title: string,
  office: any,
  image: {
    gatsbyImageData: IGatsbyImageData,
    alt: string,
    url: string,
  },
  profilePage: {
    composePage: {
      slug: string,
    }[]
  }[],
  internal: {
    type: 'ContentfulComponentPerson'
  }
};

export type PersonProps = {
  content: PersonComponentDefinition,
};

const translationKey = 'Components.Person';

const Person = ({
  content,
}: PersonProps) => {
  const { t } = useTranslation();

  const {
    personName, title, image, profilePage,
  } = content || {};

  return (
    <ProfileCard
      key={content.id}
      title={personName}
      subtitle={title}
      photo={image}
      buttonLabel={t(`${translationKey}.ReadBio`) ?? 'Read Bio'}
      slug={profilePage?.[0]?.composePage?.[0]?.slug}
      buttonVariant="bio"
      showPhoto
      showButton
      showTitle
      showSubtitle
    />
  );
};

export default Person;
