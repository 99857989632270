import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { useContentfulImage } from 'gatsby-source-contentful/hooks';

import type { GatsbyImageProps } from '#contentful/Image';

export type ResizeImage = IGatsbyImageData;

/**
 * Utilizes the 'useContentfulImage' hook but maintains the correct
 * aspect ration based on the original image. Also, it removes 'https:'
 * from the url - the hooks adds it in the returning image.
 *
 * @param {GatsbyImageProps}    image - the asset data from contentful
 * @param {number}              width - sets the new resized width
 *
 * @returns {IGatsbyImageData}  image data used by <GatsbyImage />
 */
export default (image: GatsbyImageProps, width: number): ResizeImage => {
  const height = image.file.details.image.height * (width / image.file.details.image.width);

  return useContentfulImage({
    image: {
      url: image?.url.replace('https:', ''),
      width,
      height,
    },
  });
};
