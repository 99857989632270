import React, { useRef } from 'react';
import {
  List,
  Paper,
  Popper,
  useTheme,
} from '@mui/material';
import { useToggle } from '@react-shanties/core';

import NavMenuLink, { type NavMenuLinkDefinition } from '#components/NavMenuLink';
import type { ExpandedNavigationDefinition } from '#contentful/ExpandedNavigation';
import ExpandedNavigation from '#contentful/ExpandedNavigation';
import clsx from 'clsx';

export type NavMenuCategoryProps = NavMenuLinkDefinition & {
  contentfulId: string,
  subItems: NavMenuLinkDefinition[],
  expandedItem?: ExpandedNavigationDefinition,
};

const NavMenuCategory = ({
  label,
  slug,
  subItems,
  contentfulId,
  expandedItem,
}: NavMenuCategoryProps) => {
  const anchor = useRef(null);

  const [menuOpen, , { setOn, setOff }] = useToggle();
  const { palette } = useTheme();

  const isContactCategory = contentfulId === '1cRvq5jA5pCenL84ClJpsh';

  return (
    <div
      ref={anchor}
      className={clsx(
        'm-0',
        isContactCategory && 'mt-[10px]',
        isContactCategory ? 'h-[70%]' : 'h-full',
      )}
      onMouseEnter={setOn}
      onMouseLeave={setOff}
    >
      <NavMenuLink
        label={label}
        color={isContactCategory ? palette?.color?.impblue500 : ''}
        buttonClasses={clsx(
          isContactCategory && 'border border-solid border-impblue300 rounded-[2rem] bg-lightblue-lightgreen-soft',
        )}
        slug={slug}
        menuOpen={menuOpen}
        // expandedItem={expandedItem}
      />

      {expandedItem && (
      <Popper
        anchorEl={anchor.current}
        open={menuOpen}
        placement="bottom-start"
        keepMounted
        onResize={undefined}
        onResizeCapture={undefined}
        className="z-[1000000] w-full box-border bg-transparent"
      >
        <ExpandedNavigation content={expandedItem} />
      </Popper>
      )}

      {!expandedItem && subItems && (
        <Popper
          anchorEl={anchor.current}
          open={menuOpen}
          placement="bottom-start"
          keepMounted
          onResize={undefined}
          onResizeCapture={undefined}
          className="z-[1000000]"
        >
          <Paper>
            <List disablePadding>
              {subItems.map(
                (subItem) => (
                  <NavMenuLink
                    key={subItem.id}
                    buttonClasses="w-[150px]"
                    label={subItem.label}
                    href={subItem.href}
                    slug={subItem.slug}
                  />
                ),
              )}
            </List>
          </Paper>
        </Popper>
      )}
    </div>
  );
};

export default NavMenuCategory;
