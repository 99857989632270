import React from 'react';

import formatDateTime from '#utils/formatDateTime';
import useViewport, { viewport } from '#hooks/useViewport';
import clsx from 'clsx';

export type DateRangeProps = {
  start: Date,
  end: Date,
};

const DateRange = ({
  start,
  end,
}: DateRangeProps) => {
  const activeViewport = useViewport();
  const largeScreen = [
    viewport.desktop,
  ].includes(activeViewport);

  const sameDay = start.getDate() === end.getDate();
  const sameMonth = start.getMonth() === end.getMonth();

  const includeDash = !sameDay && (sameMonth || largeScreen);
  const spaceDates = !sameMonth;

  const startDate = formatDateTime(start);
  const endDate = (): string => {
    if (sameDay) {
      return '';
    }

    return `${formatDateTime(
      end,
      {
        day: 'numeric',
        month: sameMonth
          ? undefined
          : 'short',
      },
    )}`;
  };

  return (
    <div
      data-testid="date-range"
      className={clsx(
        'flex flex-wrap',
        includeDash ? 'flex-row' : 'flex-col',
        spaceDates ? 'gap-1' : 'gap-[0.1rem]',
      )}
    >
      <div>
        {startDate}
      </div>
      {includeDash ? '-' : ''}
      <div>
        {endDate()}
      </div>
    </div>
  );
};

export default DateRange;
