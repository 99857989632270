import { createTheme } from '@mui/material';
import createTypography from './typography.theme';
import createPalette from './palette.theme';
import createComponents from './components.theme';
import createSpacing from './spacing.theme';

declare module '@mui/material/styles' {
  interface Theme {
    border: Record<string, string>,
    shadow: Record<string, string>,
  }
  interface ThemeOptions {
    border?: Record<string, string>
    shadow?: Record<string, string>,
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

const muiTheme = createTheme();

const baseTheme = createTheme({
  border: {
    card: '15px',
    section: '12px',
    subtle: '4px',
  },
  breakpoints: {
    values: {
      ...muiTheme.breakpoints.values,
      sm: 451,
      md: 767,
      lg: 992,
      xl: 1280,
    },
  },
  shadow: {
    card: '0 3px 24px 0 rgba(0, 0, 0, 0.1)',
  },
  typography: {
    ...muiTheme.typography,

  },
});

// TODO: Fix type warning
const theme = createTheme(baseTheme, {
  typography: createTypography(baseTheme),
  palette: createPalette(),
  spacing: createSpacing(baseTheme),
  components: createComponents(),
});

export default theme;

export type {
  ContentfulSpacing,
} from './spacing.theme';
