import { useEffect, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import {
  filter,
  flow,
  includes,
  map,
} from 'lodash/fp';

import type { TrainingCourseCategory } from '#components/DataTable';
import useQueryAllTrainingPages from '#hooks/useQueryAllTrainingPages';
import type { TrainingPageCardDefinition } from '#contentful/ContentfulCard/TrainingPageCard';
import type { ContentfulTrainingPage } from '#utils/getTrainingPage';

export type CourseIdInfo = {
  arloCourseId: number,
  title: string,
};

export type TrainingCategoryParent = TrainingPageCardDefinition
| ContentfulTrainingPage;

export default (
  trainingCategory?: TrainingCourseCategory,
): CourseIdInfo[] => {
  const { language } = useI18next();

  const [courseIdInfo, setCourseIdInfo] = useState<CourseIdInfo[]>([]);
  const courses = useQueryAllTrainingPages(language);

  useEffect(
    () => {
      flow(
        filter(
          ({ categories }: TrainingCategoryParent) => (
            !trainingCategory || flow(
              map('contentfulId'),
              includes(trainingCategory.contentfulId),
            )(categories)
          ),
        ),
        map(({
          arloCourseId,
          title,
        }: ContentfulTrainingPage) => ({
          arloCourseId,
          title,
        })),
        setCourseIdInfo,
      )(courses);
    },
    [courses, trainingCategory],
  );

  return courseIdInfo;
};
