import React from 'react';
import type { PropsWithChildren, MouseEventHandler } from 'react';
import { includes } from 'lodash/fp';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

import slashySlug from './slashySlug';

export type SmartLinkProps = PropsWithChildren<{
  href?: string,
  slug?: string,
  anchorTag?: string,
  openInNewWindow?: boolean,
  ariaLabel?: string,
  onClick?: MouseEventHandler<HTMLAnchorElement>,
  sx?: any,
  className?: string;
}>;

const SmartLink = ({
  href,
  slug,
  anchorTag,
  openInNewWindow = false,
  ariaLabel,
  onClick,
  sx,
  children,
  className = undefined,
}: SmartLinkProps) => {
  const linkProps = openInNewWindow || !includes('improving.com', href)
    ? {
      target: '_blank',
      rel: 'noreferrer',
    }
    : {};

  const { language } = useI18next();

  if (anchorTag) {
    return (
      <a
        href={`#${anchorTag}`}
        onClick={onClick}
        aria-label={ariaLabel}
        className={className}
        style={{
          ...sx,
          ':focusVisible': {
            outlineOffset: 1,
            outline: '-webkit-focus-ring-color auto 1px',
          },
        }}
      >
        {children}
      </a>
    );
  }

  if (!href && !slug) {
    return (
      <span aria-label={ariaLabel}>
        {children}
      </span>
    );
  }

  return (href
    ? (
      <a
        href={href}
        aria-label={ariaLabel}
        onClick={onClick}
        {...linkProps}
        className={className}
        style={{
          ...sx,
          ':focusVisible': {
            outlineOffset: 1,
            outline: '-webkit-focus-ring-color auto 1px',
          },
        }}
      >
        {children}
      </a>
    )
    : (
      <Link
        to={slashySlug(slug)}
        language={language}
        aria-label={ariaLabel}
        onClick={onClick}
        className={className}
        style={{
          ...sx,
          ':focusVisible': {
            outlineOffset: 1,
            outline: '-webkit-focus-ring-color auto 1px',
          },
        }}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        {children}
      </Link>
    ));
};

export default SmartLink;
