import React from 'react';
import { Box } from '@mui/material';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';

type BgImageProps = {
  bgImage: IGatsbyImageData
  minHeight?: {
    xs?: string | number,
    sm?: string | number,
    md?: string | number,
    lg?: string | number,
    [key: string]: any,
  },
  position?: {
    xs?: string,
    sm?: string,
    md?: string,
    lg?: string,
    [key: string]: any,
  },
  alt?: string,
};

const BgImage: React.FC<BgImageProps> = ({
  bgImage, minHeight, alt, position,
}) => (
  <Box
    sx={{
      position: position || {
        xs: 'relative',
        sm: 'relative',
        md: 'relative',
        lg: 'absolute',
      },
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 10,
      height: '100%',
      minHeight: minHeight || {
        xs: '135px',
        sm: '240px',
        lg: '100%',
      },
    }}
  >
    {bgImage && (
    <GatsbyImage
      image={bgImage}
      alt={alt || 'Background Image'}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
      }}
    />
    )}
  </Box>
);

export default BgImage;
