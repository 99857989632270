import React from 'react';
import {
  Divider,
  Grid,
  useTheme,
} from '@mui/material';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import type { TextDefinition } from '#contentful/Text';
import Text from '#contentful/Text';
import type { ImageDefinition } from '#contentful/Image';
import Image from '#contentful/Image';
import type { IconButtonDefinition } from '#contentful/IconButton';
import IconButton from '#contentful/IconButton';
import type { BackgroundDefinition } from '#components/Section';
import SectionSettings from '#components/Section/SectionSettings';
import type { ContentfulSectionSpacingDefinition } from '#hooks/useSectionSpacing';
import useSectionSpacing from '#hooks/useSectionSpacing';
import type { ButtonDefinition } from '#contentful/Button';
import Button from '#contentful/Button';

export type IconListItemDefinition = IconListItemProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentIconListItem',
  },
};

export type IconListDivider = {
  color?: {
    value: string,
  }
  size?: string,
};

export type IconListItemContent = {
  id: string,
  icon: IconButtonDefinition | ImageDefinition,
  text: TextDefinition,
  button?: ButtonDefinition
  background: BackgroundDefinition,
  imageColumns: number,
  contentPosition: string,
  spacingOptions?: ContentfulSectionSpacingDefinition,
};

export type IconListItemProps = IconListItemContent & IconListDivider & {
  index: number,
  length: number,
};

const IconListItem = ({
  id,
  icon,
  text,
  index,
  color: dividerColor,
  size: dividerSize,
  length,
  background,
  imageColumns = 3,
  contentPosition,
  spacingOptions,
  button,
}: IconListItemProps) => {
  const { spacing } = useTheme();

  //  Calculate section settings
  const responsiveSectionSpacing = useSectionSpacing(
    {
      ...spacingOptions,
    },
    {
      mtmd: 0,
      mbmd: 0,
      mtlg: 0,
      mblg: 0,
      mllg: 0,
      mrlg: 0,
      ptmd: 0,
      pbmd: 0,
      plmd: 0,
      prmd: 0,
      ptlg: 0,
      pblg: 0,
      pllg: 0,
      prlg: 0,
      plxl: 0,
      prxl: 0,
      columnSpacinglg: 0,
    },
  );

  const settings = {
    ...background,
    ...responsiveSectionSpacing,
  };

  const textColumns = 12 - (imageColumns || 3);

  const showDivider = index !== length - 1 && dividerColor && dividerSize;

  return (
    <>
      <SectionSettings {...settings}>
        <Grid
          container
          direction="column"
        >
          <Grid
            item
            container
            key={id}
            py={2}
            px={2}
            alignItems="center"
            justifyContent="center"
            columnSpacing={spacing(spacingOptions?.columnSpacing || 'none')}
            flexDirection={contentPosition === 'Alternate' ? 'row-reverse' : 'row'}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            className="pb-0"
          >
            <Grid
              item
              xs={imageColumns || 3}
              padding={2}
              justifyContent="center"
              alignItems="center"
            >
              {
              icon?.internal?.type === 'ContentfulComponentImage' ? (
                <Image
                  content={icon as ImageDefinition}
                />
              )
                : <IconButton {...icon as IconButtonDefinition} />
            }
            </Grid>
            {text && (
            <Grid item xs={textColumns} className="w-[200px]">
              <Text content={text} />
              {
                button && <Button className="justify-start" content={button} />
              }
            </Grid>
            )}
          </Grid>
        </Grid>
      </SectionSettings>
      {showDivider && (
      <Divider aria-hidden="true" sx={{ backgroundColor: dividerColor?.value, height: dividerSize, width: '100%' }} />
      )}
    </>
  );
};

export default IconListItem;
