import React from 'react';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import type { QuoteDefinition } from '#components/ContentfulComponent/Quote';
import Quote from '#components/ContentfulComponent/Quote';
import type { BackgroundDefinition } from '#components/Section';
import ProfileCard from '#components/ProfileCard';
import SectionSettings from '#components/Section/SectionSettings';
import Button from '#contentful/Button';
import type { ButtonDefinition } from '#contentful/Button';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';
import type { PersonDefinition } from '../Team/PersonDefinition';

export type PersonWithQuoteDefinition = ContentfulComponentDefinition & {
  heading?: string,
  person: PersonDefinition,
  quote: QuoteDefinition,
  background?: BackgroundDefinition,
  link: ButtonDefinition,
};

export type PersonWithQuoteProps = {
  content: PersonWithQuoteDefinition,
};

const translationKey = 'Components.PersonWithQuote';

const PersonWithQuote = ({
  content: {
    heading,
    person,
    quote,
    background,
    link,
  },
}: PersonWithQuoteProps) => {
  const {
    name, title, image, profilePage,
  } = person || {};
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'grid' }}>
      {heading && (
      <Typography variant="h2" sx={{ textAlign: 'center' }}>
        {heading}
      </Typography>
      )}

      <SectionSettings {...background}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={2}
          rowSpacing={3}
          p={{ xs: 0, md: 5 }}
          pt={{ xs: 2, md: 5 }}
        >
          <Grid item xs={12} md={4}>
            <ProfileCard
              title={name}
              subtitle={title}
              photo={image}
              buttonLabel={t(`${translationKey}.ReadBio`) ?? 'Read Bio'}
              slug={profilePage?.[0]?.composePage?.[0]?.slug}
              buttonVariant="bio"
              showPhoto
              showButton
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Quote content={quote} />
            {link && (
              <Button
                content={link}
                sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
              />
            )}
          </Grid>
        </Grid>
      </SectionSettings>
    </Box>
  );
};

export default PersonWithQuote;
