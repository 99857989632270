import React, { useState } from 'react';
import {
  Box, Grid, useMediaQuery, useTheme,
} from '@mui/material';

import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import type { ImageDefinition } from '#contentful/Image';
import type { ButtonDefinition } from '#contentful/Button';
import type { BackgroundDefinition } from '#components/Section';
import type { TextDefinition } from '#contentful/Text';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import type { LinkDefinition } from '#contentful/Link';
import ContentfulLink from '#contentful/Link';
import Map from './Map';
import InteractiveMapTooltip from './InteractiveMapTooltip';

export type InteractiveMapDefinition = InteractiveMapProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentTab',
  },
};

type MarkerColor = {
  value: string,
};

export type MarkerOptions = {
  nonActiveColor: MarkerColor,
  hoverColor: MarkerColor,
  activeColor: MarkerColor,
};

export type Marker = {
  id: string,
  locationCard: LocationCard,
  position: Position,
  markerOptions: MarkerOptions,
};

export type LocationCard = {
  title: string,
  address: string,
  phone: string,
  image: ImageDefinition,
  text: TextDefinition[],
  button: ButtonDefinition,
  background: BackgroundDefinition,
  border: ContentfulBorderDefinition
};

type Position = {
  internal: {
    raw: string,
  },
};

export type InteractiveMapProps = {
  markers: Marker[],
  color?: MarkerColor,
  link?: LinkDefinition
};

const InteractiveMap = ({
  markers,
  color,
  link,
}: InteractiveMapProps) => {
  const [open, setOpen] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const handleTooltipClose = () => {
    setOpen(null);
  };

  const handleTooltipOpen = (marker: Marker) => {
    setOpen(marker.id);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Grid
        container
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        {link && (
        <Grid
          item
          xs={12}
          pr={{ xs: 2, md: 12 }}
          width={{ xs: '100%', sm: 400 }}
          mb={{ xs: 0 }}
          zIndex={2}
        >
          <Box display="flex" justifyContent="flex-end">
            <ContentfulLink
              content={link}
            >
              {link.label}
            </ContentfulLink>
          </Box>
        </Grid>
        )}
        <Grid item xs={12}>
          <Map
            color={color?.value}
            onClick={handleTooltipClose}
          />
          {
            markers?.map((marker) => (
              <InteractiveMapTooltip
                key={marker.id}
                marker={marker}
                open={open}
                handleTooltipOpen={handleTooltipOpen}
                isMobile={isMobile}
              />
            ))
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default InteractiveMap;
