import { useMemo } from 'react';
import {
  flow,
  map,
} from 'lodash/fp';

import type { LinkDefinition } from '#contentful/Link';
import type { NavMenuLinkDefinition } from '#components/NavMenuLink';
import type { NavMenuCategoryProps } from '#components/NavMenuCategory';

import type { NavigationItem } from './_useQuery';

const flattenContentfulLink = ({
  id,
  contentfulId,
  label,
  openInNewWindow,
  href,
  page,
}: LinkDefinition): NavMenuLinkDefinition => ({
  id,
  contentfulId,
  label: label as string,
  openInNewWindow,
  href,
  slug: page?.slug,
});

export default (navMenu: NavigationItem[]): NavMenuCategoryProps[] => (
  useMemo(
    () => map(
      (contentful: NavigationItem) => {
        const item = flattenContentfulLink(contentful.item) as NavMenuCategoryProps;
        item.subItems = flow(
          map('item'),
          map(flattenContentfulLink),
        )(contentful.subItems);

        return item;
      },
      navMenu,
    ),
    [navMenu],
  )
);
