import React from 'react';

import type { TextDefinition } from '#contentful/Text';
import Text from '#contentful/Text';
import type { ImageDefinition } from '#contentful/Image';
import Image from '#contentful/Image';
import type { ButtonDefinition } from '#contentful/Button';
import type { SuggestedContentDefinition } from '#contentful/SuggestedContent';
import SuggestedContent from '#contentful/SuggestedContent';
import Button from '#contentful/Button';

export type GraphicCardContentDefinition =
  TextDefinition
  | ImageDefinition
  | ButtonDefinition
  | SuggestedContentDefinition;

type GraphicCardContentProps = {
  content: GraphicCardContentDefinition,
};

const GraphicCardContent = ({ content }: GraphicCardContentProps) => {
  if (!content.internal?.type) {
    return null;
  }

  switch (content.internal.type) {
    case 'ContentfulComponentText':
      return (
        <Text content={content as TextDefinition} maxWidth="100%" />
      );
    case 'ContentfulComponentImage':
      return (
        <Image content={content as ImageDefinition} />
      );
    case 'ContentfulComponentButton':
      return (
        <Button
          key={content.id}
          content={content as ButtonDefinition}
          sx={{ px: { xs: 2, md: 0 }, mt: 2 }}
        />
      );
    case 'ContentfulComponentSuggestedContent':
      return (
        <SuggestedContent key={content.id} content={content as SuggestedContentDefinition} />
      );
    default:
      throw new Error(`Unexpected type: ${JSON.stringify(content)}`);
  }
};

export default GraphicCardContent;
