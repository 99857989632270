import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { filter } from 'lodash/fp';
import { useMemo } from 'react';

export default () => {
  const { language } = useI18next();

  const data = useStaticQuery(graphql`
    query {
      all: allContentfulComposeFooter {
        footers: nodes {
          content {
            motto
            improvingSocials {
              ...SocialSharing
            }
            farLeftColumn {
              raw
            }
            middleLeftColumn {
              raw
            }
            middleRightColumn {
              raw
            }
            farRightColumn {
              raw
            }
            legalLinks {
              raw
            }
          }
          nodeLocale: node_locale
        }
      }
    }
  `);

  const filteredFooter = useMemo(() => filter(
    ({ nodeLocale }) => nodeLocale === language,
    data.all.footers,
  ), [data, language]);

  return filteredFooter[0].content;
};
