import React from 'react';
import {
  Box,
} from '@mui/material';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import Counting from './Animations/Counting';
import Writing from './Animations/Writing';

export type AnimatedTextDefinition = AnimatedTextProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentAnimatedText',
  },
};

export type AnimatedTextProps = {
  defaultValue: string,
  animationType: string,
  animationOptions: {
    internal: {
      type: string,
      raw: string,
    }
  }
};

const AnimatedText = ({
  defaultValue,
  animationType,
  animationOptions,
}: AnimatedTextProps) => (
  <Box
    sx={{
      textAlign: 'center',
    }}
    maxWidth="780px"
  >
    {animationType === 'Counting' && (
    <Counting
      defaultValue={defaultValue}
      animationOptions={animationOptions?.internal?.raw}
    />
    )}

    {animationType === 'Typewritter' && (
    <Writing animationOptions={animationOptions.internal.raw} />
    )}
  </Box>
);

export default AnimatedText;
