import React from 'react';
import type { SkeletonProps } from '@mui/material';
import { Skeleton as MuiSkeleton } from '@mui/material';

const Skeleton = ({ ...props }: SkeletonProps) => (
  <MuiSkeleton
    variant="rounded"
    animation="wave"
    sx={{ bgcolor: 'palette.gradient?.bluegreenlefttoright' }}
    data-testid="skeleton-component"
    {...props}
  />
);

export default Skeleton;
