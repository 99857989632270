import { graphql, useStaticQuery } from 'gatsby';

export default () => useStaticQuery(graphql`
  query {
    all: allContentfulComponentWebinar(
      sort: {startDateTime:DESC}
    ) {
      cards: nodes {
        ...WebinarCard
      }
    }
  }
`);
