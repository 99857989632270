/* eslint-disable react/function-component-definition */
import React from 'react';
import type { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

import SmartLink from '#components/SmartLink';

import { cn } from '@/utils';
import clsx from 'clsx';

export type LinkCellProps = PropsWithChildren<{
  slug?: string,
  href?: string,
  anchorTag?: string,
  className?: string,
  minWidth?: string | number,
}>;

function LinkCell({
  slug,
  href,
  anchorTag,
  className,
  minWidth,
  children,
}: LinkCellProps) {
  const isLink = href || slug;

  return (
    <div
      className={cn('text-left break-words text-impgray900', className)}
      style={{ minWidth }}
    >
      <SmartLink
        slug={slug}
        href={href}
        anchorTag={anchorTag}
        className={clsx('text-inherit', isLink && 'hover:text-impgreen500')}
      >
        <Typography variant="subtitle1">
          {children}
        </Typography>
      </SmartLink>
    </div>
  );
}

export default LinkCell;
