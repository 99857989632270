import React from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import type { LinkDefinition } from '#contentful/Link';
import { GatsbyImage } from 'gatsby-plugin-image';
import SmartLink from '#components/SmartLink';
import type { ColorIndex } from '#components/Layout';
import useColor from '#hooks/useColor';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import useBorder from '#hooks/useBorder';

import type { GatsbyImageProps } from '../Image';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';
import type { TextDefinition } from '../Text';
import Text from '../Text';

export type IconButtonDefinition = IconButtonProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentIconButton'
  }
};

export type IconButtonProps = {
  icon: GatsbyImageProps,
  link?: LinkDefinition,
  label?: string,
  labelColor?: ColorIndex,
  description?: TextDefinition
  border?: ContentfulBorderDefinition,
  style?: React.CSSProperties,
  preloadImage?: boolean,
};

const IconButton = ({
  icon,
  link,
  label,
  labelColor,
  description,
  border,
  style,
  preloadImage = false,
}: IconButtonProps) => {
  const fontColor = useColor('color', labelColor);
  const borderStyles = useBorder(border);

  return (
    <Box
      className={link ? 'hover-scale reveal transition-ease-1' : ''}
    >
      <SmartLink
        href={link?.href}
        slug={link?.page?.slug}
        anchorTag={link?.anchorTag?.customAnchorTag ?? link?.anchorTag?.id}
        sx={{
          textDecoration: 'none',
          color: 'color.impgreen500',
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ ...borderStyles }}
        >
          {icon
          && preloadImage && (
          <Grid item className='className="pt-2 pl-4 pr-4'>
            <img
              src={icon.url}
              alt={icon.alt}
              style={style}
            />
          </Grid>
          )}

          {icon
          && !preloadImage && (
          <Grid item className='className="pt-2 pl-4 pr-4'>
            <GatsbyImage
              loading="lazy"
              image={icon.gatsbyImageData}
              alt={icon.alt}
              style={style}
            />
          </Grid>
          )}

          {label && (
          <Grid item textAlign="center" className="font-poppins-bold mt-[10px] ml-4 mr-4 mb-4">
            <Typography
              variant="iconLabel"
              color={labelColor ? fontColor : 'color.impgray900'}
            >
              {label}
            </Typography>
          </Grid>
          )}

          {
            description && (
              <Grid item className="mt-1 pl-2 pr-2 w-52 overflow-hidden">
                <Text content={description} className="min-w-0 text-center" />
              </Grid>
            )
          }
        </Grid>
      </SmartLink>
    </Box>
  );
};

export default IconButton;
