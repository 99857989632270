import {
  curry,
  find,
  first,
  map,
} from 'lodash/fp';

import type {
  CurrencyCode,
  ArloEventData,
  ArloPresenter,
} from '#services/arlo';
import type { InstructorDefinition } from '#components/Listing/FullClassScheduleTable';
import type { LocationCellDefinition } from '#components/DataTable/LocationCell';
import type { LocationPage } from '#hooks/useLocationPages';
import type { TrainingProfilePage } from '#hooks/useTrainingProfilePages';

import flattenOffers from './flattenAdvertisedOffers';

export type FlatClassData = {
  id: number,
  eventTemplateId: number,
  name: string,
  summary: string,
  description: string,
  date: string,
  start: string,
  end: string,
  location: LocationCellDefinition,
  instructors: InstructorDefinition[],
  instructorNames: string,
  basePrice: string,
  discountPrice?: string,
  currencyCode: CurrencyCode,
  registrationUri: string,
};

export default curry((
  locationPages: LocationPage[],
  trainingProfilePages: TrainingProfilePage[],
  arloEvent: ArloEventData,
) => {
  const {
    EventID,
    EventTemplateID,
    Name,
    Summary,
    Description,
    StartDateTime,
    EndDateTime,
    Location: {
      Name: LocationName,
      IsOnline,
      StreetLine1,
      City,
      State,
      VenueID,
    },
    Presenters,
    AdvertisedOffers,
    RegistrationInfo: {
      RegisterUri,
    },
  } = arloEvent;
  const flatOffer = flattenOffers(AdvertisedOffers);

  return {
    id: EventID,
    eventTemplateId: EventTemplateID,
    name: Name,
    summary: Summary,
    description: Description.Text,
    date: `${StartDateTime} - ${EndDateTime}`,
    start: StartDateTime,
    end: EndDateTime,
    location: {
      isOnline: IsOnline,
      name: LocationName,
      street1: StreetLine1,
      city: City,
      state: State,
      slug: find(
        ({ arloVenueId: VenueID }),
        locationPages,
      )?.slug,
    },
    instructors: map(
      ({
        PresenterID,
        Name: instructorName,
      }: ArloPresenter) => ({
        id: PresenterID,
        name: instructorName,
        slug: find(
          ({ arloPresenterId: PresenterID }),
          trainingProfilePages,
        )?.slug,
      }),
      Presenters,
    ) as InstructorDefinition[],
    instructorNames: map('Name', Presenters).join(','),
    basePrice: flatOffer.basePrice ?? '',
    discountPrice: flatOffer.discountPrice,
    currencyCode: first(AdvertisedOffers)!.OfferAmount.CurrencyCode as CurrencyCode,
    registrationUri: RegisterUri,
  };
});
