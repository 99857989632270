type Location = string | {
  isOnline: boolean,
  name: string,
};

export type LocationProps = {
  id: string;
  location: Location;
};

export default <T extends LocationProps> (item: T): T => {
  if (typeof item.location === 'object') {
    const isOnline = item.location?.isOnline;
    const location = isOnline ? 'Online' : item.location?.name;
    return { ...item, location };
  }
  return item;
};
