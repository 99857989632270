import React from 'react';

import BlogCard from '#contentful/ContentfulCard/BlogCard';
import DumbBlogCard from '#components/BlogCard';
import type { ContentfulBlogCardDefinition } from '#components/BlogCard';
import type {
  BlogCardDefinition,
} from '#contentful/ContentfulCard/BlogCard';
import type { ContentfulWebinarCardDefinition } from '#components/WebinarCard';
import WebinarCard from '#components/WebinarCard';
import type { UserGroupCardDefinition } from '#components/UserGroupCard';
import UserGroupCard from '#components/UserGroupCard';
import type { SquaredButtonDefinition } from '#contentful/SquaredButton';
import SquaredButton from '#contentful/SquaredButton';
import type { ArloTrainingCardDefinition } from '#components/ArloTrainingCard';
import ArloTrainingCard from '#components/ArloTrainingCard';
import type { ContentCardDefinition } from '#components/ContentCard';

import ContentCard from '#components/ContentCard';
import type { GatsbyImageProps } from '#contentful/Image';
import type { TextCardDefinition } from '#contentful/TextCard';
import TextCard from '#contentful/TextCard';
import FullImageCard from './FullImageCard';
import type { FullImageCardDefinition } from './FullImageCard';
import type { TrainingCourseCardDefinition } from './TrainingCourseCard';
import type { TrainingPageCardDefinition } from './TrainingPageCard';
import TrainingPageCard from './TrainingPageCard';
import TrainingCourseCard from './TrainingCourseCard';
import type { IconButtonDefinition } from '../IconButton';
import IconButton from '../IconButton';

export type Category = {
  id?: string,
  displayName: string,
  isPrimary?: boolean,
  contentfulId: string,
  listingPage?: {
    composePage?: {
      slug: string,
    }[]
  }[]
};

export type CaseStudyCategory = Category & {
  parentCategory?: Category,
};

export type AnyContentfulCardDefinition = (
  BlogCardDefinition
  | FullImageCardDefinition
  | TrainingCourseCardDefinition
  | TrainingPageCardDefinition
  | ArloTrainingCardDefinition
  | ContentfulBlogCardDefinition
  | ContentfulWebinarCardDefinition
  | IconButtonDefinition
  | UserGroupCardDefinition
  | SquaredButtonDefinition
  | ContentCardDefinition
  | TextCardDefinition
) & {
  categories?: Category[] | CaseStudyCategory[],
  newsroomCategories?: Category[],
  contentfulId?:string,
  composePage?: {
    slug?: string
  },
  featured?: boolean,
  nodeLocale?: string,
};

export type ContentfulCardProps = {
  card: AnyContentfulCardDefinition,
};

const ContentfulCard = ({ card }: ContentfulCardProps) => {
  switch (card.internal?.type) {
    case 'ContentfulComponentBlogCard':
      return <BlogCard key={card.id} card={card as BlogCardDefinition} isBlog />;
    case 'ContentfulPageTemplateBlogPost': {
      const {
        title = '',
        categories = [],
        snippet = { text: '' },
        thumbnail = {} as GatsbyImageProps,
        composePage = [],
      } = card as ContentfulBlogCardDefinition;

      return (
        <DumbBlogCard
          key={card.id}
          title={title}
          category={categories?.[0]?.displayName}
          summary={snippet}
          image={thumbnail}
          slug={composePage?.[0]?.slug}
          isBlog
        />
      );
    }
    case 'ContentfulComponentFullImageCard':
      return <FullImageCard key={card.id} card={card as FullImageCardDefinition} />;
    case 'ContentfulComponentTrainingCourseCard':
      return <TrainingCourseCard key={card.id} card={card as TrainingCourseCardDefinition} />;
    case 'ContentfulComponentTrainingCourse':
      return <TrainingPageCard key={card.id} card={card as TrainingPageCardDefinition} />;
    case 'ArloTrainingClass':
      return <ArloTrainingCard key={card.id} {...card as ArloTrainingCardDefinition} />;
    case 'ContentfulComponentWebinar': {
      const {
        title = '',
        snippet = { text: '' },
        startDateTime = '',
        webinarPage = null,
        registrationUrl = null,
        videoUrl = null,
      } = card as ContentfulWebinarCardDefinition;

      return (
        <WebinarCard
          key={card.id}
          title={title}
          snippet={snippet}
          startDateTime={startDateTime}
          slug={webinarPage?.[0]?.composePage?.[0]?.slug}
          registrationUrl={registrationUrl?.text}
          videoUrl={videoUrl?.text}
        />
      );
    }
    case 'ContentfulComponentIconButton': {
      const {
        icon,
        link,
        label,
        labelColor,
        border,
        description,
      } = card as IconButtonDefinition;

      return (
        <IconButton
          key={card.id}
          icon={icon}
          link={link}
          label={label}
          labelColor={labelColor}
          border={border}
          description={description}
        />
      );
    }
    case 'ContentfulUserGroupMeeting': {
      return <UserGroupCard {...card as UserGroupCardDefinition} />;
    }
    case 'ContentfulComponentButton': {
      return <SquaredButton {...card as SquaredButtonDefinition} />;
    }
    case 'ContentfulComponentContentCard': {
      return <ContentCard {...card as ContentCardDefinition} />;
    }
    case 'ContentfulComponentCaseStudyCard': {
      return <BlogCard key={card.id} card={card as BlogCardDefinition} />;
    }
    case 'ContentfulPageTemplateCaseStudyPost': {
      const {
        title = '',
        categories = [],
        snippet = { text: '' },
        thumbnail = {} as GatsbyImageProps,
        composePage = [],
      } = card as ContentfulBlogCardDefinition;

      return (
        <DumbBlogCard
          key={card.id}
          title={title}
          category={categories?.[0]?.displayName}
          summary={snippet}
          image={thumbnail}
          slug={composePage?.[0]?.slug}
        />
      );
    }
    case 'ContentfulSubComponentTextCard':
      return <TextCard key={card.id} content={card as TextCardDefinition} />;
    default:
      throw new Error(`Unexpected type in Suggested Content: ${JSON.stringify(card)}`);
  }
};

export default ContentfulCard;
