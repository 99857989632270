import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { filter } from 'lodash/fp';
import { useMemo } from 'react';

import type {
  ContentfulWebinarCardDefinition,
} from '#components/WebinarCard';

export default (): ContentfulWebinarCardDefinition[] => {
  const { language } = useI18next();

  const data = useStaticQuery(graphql`
    query {
      all: allContentfulComponentWebinar(
        sort: {startDateTime: DESC}
      ) {
        cards: nodes {
          ...WebinarCard
        }
      }
    }
  `);

  const filteredCards = useMemo(() => filter(
    ({ nodeLocale }) => nodeLocale === language,
    data.all.cards,
  ), [data, language]);

  return filteredCards;
};
