import type { ChangeEvent } from 'react';
import React, {
  useMemo, useState, useContext, useEffect,
} from 'react';

import {
  FormControlLabel,
  Skeleton,
  Switch,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import type { GridFilterItem, GridFilterModel } from '@mui/x-data-grid';
import {
  first,
  isEmpty,
} from 'lodash/fp';

import SearchBar from '#components/SearchBar';
import SmartLink from '#components/SmartLink';
import useColor from '#hooks/useColor';
import RegionSelector from '#components/RegionSelector';
import type { ArloRegionCodes } from '#services/arlo';
import { GeolocationContext } from '#providers/GeolocationProvider';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import clsx from 'clsx';
import type { LocationProps } from './_useNormalizeLocation';
import normalizeLocation from './_useNormalizeLocation';
import useSearchedItems from './_useSearchedItems';
import type { DataTableProps } from './DataTable.types';

const translationkey = 'Components.DataTable';

const DataTable = <T,>({
  columns,
  items,
  noItemsCopy,
  heading,
  headingVariant = 'h2',
  headingFontColor = 'impblue300',
  text,
  href,
  slug,
  anchorTag,
  searchable = false,
  initialRowCount = 10,
  rowsPerPageOptions = [3, 5, 10, 25, 50],
  hasRegionSelector = false,
  toggleIncludeOnline = false,
  includeOnline = true,
  setIncludeOnline,
  viewAllLinkLabel,
  loadingItems,
}: DataTableProps<T>) => {
  const { t } = useTranslation();

  const { region, setRegion } = useContext(GeolocationContext);

  //  get filter state based on query params
  //  must check to ensure the window exists
  const queryParams = useMemo(
    () => new URLSearchParams(
      typeof window === 'undefined'
        ? undefined
        : window.location.search,
    ),
    [],
  );

  const [filterItem, setFilterItem] = useState<GridFilterItem | undefined>(
    queryParams.get('field')
      ? {
        columnField: queryParams.get('field')!,
        operatorValue: queryParams.get('operator')!,
        value: queryParams.get('value')!,
      }
      : undefined,
  );

  const searchableColumns = useMemo(
    () => columns
      .filter((c) => c.searchable)
      .map((c) => c.valueField!),
    [columns],
  );

  const [searchValue, setSearchValue] = useState(
    queryParams.get('search') ?? '',
  );

  // location is sometimes a string, sometimes an object
  const itemsToSearch = (items as LocationProps[]).map(normalizeLocation);

  const searchedItems = useSearchedItems(
    itemsToSearch,
    searchableColumns,
    searchValue,
  );

  useEffect(
    () => {
      if (typeof window === 'undefined') {
        return;
      }
      //  update values based on what's being passed in
      const hasFilter = !isEmpty(filterItem?.value);
      const hasSearch = !isEmpty(searchValue);
      const notOnline = !includeOnline;
      const nonUsRegion = isEmpty(region)
        ? !!queryParams.get('region')
        : region !== 'us';

      //  update the filters
      if (hasFilter) {
        queryParams.set('field', filterItem?.columnField!);
        queryParams.set('operator', filterItem?.operatorValue!);
        queryParams.set('value', filterItem?.value);
      } else {
        queryParams.delete('field');
        queryParams.delete('operator');
        queryParams.delete('value');
      }

      //  update the search param
      if (hasSearch) {
        queryParams.set('search', searchValue!);
      } else {
        queryParams.delete('search');
      }

      //  update the include online param
      if (notOnline) {
        queryParams.set('online', 'false');
      } else {
        queryParams.delete('online');
      }

      //  update the region info
      if (nonUsRegion) {
        const newRegion = region
          ?? queryParams.get('region')! as ArloRegionCodes;
        queryParams.set('region', newRegion);
        setRegion(newRegion);
      } else {
        queryParams.delete('region');
      }

      //  determine the shape of the url
      let url = '';
      if (hasFilter || hasSearch || notOnline || nonUsRegion) {
        url = `?${queryParams.toString()}`;
      } else {
        url = window.location.href
          .split('?')[0].toString();
      }

      //  update the url
      window.history.replaceState(
        {},
        document.title,
        url,
      );
    },
    [
      queryParams,
      filterItem,
      searchValue,
      includeOnline,
      region,
      setRegion,
    ],
  );

  const [pageSize, setPageSize] = useState(initialRowCount);

  const headingTextColor = useColor('color', headingFontColor);

  const handleFilterModelChange = (
    { items: filterItems }: GridFilterModel,
  ) => {
    //  for the basic version of DataGrid, only one filter item is allowed at a time
    setFilterItem(first(filterItems));
  };

  const handleInludeOnlineChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setIncludeOnline!(event.target.checked);
  };

  const listedItems = useMemo(
    () => (isEmpty(searchValue) ? items : items.filter(
      (item) => searchedItems.find((searchedItem) => searchedItem.id === item.id),
    )),
    [searchValue, items, searchedItems],
  );

  if (loadingItems) {
    return (
      <Skeleton
        variant="rounded"
        animation="wave"
        height={400}
        className="bg-bluegreenlefttoright"
      />
    );
  }

  return (
    <div className="flex flex-col px-4">
      {heading && (
        <div className="w-full">
          <Typography
            variant={headingVariant ?? 'h2'}
            className="text-center"
            style={{ color: headingTextColor }}
          >
            {heading}
          </Typography>
        </div>
      )}

      {text && (
        <div className="w-2/3">
          <Typography
            variant="subheading2"
            className="block text-center mb-12 max-w-[46rem]"
          >
            {text}
          </Typography>
        </div>
      )}

      {(hasRegionSelector || toggleIncludeOnline) && (
        <div className="mb-8 flex flex-wrap items-center justify-center max-sm:gap-4">
          {toggleIncludeOnline && setIncludeOnline && (
            <div className="flex justify-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
              <FormControlLabel
                label={t(`${translationkey}.IncludeOnline`)}
                control={(
                  <Switch
                    checked={includeOnline}
                    onChange={handleInludeOnlineChange}
                  />
                )}
              />
            </div>
          )}

          {hasRegionSelector && (
            <div className="flex justify-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
              <RegionSelector defaultValue={region} />
            </div>
          )}
        </div>
      )}

      {(href || slug || anchorTag) && (
        <div className="mb-5 pr-[10px] self-end">
          <Typography
            variant="subtitle1"
            className="ContentfulLink"
          >
            <SmartLink
              href={href}
              slug={slug}
              anchorTag={anchorTag}
              className="mb-5 pr-[10px]"
            >
              {viewAllLinkLabel}
            </SmartLink>
          </Typography>
        </div>
      )}

      <div className="w-full">
        {(items.length > 0)
          ? (
            <>
              {searchable && (
                <div className="flex justify-center px-4 py-6 rounded-t bg-impgreen500">
                  <SearchBar
                    value={searchValue ?? ''}
                    onChange={setSearchValue}
                    inputAriaLabel="search items"
                    className="flex items-center w-full md:w-[600px]"
                  />
                </div>
              )}

              <DataGrid
                className={clsx(
                  'border-0 max-md:overflow-x-scroll',
                  searchable && 'rounded-t-none',
                )}
                classes={{
                  columnHeaders: clsx(searchable && 'rounded-t-none'),
                  columnHeader: 'text-impgray900 bg-impgreen500/35',
                  menuIcon: 'ml-0.5 opacity-80 !visible !w-auto',
                  sortIcon: 'opacity-80 visible',
                  cell: '!min-h-[60px]',
                  row: '!min-h-[60px]',
                }}
                rows={listedItems}
                columns={columns}
                sortingOrder={['desc', 'asc']}
                pagination
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
                rowsPerPageOptions={rowsPerPageOptions}
                autoHeight
                getRowHeight={() => 'auto'}
                onFilterModelChange={handleFilterModelChange}
                localeText={{
                  MuiTablePagination: {
                    labelRowsPerPage: t(`${translationkey}.RowsPerPage`),
                    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t(`${translationkey}.Of`)} ${count}`,
                  },
                }}
                initialState={{
                  filter: {
                    filterModel: filterItem
                      ? {
                        items: [filterItem],
                      }
                      : undefined,
                  },
                }}
              />
            </>
          ) : (
            <div
              className="
                bg-lightblue-lightgreen-soft
                md:mx-[6%] lg:mx-[16%]
                p-4 sm:p-8 md:p-16
              "
            >
              <Typography className="text-center">
                {noItemsCopy}
              </Typography>
            </div>
          )}
      </div>
    </div>
  );
};

export default DataTable;
