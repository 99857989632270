import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {
  contains,
  defaultsDeep,
  findIndex,
  forEach,
} from 'lodash/fp';
import defaultOptions from './_defaultOptions';

import type { RichTextReference } from './_richTextReferences';
import richTextReferences from './_richTextReferences';

export type RichTextContent = Parameters<typeof renderRichText>[0];
export type RichTextOptions = Parameters<typeof renderRichText>[1];

export type RichTextProps = {
  content: RichTextContent,
  options?: RichTextOptions,
};

const RichText = ({
  content,
  options,
}: RichTextProps) => {
  const combinedOptions = defaultsDeep(defaultOptions, options);

  if (!content || !content.raw) {
    return <span />;
  }

  const elements = JSON.parse(content.raw).content;

  forEach(
    (reference: RichTextReference) => {
      if (reference.internal.type === 'ContentfulAsset') {
        const { contentful_id: id, gatsbyImageData, alt } = reference;

        const imageIndex = findIndex(
          (e: any) => e.data?.target?.sys?.id === id,
          elements,
        );

        const proceedsHeading = contains(
          'heading',
          elements?.[imageIndex - 1]?.nodeType,
        );

        richTextReferences[id] = {
          image: gatsbyImageData,
          alt,
          boxProps: {
            mt: proceedsHeading ? 0 : 8,
          },
        };
      }

      if (reference.internal.type === 'ContentfulComponentCodeBlock') {
        const { contentful_id: id, code, language } = reference;
        richTextReferences[id] = { code, language };
      }
    },
    content?.references,
  );

  return (
    <div className="rich-text">
      {
        renderRichText(
          content,
          combinedOptions,
        )
      }
    </div>
  );
};

export default RichText;
