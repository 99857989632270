import React from 'react';

const GlassDoorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    xmlSpace="preserve"
    width="40"
    height="40"
    className="enable-background"
  >
    <g fillRule="evenodd" clipRule="evenodd" fill="#edeeee">
      <path
        d="M24.2 10.2c1.55 0 2.8 1.25 2.8 2.8H15.8v11.5a.1.1 0 0 1-.1.1h-2.6a.1.1 0 0 1-.1-.1V13c0-1.55 1.25-2.8 2.8-2.8h8.4zm0 16.8H13c0 1.55 1.25 2.8 2.8 2.8h8.4c1.55 0 2.8-1.25 2.8-2.8V15.5a.1.1 0 0 0-.1-.1h-2.6a.1.1 0 0 0-.1.1V27z"
      />
    </g>
  </svg>
);

export default GlassDoorIcon;
