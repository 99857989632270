/* eslint-disable no-console */
import type { GeolocationResult } from '#services/geolocation/types';
import type { Context } from '@netlify/serverless-functions-api';

export default async (
  request: Request,
  context: Context,
) => {
  const { geo, ip } = context;

  const geoInfo = {
    city: geo?.city,
    clientIp: ip,
    countryCode: geo?.country?.code?.toLowerCase(),
    countryName: geo?.country?.name,
  } as GeolocationResult;

  console.log({ geoInfo });

  return new Response(JSON.stringify(geoInfo), {
    headers: { 'content-type': 'application/json' },
  });
};

export const config = {
  path: '/edge-api/geolocation',
};
