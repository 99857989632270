import React, { useEffect, useState } from 'react';
import {
  filter,
  first,
  flow,
  get,
  map,
  sortBy,
} from 'lodash/fp';

import type { ContentfulComponentDefinition } from '#components/ContentfulComponent/ContentfulComponentDefinition';
import CourseCard from '#components/CourseCard';
import { getEvents } from '#services/arlo';
import type { FlatClassData } from '#utils/flattenArloEventIntoClassData';
import flattenArloEventIntoClassData from '#utils/flattenArloEventIntoClassData';
import type { ArloEventData } from '#services/arlo/models/events';
import type { TrainingCourseCategory } from '#components/DataTable';

import getCourse from '#services/arlo/getCourse';
import type { FlatCourseData } from 'src/pages/templates/course/_flattenCourseData';
import flattenCourseData from 'src/pages/templates/course/_flattenCourseData';
import { useI18next } from 'gatsby-plugin-react-i18next';
import useLocationPages from '#hooks/useLocationPages';
import useTrainingProfilePages from '#hooks/useTrainingProfilePages';

export type TrainingPageCardDefinition = ContentfulComponentDefinition & {
  title: string,
  arloCourseTemplateId: number,
  categories: TrainingCourseCategory[],
  pageTemplate: {
    composePage: {
      slug: string,
    }[],
  }[],
  internal: {
    type: 'ContentfulComponentTrainingCourse'
  }
};

export type TrainingPageCardProps = {
  card: TrainingPageCardDefinition,
};

const TrainingPageCard = ({
  card: {
    title,
    arloCourseTemplateId,
    pageTemplate,
  },
}: TrainingPageCardProps) => {
  const { language } = useI18next();
  const locationPages = useLocationPages(language);
  const trainingProfilePages = useTrainingProfilePages(language);

  const [card, setCard] = useState<FlatClassData>();
  const [privateCard, setPrivateCard] = useState<FlatCourseData>();

  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  useEffect(() => setIsPrivate(!card), [card]);

  useEffect(() => {
    getEvents({}, { skip: 0, top: 100 }) //  try passing a filter the the course Id
      .then(
        flow(
          get('Items'),
          filter((i: ArloEventData) => i.EventTemplateID === arloCourseTemplateId),
          sortBy(['StartDateTime']),
          map(flattenArloEventIntoClassData(
            locationPages,
            trainingProfilePages,
          )),
          first,
          setCard,
        ),
      );
  }, [arloCourseTemplateId, locationPages, trainingProfilePages]);

  useEffect(
    () => {
      if (!card) {
        getCourse(arloCourseTemplateId)
          .then(
            flow(
              flattenCourseData,
              setPrivateCard,
            ),
          );
      }
    },
    [arloCourseTemplateId, card],
  );

  if (isPrivate) {
    return (
      <CourseCard
        title={title}
        slug={pageTemplate?.[0]?.composePage?.[0]?.slug ?? ''}
        duration={privateCard?.duration}
        registrationUrl={privateCard?.registrationUri}
      />
    );
  }

  return (
    <CourseCard
      title={title}
      start={card?.start ?? ''}
      end={card?.end ?? ''}
      slug={pageTemplate?.[0]?.composePage?.[0]?.slug ?? ''}
      registrationUrl={card?.registrationUri ?? ''}
    />
  );
};

export default TrainingPageCard;
