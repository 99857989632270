import React from 'react';
import { Grid, Typography } from '@mui/material';

import Button from '#components/Button';
import ClassSchedule from '#components/ClassSchedule/ClassSchedule';
import type { FlatClassData } from '#utils/flattenArloEventIntoClassData';
import LocationCell from '#components/DataTable/LocationCell';
import type { InstructorDefinition } from '#components/Listing/FullClassScheduleTable';
import LinkCell from '#components/DataTable/LinkCell';
import PriceCell from '#components/DataTable/PriceCell';

import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import paletteTheme from '#components/Layout/theme/palette.theme';
import type { ButtonDefinition } from '#contentful/Button';
import ContentfulButton from '#contentful/Button';
import VerticalTableHeader from './_VerticalTableHeader';

export type NextClassMobileProps = {
  class: FlatClassData,
  defaults?: {
    defaultDate: string,
    defaultLocation: string,
    defaultInstructor: string,
    defaultStartingAt: string,
    defaultButton: ButtonDefinition,
  },
};

const translationKey = 'Components.NextClassMobile';

const NextClassMobile = ({
  class: classData,
  defaults,
}: NextClassMobileProps) => {
  const { t } = useTranslation();

  const {
    start, end, location, instructors, basePrice, discountPrice, currencyCode, registrationUri,
  } = classData ?? {};

  const {
    defaultDate, defaultLocation, defaultInstructor, defaultStartingAt, defaultButton,
  } = defaults ?? {};

  const getDefaultButton = () => {
    if (defaultButton) {
      return <ContentfulButton content={defaultButton} />;
    }

    return t(`${translationKey}.RegistrationClosed`);
  };

  return (
    <Grid
      container
      rowSpacing={2}
      aria-label="training courses"
      sx={{ background: 'rgba(0,0,0,0)' }}
    >
      <Grid item xs={12}>
        <VerticalTableHeader>
          {t(`${translationKey}.Headers.Date`)}
        </VerticalTableHeader>
        {start && end ? (
          <ClassSchedule
            startDateTime={start}
            endDateTime={end}
          />
        ) : (
          <Typography
            variant="h6"
            sx={{ color: paletteTheme().color.impgray900, fontWeight: 'bold' }}
            textAlign="left"
          >
            {defaultDate}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <VerticalTableHeader>
          {t(`${translationKey}.Headers.Location`)}
        </VerticalTableHeader>
        {!isEmpty(location) ? (
          <LinkCell slug={location.slug}>
            <LocationCell {...location} />
          </LinkCell>
        ) : (
          <Typography
            variant="subtitle1"
            color="color.impgray900"
          >
            {defaultLocation}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} container>
        <VerticalTableHeader>
          {t(`${translationKey}.Headers.Instructors`)}
        </VerticalTableHeader>
        {isEmpty(instructors) ? (
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="color.impgray900"
            >
              {defaultInstructor ?? 'TBA'}
            </Typography>
          </Grid>
        ) : instructors.map(({
          id,
          name,
          slug,
        }: InstructorDefinition) => (
          <Grid
            item
            xs={12}
            key={id}
          >
            <LinkCell slug={slug}>
              {name}
            </LinkCell>
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12} container direction="column">
        <Grid item>
          <VerticalTableHeader>
            {t(`${translationKey}.Headers.StartingAt`)}
          </VerticalTableHeader>
        </Grid>
        <Grid item>
          {basePrice ? (
            <PriceCell
              basePrice={basePrice}
              discountPrice={discountPrice}
              currencyCode={currencyCode}
            />
          ) : (
            <Typography
              variant="subtitle1"
              color="color.impgray900"
            >
              {defaultStartingAt}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} py={4.5}>
        {Date.parse(start) > Date.now()
          ? (
            <Button
              variant="bio"
              isInTable
              href={registrationUri}
            >
              {t(`${translationKey}.RegisterButton`)}
            </Button>
          )
          : (
            getDefaultButton()
          )}
      </Grid>
    </Grid>
  );
};

export default NextClassMobile;
