import React from 'react';
import CountUp from 'react-countup';

type AnimationCountingProps = {
  animationOptions: string,
  defaultValue: string,
};

const AnimationCounting = ({ animationOptions, defaultValue }: AnimationCountingProps) => {
  const animationOptionsObject = JSON.parse(animationOptions);
  const {
    minimumValue,
    maximumValue,
    animationSpeed,
    fontColor,
    fontSize,
    fontWeight,
    fontTransformation,
    suffixPrefix,
    suffixPrefixPosition,
  } = animationOptionsObject || {};

  const textStyle = {
    fontFamily: 'Roboto-Light',
    display: 'inline-block',
    lineHeight: '1.4',
    letterSpacing: '-3.61px',
    fontWeight:
      fontTransformation === 'bold' ? 'bold' : fontWeight || 'inherit',
    fontSize: fontSize ? `${fontSize}` : 'inherit',
    color: fontColor || 'inherit',
    textDecoration: fontTransformation === 'underline' ? 'underline' : 'none',
    fontStyle: fontTransformation === 'italicized' ? 'italic' : 'normal',
  };

  if (!minimumValue || !maximumValue || !animationSpeed) {
    return (
      <div>
        <span style={textStyle}>{defaultValue?.[0]}</span>
      </div>
    );
  }

  return (
    <CountUp
      start={parseInt(minimumValue, 10)}
      end={parseInt(maximumValue, 10)}
      duration={parseInt(animationSpeed, 10) / 1000}
      enableScrollSpy
      delay={0}
    >
      {({ countUpRef }) => (
        <div>
          {suffixPrefix && suffixPrefixPosition === 'before' && (
            <span style={textStyle}>{suffixPrefix}</span>
          )}
          <span style={textStyle} ref={countUpRef} />
          {suffixPrefix && suffixPrefixPosition === 'after' && (
            <span style={textStyle}>{suffixPrefix}</span>
          )}
        </div>
      )}
    </CountUp>
  );
};

export default AnimationCounting;
