import React from 'react';
import {
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import type { BackgroundDefinition } from '#components/Section';
import ProfileCard from '#components/ProfileCard';
import SectionSettings from '#components/Section/SectionSettings';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';
import type { LocationCardDefinition } from './LocationCardDefinition';

export type LocationCollectionDefinition = ContentfulComponentDefinition & {
  title: string,
  background: BackgroundDefinition,
  locations: LocationCardDefinition[],
  internal: {
    type: 'ContentfulComponentLocationCollection',
  },
};

export type LocationCollectionProps = {
  content: LocationCollectionDefinition,
};

const translationKey = 'Components.LocationCollection';

const LocationCollection = ({
  content: {
    title,
    background,
    locations,
  },
}: LocationCollectionProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        {title}
      </Typography>

      <SectionSettings {...background}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          rowSpacing={5}
          columnSpacing={{ xs: 0, lg: 3 }}
          my={4}
          pb={6}
        >
          {locations && locations.map(({
            id,
            name,
            addressLine1,
            addressLine2,
            cityDistrict,
            stateProvince,
            postalCode,
            phoneNumber,
            photo,
            locationPage,
          }) => (
            <Grid
              item
              key={id}
              xs={12}
              md={6}
              lg={4}
            >
              <ProfileCard
                title={name}
                subtitle={(
                  <>
                    {`${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}`}
                    <br />
                    {`${cityDistrict}, ${stateProvince} ${postalCode}`}
                    <br />
                    {`${phoneNumber}`}
                  </>
            )}
                photo={photo}
                buttonLabel={t(`${translationKey}.ReadMore`) ?? 'Read More'}
                slug={locationPage?.[0]?.composePage?.[0]?.slug}
                buttonVariant="secondary"
                showPhoto
                showButton
              />
            </Grid>
          ))}
        </Grid>
      </SectionSettings>
    </Box>
  );
};

export default LocationCollection;
