import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export type Color = string;

export default (
  category: string,
  colorName: string | undefined,
  fallbackColor?: string,
): Color => {
  const theme = useTheme();

  return useMemo(
    () => {
      if (!colorName) {
        return fallbackColor ?? theme?.palette?.color?.impblue300 ?? '';
      }

      const color = colorName.toLowerCase().replaceAll('-', '');
      // TODO: Fix type error
      // @ts-ignore
      return theme?.palette[(category as ColorCategory)][color];
    },
    [category, colorName, theme?.palette, fallbackColor],
  );
};
