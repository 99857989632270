import React from 'react';

import type { ButtonVariant } from '#components/Button';
import Button from '#components/Button';
import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import type { GatsbyImageProps } from '#contentful/Image';
import { Box } from '@mui/material';

export type PdfDefinition = PdfProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentPdf',
  },
};

export type PdfProps = {
  file: GatsbyImageProps,
  buttonLabel?: string,
  buttonVariant?: ButtonVariant,
  slug?: string,
};

const Pdf = ({
  file,
  buttonLabel,
  buttonVariant,
  slug,
}: PdfProps) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}
  >
    <Button
      variant={buttonVariant ?? 'primary'}
      slug={slug}
    >
      {buttonLabel ?? file.alt}
    </Button>
  </Box>
);

export default Pdf;
