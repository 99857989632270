import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import LinkCell from '#components/DataTable/LinkCell';
import useQueryAllTrainingPages from '#hooks/useQueryAllTrainingPages';
import useTrainingPage from '#utils/getTrainingPage';

export type CourseNameLinkProps = {
  id: number,
  name: string,
};

const CourseNameLink = (
  { id, name }:
  CourseNameLinkProps,
) => {
  const { language } = useI18next();
  const pages = useQueryAllTrainingPages(language);
  const slug = useTrainingPage(id)(pages)?.slug;

  return (
    <LinkCell
      slug={slug}
      minWidth={180}
    >
      {name}
    </LinkCell>
  );
};

export default CourseNameLink;
