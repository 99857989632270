import React from 'react';

import type { LinkDefinition } from '#contentful/Link';
import BaseHero from '#components/Hero';
import type { ContentfulAlignment } from '#hooks/useContentfulAlignment';
import type { GatsbyImageProps } from '#contentful/Image';
import type {
  ContentfulComponentDefinition,
} from '../ContentfulComponentDefinition';

export type HeroDefinition = ContentfulComponentDefinition & {
  alignment: ContentfulAlignment,
  headline: string,
  subtitle?: string,
  info?: {
    info: string
  },
  image?: GatsbyImageProps,
  ctaLink: LinkDefinition,
  id: string,
  internal: {
    type: 'ContentfulComponentHero'
  }
};

export type HeroProps = {
  content: HeroDefinition
};

const Hero = ({
  content: {
    alignment = 'left',
    headline,
    subtitle,
    info,
    image,
  },
}: HeroProps) => (
  <BaseHero
    alignment={alignment}
    headline={headline}
    subtitle={subtitle}
    info={info?.info}
    image={image}
    fontColor="implightblue100"
  />
);

export default Hero;
