import React from 'react';
import { first } from 'lodash/fp';

import CardHolder from '#components/CardHolder';
import type { LinkDefinition } from '#contentful/Link';
import type { AnyContentfulCardDefinition } from '#contentful/ContentfulCard';
import type { CardHolderListingOptions } from '#components/CardHolder';
import useQueryAllBlogs from '#hooks/useQueryAllBlogs';
import useQueryAllCourseCards from '#hooks/useQueryAllCourseCards';
import useQueryAllWebinars from '#hooks/useQueryAllWebinars';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';

export type SuggestedContentDefinition = ContentfulComponentDefinition & {
  heading?: string,
  headingVariant?: 'h2' | 'h3',
  text?: string,
  linkToMoreContent?: LinkDefinition,
  cards: AnyContentfulCardDefinition[],
  listingOptions?: CardHolderListingOptions,
  id?: string,
  internal: {
    type: 'ContentfulComponentSuggestedContent'
  }
};

export type SuggestedContentProps = {
  content: SuggestedContentDefinition
};

const SuggestedContent = ({
  content,
}: SuggestedContentProps) => {
  const {
    cards,
    listingOptions,
  } = content;

  const cardsPerRow = listingOptions?.cardsPerRow ?? 3;
  const rowsPerGroup = listingOptions?.rowsPerGroup ?? 1;

  const allBlogs = useQueryAllBlogs();
  const allWebinars = useQueryAllWebinars();
  const allCourses = useQueryAllCourseCards();

  if (listingOptions?.autofill) {
    //  if there aren't enough cards,
    //  then autofill the latest cards based on type,
    //  but only for certain types
    const missingCards = (cardsPerRow * rowsPerGroup) - cards.length;

    if (missingCards > 0) {
      const firstCard = first(cards)!;

      switch (firstCard.internal?.type) {
        case 'ContentfulComponentBlogCard':
        case 'ContentfulPageTemplateBlogPost': {
          for (let i = 0; i < missingCards; i += 1) {
            cards.push(allBlogs[i] as AnyContentfulCardDefinition);
          }
          break;
        }
        case 'ContentfulComponentTrainingCourseCard':
        case 'ContentfulComponentTrainingCourse': {
          for (let i = 0; i < missingCards; i += 1) {
            cards.push(allCourses[i] as AnyContentfulCardDefinition);
          }
          break;
        }
        case 'ContentfulComponentWebinar': {
          for (let i = 0; i < missingCards; i += 1) {
            cards.push(allWebinars[i] as AnyContentfulCardDefinition);
          }
          break;
        }
        default:
          throw new Error(`Unexpected type in Suggested Content: ${JSON.stringify(firstCard)}`);
      }
    }
  }

  return (
    <CardHolder
      {...content}
      {...listingOptions}
    />
  );
};

export default SuggestedContent;
