import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import Layout from './src/components/Layout';
import './src/styles/index.scss';
import './src/styles/tailwind-index.css';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (element);

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  /**
   * The nested use of React.cloneElement is a way to maintain the hierarchical structure of
   * the implicit context providers that Gatsby and gatsby-plugin-react-i18next add to the
   * app. This structure ensures that the i18next context (I18nextProvider and
   * I18nextContext.Provider) wraps around our Layout component.
   *
   * - The outermost clone maintains the I18nextProvider context.
   * - The inner clone represents the I18nextContext.Provider.
   *
   * By following this hierarchy, it ensures that any i18next-related features (like
   * the language dropdown selector) used inside the Layout or its children have access
   * to the correct translation context.
   */
  const newElement = React.cloneElement(
    element, // I18nextProvider
    element.props,
    React.cloneElement(
      element.props.children, // I18nextContext.Provider
      element.props.children?.props,
      React.createElement(
        Layout,
        undefined,
        element.props.children?.props.children,
      ),
    ),
  );

  return newElement;
};
