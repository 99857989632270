import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import type { GatsbyImageProps } from '#contentful/Image';
import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import useResizeImage from '#hooks/useResizeImage';

export type ImageCollageDefinition = ContentfulComponentDefinition & {
  desktop: GatsbyImageProps,
  mobile: GatsbyImageProps,
  internal: {
    type: 'ContentfulComponentImageCollage'
  }
};

export type ImageCollageProps = {
  content: ImageCollageDefinition
};

const ImageCollage = ({
  content: {
    desktop,
    mobile,
  },
}: ImageCollageProps) => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('md'));
  const isTablet = useMediaQuery(breakpoints.between('sm', 'md'));

  const dynamicDesktopImage = useResizeImage(desktop, 1280);
  const dynamicTabletImage = useResizeImage(mobile, 900);
  const dynamicMobileImage = useResizeImage(mobile, 600);

  if (isDesktop) {
    return (
      <GatsbyImage
        loading="lazy"
        image={dynamicDesktopImage}
        alt={desktop.alt}
      />
    );
  } if (isTablet) {
    return (
      <GatsbyImage
        loading="lazy"
        style={{ margin: '0 10% 8% 10%' }}
        image={dynamicTabletImage}
        alt={desktop.alt}
      />
    );
  }
  return (
    <GatsbyImage
      loading="lazy"
      image={dynamicMobileImage}
      alt={mobile.alt}
    />
  );
};

export default ImageCollage;
