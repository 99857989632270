import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';

import type {
  ContentfulComponentDefinition,
} from '#components/ContentfulComponent';
import type { AnimatedTextDefinition } from '#contentful/AnimatedText';
import AnimatedText from '#contentful/AnimatedText';

export type StatCardDefinition = ContentfulComponentDefinition & {
  title: string,
  value: number,
  isPercentage: boolean,
  animatedText: AnimatedTextDefinition
  internal: {
    type: 'ContentfulComponentStatisticsCard'
  }
};

export type StatCardProps = {
  content: StatCardDefinition
};

const StatCard = ({
  content: {
    title,
    value,
    isPercentage = false,
    animatedText,
  },
}: StatCardProps) => {
  const { palette } = useTheme();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      mx="auto"
    >
      <Grid item>
        {animatedText ? (
          <AnimatedText {...animatedText} />
        ) : (
          <Typography
            variant="statistic"
            sx={{ color: palette.color?.impgreen500 }}
          >
            {value.toLocaleString('en-US')}
            {isPercentage ? '%' : ''}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          sx={{ color: palette.color?.impgray700 }}
        >
          <b>
            {title}
          </b>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StatCard;
