import React from 'react';
import { Box, styled } from '@mui/material';

import BaseButton from '#components/Button';
import type { LinkDefinition } from '#contentful/Link';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';

export type TextButtonDefinition = ContentfulComponentDefinition & {
  title: string,
  link: LinkDefinition,
  color: {
    value: string
  },
  alignment: string
  id: string,
  internal: {
    type: 'ContentfulComponentTextButton',
  }
};

export type ContentfulComponentTextButtonProps = {
  content: TextButtonDefinition,
  className?: string,
};

/**
 * Contentful Content Model Name: "Component: Text Button"
 *
 * Contentful Button Settings: https://app.contentful.com/spaces/0vvalmm98slw/content_types/componentTextButton/fields
 */
const TextButton = ({
  content: {
    alignment,
    color,
    link,
    title,
  },
  className,
}: ContentfulComponentTextButtonProps) => (
  <Box
    className={className}
    sx={{
      alignItems: alignment,
      textAlign: alignment,
    }}
  >
    <BaseButton
      variant="secondary"
      href={link?.href}
      slug={link?.page?.slug}
      anchorTag={link?.anchorTag?.customAnchorTag
          || link?.anchorTag?.id
          || link?.manualAnchorTag}
      fontColor={color?.value}
    >
      {title}
    </BaseButton>
  </Box>
);

export default styled(TextButton)({});
