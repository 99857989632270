import React from 'react';

const OpenQuoteMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="138.082"
    height="115.819"
    opacity="66%"
    viewBox="0 0 138.082 115.819"
  >
    <path
      d="M60.868-405.539,39.66-348.524a30.175,30.175,0,0,1,14.942,9.64q6.266,7.23,6.266,19.28,0,13.014-8.917,21.449A30.1,30.1,0,0,1,30.5-289.72q-13.5,0-21.931-8.917t-8.435-25.3a58.467,58.467,0,0,1,2.169-15.906Q4.474-347.56,7.848-357.2l26.028-48.339Zm77.35,0L117.01-348.524a30.175,30.175,0,0,1,14.942,9.64q6.266,7.23,6.266,19.28,0,13.014-8.917,21.449a30.1,30.1,0,0,1-21.449,8.435q-13.5,0-21.931-8.917t-8.435-25.3a58.467,58.467,0,0,1,2.169-15.906Q81.824-347.56,85.2-357.2l26.028-48.339Z"
      transform="translate(-0.136 405.539)"
      fill="#d7d7d799"
    />
  </svg>
);

export default OpenQuoteMark;
