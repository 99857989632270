import React from 'react';
import type { PropsWithChildren } from 'react';
import { Grid, useTheme } from '@mui/material';

import Footer from './Footer';
import Header from './Header';

export type LayoutProps = PropsWithChildren<{}>;

const Layout = ({
  children,
}: LayoutProps) => {
  const { palette } = useTheme();

  return (
    <Grid
      container
      direction="column"
      maxWidth="100vw"
    >
      <Grid item>
        <Header />
      </Grid>

      <Grid item component="main" className="main" sx={{ overflowX: 'clip' }}>
        {children}
      </Grid>

      <Grid
        item
        sx={{ backgroundColor: palette.color?.impgray700 }}
      >
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Layout;
