import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import type { RichTextOptions } from '#components/RichText';
import { renderTypography } from '#components/RichText';
import useComposePage from '#hooks/useComposePage';

import { LegalLink } from './_FooterLink';

const defaults: RichTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: renderTypography('caption'),
    [INLINES.HYPERLINK]: ({ data }: any, children: any) => {
      const { uri } = data;

      return (
        <LegalLink uri={uri}>
          {children}
        </LegalLink>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: ({ data, content }: any) => {
      const { slug } = useComposePage(data?.target?.sys?.id)!;

      return (
        <LegalLink uri={slug}>
          {content[0].value}
        </LegalLink>
      );
    },
  },
};

export default defaults;
