import React from 'react';

const TikTokIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    width="40"
    height="40"
    className="enable-background"
    xmlSpace="preserve"
  >
    <g fillRule="evenodd" clipRule="evenodd" fill="#edeeee">
      <path
        d="M29.3 15.8c-1.1 0-2.2-.4-3-1-1-.7-1.6-1.8-1.9-3-.1-.3-.1-.6-.1-.9h-3.2v13.5c0
        1.3-.8 2.4-2 2.7-.3.1-.7.2-1.1.1-.5 0-.9-.2-1.3-.4-.8-.5-1.4-1.4-1.4-2.4 0-1.6
        1.3-2.9 2.9-2.9.3 0 .6.1.9.1v-3.3c-.3 0-.6-.1-.9-.1-1.8 0-3.4.7-4.6 2.1-.9 1-1.4
        2.3-1.5 3.6-.1 1.8.5 3.4 1.8 4.7.2.2.4.3.6.5 1.1.8 2.4 1.3 3.8 1.3.3 0 .6 0 .9-.1
        1.3-.2 2.5-.8 3.4-1.7 1.2-1.1 1.8-2.7 1.8-4.3v-7.2c.5.4 1.2.8 1.8 1 1 .4 2.1.6 3.2.6v-2.3l-.1-.6z"
      />
    </g>
  </svg>
);

export default TikTokIcon;
