import { useEffect, useState } from 'react';

import getRegionCode from '#services/geolocation';
import type { ArloRegionCodes } from '#services/arlo';

export type DeviceRegion = ArloRegionCodes | null;

export default (): DeviceRegion => {
  const [
    deviceRegion,
    setDeviceRegion,
  ] = useState<ArloRegionCodes | null>(null);

  useEffect(() => {
    getRegionCode().then(
      (device) => setDeviceRegion(device),
    );
  }, []);

  return deviceRegion;
};
