import type { PropsWithChildren } from 'react';
import React from 'react';
import type { BoxProps } from '@mui/material';
import { Box, Grid } from '@mui/material';

import type { ResponsiveJustifyContent } from '#hooks/useResponsiveJustifyContent';
import type { ResponsiveAlignItems } from '#hooks/useResponsiveAlignItems';
import type { SpacingValue } from '#components/Layout/theme/spacing.theme';
import { spacing } from '#components/Layout/theme/spacing.theme';
import { GatsbyImage } from 'gatsby-plugin-image';
import type { GatsbyImageProps } from '#contentful/Image';

export type SectionColumnProps = Omit<BoxProps, 'alignItems' | 'justifyContent'>
& PropsWithChildren<{
  justifyContent?: ResponsiveJustifyContent,
  alignItems?: ResponsiveAlignItems,
  pt?: SpacingValue,
  pb?: SpacingValue,
  pr?: SpacingValue,
  pl?: SpacingValue,
  color?: {
    value: string,
  },
  image?: GatsbyImageProps,
}>;

const SectionColumn = ({
  justifyContent,
  alignItems,
  children,
  pt,
  pb,
  pr,
  pl,
  color,
  image,
}: SectionColumnProps) => {
  const imageData = image?.gatsbyImageData;

  return (
    <Box
      pt={spacing(pt)}
      pb={spacing(pb)}
      pr={spacing(pr)}
      pl={spacing(pl)}
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        alignItems,
        justifyContent,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: color?.value,
          zIndex: -1,
          '::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: color?.value,
            zIndex: -1,
          },
        }}
      >
        {imageData && (
        <GatsbyImage
          image={imageData}
          loading="lazy"
          alt="Background Image"
          style={{ position: 'relative', width: '100%', height: '100%' }}
        />
        )}
      </Box>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        justifyContent={justifyContent}
        alignItems={alignItems}
        rowSpacing={1}
      >
        {children}
      </Grid>
    </Box>
  );
};

export default SectionColumn;
