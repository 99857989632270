import {
  flow,
  contains,
  first,
  map,
  find,
} from 'lodash/fp';

import useQueryAllLocationPages from '../components/Listing/JobTable/_useQueryAllLocationPages';

export type ContentfulLocationPage = {
  name: string,
  pageTemplate: {
    composePage: {
      slug: string,
    }[],
  }[],
};

export type FlatLocationPage = {
  name: string,
  slug: string,
};

export default (location: string): FlatLocationPage => flow(
  map(({ name, pageTemplate }: ContentfulLocationPage) => ({
    name,
    slug: pageTemplate?.[0]?.composePage?.[0]?.slug,
  })),
  find(({ name }: FlatLocationPage) => (
    contains(first(location.split(',')), name)
  )),
)(useQueryAllLocationPages()) as FlatLocationPage;
