import React, { useCallback, useState } from 'react';
import {
  IconButton,
  SwipeableDrawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItemText,
  ListItem,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ExpandMore,
} from '@mui/icons-material';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import loadable from '@loadable/component';

import type { NavMenuCategoryProps } from '#components/NavMenuCategory';
import NavMenuLink from '#components/NavMenuLink';
import SmartLink from '#components/SmartLink';

import { langs } from '#components/LanguageSelector';

const PrimaryLogo = loadable(() => import('#components/PrimaryLogo'));

export type NavBarMobileProps = {
  menu: NavMenuCategoryProps[],
};

const NavBarMobile = ({ menu }: NavBarMobileProps) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (openDrawer: boolean) => (event: any) => {
    if (event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(openDrawer);
  };

  const [expanded, setExpanded] = useState<string | false>(false);
  const langId = 'mobile-language-selector';

  const { language, languages, originalPath } = useI18next();

  const handleChange = useCallback(
    (panel: string) => (
      _event: React.SyntheticEvent,
      newExpanded: boolean,
    ) => {
      setExpanded(newExpanded ? panel : false);
    },
    [],
  );

  const isLanguageEnabled = process.env.GATSBY_ENABLE_LANG === 'true';

  return (
    <>
      <div className="w-full mb-2 flex justify-between items-center">
        <div>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            className="mx-0"
          >
            <MenuIcon className="text-impgreen500" />
          </IconButton>
        </div>

        <div>
          <SmartLink slug="/" ariaLabel="Improving Home Page">
            <PrimaryLogo />
          </SmartLink>
        </div>

        <div />
      </div>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          className: 'w-4/5 bg-impblue400',
        }}
      >
        <div className="flex flex-col items-stretch gap-8">
          <div className="p-4">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon className="text-impgreen500" />
            </IconButton>
          </div>

          {isLanguageEnabled && (
          <div>
            <Accordion
              id={langId}
              elevation={0}
              expanded={expanded === langId}
              onChange={handleChange(langId)}
              className="bg-inherit"
            >
              <AccordionSummary
                expandIcon={<ExpandMore className="text-impgreen500" />}
                aria-controls="language-selector"
                className="px-4"
              >
                <LanguageIcon color="secondary" className="px-2" />
                <Typography
                  variant="subtitle1"
                  color="white"
                >
                  {langs[language]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="bg-impblue300">
                {languages?.map((lang) => (
                  <ListItem key={lang}>
                    <Link
                      to={originalPath}
                      language={lang}
                      onClick={toggleDrawer(false)}
                      className="no-underline"
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    >
                      <ListItemText>
                        <Typography
                          variant="subtitle1"
                          color="white"
                        >
                          {langs[lang]}
                        </Typography>
                      </ListItemText>
                    </Link>
                  </ListItem>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
          )}

          <div data-testid="mobile-nav-menu">
            {menu?.map(({
              id,
              label,
              slug,
              subItems,
            }: NavMenuCategoryProps) => (!subItems.length
              ? (
                <div
                  key={id}
                  // NOTE: items with subItems are 72px tall. Is this intentional?
                  className="h-[71px] flex items-center"
                >
                  <NavMenuLink
                    label={label}
                    slug={slug}
                    onClick={toggleDrawer(false)}
                    color="white"
                    buttonClasses="box-border h-auto ml-4 w-max"
                    isMobile
                  />
                </div>
              ) : (
                <Accordion
                  key={id}
                  elevation={0}
                  expanded={expanded === id}
                  onChange={handleChange(id)}
                  className="bg-inherit before:hidden"
                >
                  <AccordionSummary
                    className="px-4"
                    expandIcon={<ExpandMore className="text-impgreen500" />}
                    aria-controls={`${label}-nav-link`}
                  >
                    <NavMenuLink
                      key={id}
                      label={label}
                      slug={slug}
                      onClick={toggleDrawer(false)}
                      color="white"
                    />
                  </AccordionSummary>
                  <AccordionDetails className="bg-impblue300">
                    {subItems.map(
                      (subItem) => (
                        <NavMenuLink
                          key={subItem.id}
                          label={subItem.label}
                          slug={subItem.slug}
                          onClick={toggleDrawer(false)}
                          color="white"
                        />
                      ),
                    )}
                  </AccordionDetails>
                </Accordion>
              )))}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default NavBarMobile;
