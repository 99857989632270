import React from 'react';

import type { TextDefinition } from '#contentful/Text';
import type { LinkDefinition } from '#contentful/Link';
import type { AnySectionDefinition, BackgroundDefinition } from '#components/Section';
import { Box, useTheme } from '@mui/material';
import Link from '#contentful/Link';
import Text from '#contentful/Text';
import Section from '#components/Section';
import type {
  ContentfulComponentDefinition,
} from '../ContentfulComponentDefinition';

export type ExpandedNavigationDefinition = ContentfulComponentDefinition & {
  title: TextDefinition,
  background: BackgroundDefinition,
  description: TextDefinition,
  links: LinkDefinition[],
  content: AnySectionDefinition,
  internal: {
    type: 'ContentfulSubComponentExpandedNavigation'
  }
};

export type ExpandedNavigationProps = {
  content: ExpandedNavigationDefinition
};

const ExpandedNavigation = ({
  content: {
    title,
    description,
    links,
    content,
    background,
  },
}: ExpandedNavigationProps) => {
  const { palette } = useTheme();

  return (
    <Box
      style={{
        backgroundColor: palette?.background?.default,
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        margin: '0 40px',
        boxShadow: '2px 4px 8px rgba(0,0,0,0.2)',
      }}
    >
      <Box
        padding={4}
        style={{
          background: background.color?.value,
        }}
      >
        <Text
          content={title}
        />
        <Text
          content={description}
        />
        {links.map(
          (link) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              key={link.id}
              content={link}
            />
          ),
        )}
      </Box>
      <Box
        padding={4}
      >
        <Section
          section={content}
        />
      </Box>
    </Box>
  );
};

export default ExpandedNavigation;
