import React from 'react';
import SectionSettings from '#components/Section/SectionSettings';
import type { SuggestedContentDefinition } from '#contentful/SuggestedContent/SuggestedContent';
import SuggestedContent from '#contentful/SuggestedContent/SuggestedContent';
import useSectionSpacing from '#hooks/useSectionSpacing';
import type { SuggestedContentTabsTabDefinition } from '../SuggestedContentTabsTab';

export type SuggestedContentTabsGroupProps = {
  id: string,
  tab: SuggestedContentTabsTabDefinition,
};

export type SuggestedContentTabsContentDefinition = SuggestedContentDefinition;

const SuggestedContentTabsGroup = ({
  id,
  tab,
}: SuggestedContentTabsGroupProps) => {
  //  Calculate section settings
  const responsiveSectionSpacing = useSectionSpacing(
    {
      ...tab?.spacingOptions,
    },
    {
      ptxl: '36px',
      ptlg: '36px',
      ptmd: '36px',
      ptsm: '36px',
      ptxs: '36px',
    },
  );

  return (
    <SectionSettings key={id} {...responsiveSectionSpacing}>
      <SuggestedContent content={tab?.content} />
    </SectionSettings>
  );
};

export default SuggestedContentTabsGroup;
