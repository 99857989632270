import React from 'react';

const WorldMap = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="140" height="80" viewBox="0 0 916 471">
    <g fill="#a7a7a9">
      <circle cx="255.04" cy="18.68" r="6.68" />
      <circle cx="275.07" cy="18.68" r="6.68" />
      <circle cx="295.1" cy="18.68" r="6.68" />
      <circle cx="315.13" cy="18.68" r="6.68" />
      <circle cx="335.16" cy="18.68" r="6.68" />
      <circle cx="355.19" cy="18.68" r="6.68" />
      <circle cx="475.37" cy="18.68" r="6.68" />
      <circle cx="495.4" cy="18.68" r="6.68" />
      <circle cx="615.58" cy="18.68" r="6.68" />
      <circle cx="635.61" cy="18.68" r="6.68" />
      <circle cx="174.92" cy="36.68" r="6.68" />
      <circle cx="194.95" cy="36.68" r="6.68" />
      <circle cx="214.98" cy="36.68" r="6.68" />
      <circle cx="235.01" cy="36.68" r="6.68" />
      <circle cx="255.04" cy="36.68" r="6.68" />
      <circle cx="275.07" cy="36.68" r="6.68" />
      <circle cx="315.13" cy="36.68" r="6.68" />
      <circle cx="335.16" cy="36.68" r="6.68" />
      <circle cx="355.19" cy="36.68" r="6.68" />
      <circle cx="375.22" cy="36.68" r="6.68" />
      <circle cx="395.25" cy="36.68" r="6.68" />
      <circle cx="495.4" cy="36.68" r="6.68" />
      <circle cx="575.52" cy="36.68" r="6.68" />
      <circle cx="595.55" cy="36.68" r="6.68" />
      <circle cx="615.58" cy="36.68" r="6.68" />
      <circle cx="635.61" cy="36.68" r="6.68" />
      <circle cx="655.64" cy="36.68" r="6.68" />
      <circle cx="675.67" cy="36.68" r="6.68" />
      <circle cx="695.7" cy="36.68" r="6.68" />
      <circle cx="715.73" cy="36.68" r="6.68" />
      <circle cx="735.76" cy="36.68" r="6.68" />
      <circle cx="755.79" cy="36.68" r="6.68" />
      <circle cx="54.74" cy="54.68" r="6.68" />
      <circle cx="74.77" cy="54.68" r="6.68" />
      <circle cx="94.8" cy="54.68" r="6.68" />
      <circle cx="114.83" cy="54.68" r="6.68" />
      <circle cx="134.86" cy="54.68" r="6.68" />
      <circle cx="154.89" cy="54.68" r="6.68" />
      <circle cx="174.92" cy="54.68" r="6.68" />
      <circle cx="194.95" cy="54.68" r="6.68" />
      <circle cx="214.98" cy="54.68" r="6.68" />
      <circle cx="255.04" cy="54.68" r="6.68" />
      <circle cx="275.07" cy="54.68" r="6.68" />
      <circle cx="315.13" cy="54.68" r="6.68" />
      <circle cx="335.16" cy="54.68" r="6.68" />
      <circle cx="355.19" cy="54.68" r="6.68" />
      <circle cx="375.22" cy="54.68" r="6.68" />
      <circle cx="475.37" cy="54.68" r="6.68" />
      <circle cx="495.4" cy="54.68" r="6.68" />
      <circle cx="515.43" cy="54.68" r="6.68" />
      <circle cx="535.46" cy="54.68" r="6.68" />
      <circle cx="555.49" cy="54.68" r="6.68" />
      <circle cx="575.52" cy="54.68" r="6.68" />
      <circle cx="595.55" cy="54.68" r="6.68" />
      <circle cx="615.58" cy="54.68" r="6.68" />
      <circle cx="635.61" cy="54.68" r="6.68" />
      <circle cx="655.64" cy="54.68" r="6.68" />
      <circle cx="675.67" cy="54.68" r="6.68" />
      <circle cx="695.7" cy="54.68" r="6.68" />
      <circle cx="715.73" cy="54.68" r="6.68" />
      <circle cx="735.76" cy="54.68" r="6.68" />
      <circle cx="755.79" cy="54.68" r="6.68" />
      <circle cx="775.82" cy="54.68" r="6.68" />
      <circle cx="795.85" cy="54.68" r="6.68" />
      <circle cx="815.88" cy="54.68" r="6.68" />
      <circle cx="835.91" cy="54.68" r="6.68" />
      <circle cx="855.94" cy="54.68" r="6.68" />
      <circle cx="875.97" cy="54.68" r="6.68" />
      <circle cx="34.71" cy="72.68" r="6.68" />
      <circle cx="54.74" cy="72.68" r="6.68" />
      <circle cx="74.77" cy="72.68" r="6.68" />
      <circle cx="94.8" cy="72.68" r="6.68" />
      <circle cx="114.83" cy="72.68" r="6.68" />
      <circle cx="134.86" cy="72.68" r="6.68" />
      <circle cx="154.89" cy="72.68" r="6.68" />
      <circle cx="174.92" cy="72.68" r="6.68" />
      <circle cx="194.95" cy="72.68" r="6.68" />
      <circle cx="315.13" cy="72.68" r="6.68" />
      <circle cx="435.31" cy="72.68" r="6.68" />
      <circle cx="455.34" cy="72.68" r="6.68" />
      <circle cx="475.37" cy="72.68" r="6.68" />
      <circle cx="495.4" cy="72.68" r="6.68" />
      <circle cx="515.43" cy="72.68" r="6.68" />
      <circle cx="535.46" cy="72.68" r="6.68" />
      <circle cx="555.49" cy="72.68" r="6.68" />
      <circle cx="575.52" cy="72.68" r="6.68" />
      <circle cx="595.55" cy="72.68" r="6.68" />
      <circle cx="615.58" cy="72.68" r="6.68" />
      <circle cx="635.61" cy="72.68" r="6.68" />
      <circle cx="655.64" cy="72.68" r="6.68" />
      <circle cx="675.67" cy="72.68" r="6.68" />
      <circle cx="695.7" cy="72.68" r="6.68" />
      <circle cx="715.73" cy="72.68" r="6.68" />
      <circle cx="735.76" cy="72.68" r="6.68" />
      <circle cx="755.79" cy="72.68" r="6.68" />
      <circle cx="775.82" cy="72.68" r="6.68" />
      <circle cx="795.85" cy="72.68" r="6.68" />
      <circle cx="815.88" cy="72.68" r="6.68" />
      <circle cx="835.91" cy="72.68" r="6.68" />
      <circle cx="855.94" cy="72.68" r="6.68" />
      <circle cx="14.68" cy="90.68" r="6.68" />
      <circle cx="34.71" cy="90.68" r="6.68" />
      <circle cx="94.8" cy="90.68" r="6.68" />
      <circle cx="114.83" cy="90.68" r="6.68" />
      <circle cx="134.86" cy="90.68" r="6.68" />
      <circle cx="154.89" cy="90.68" r="6.68" />
      <circle cx="174.92" cy="90.68" r="6.68" />
      <circle cx="194.95" cy="90.68" r="6.68" />
      <circle cx="214.98" cy="90.68" r="6.68" />
      <circle cx="235.01" cy="90.68" r="6.68" />
      <circle cx="255.04" cy="90.68" r="6.68" />
      <circle cx="275.07" cy="90.68" r="6.68" />
      <circle cx="415.28" cy="90.68" r="6.68" />
      <circle cx="435.31" cy="90.68" r="6.68" />
      <circle cx="475.37" cy="90.68" r="6.68" />
      <circle cx="495.4" cy="90.68" r="6.68" />
      <circle cx="515.43" cy="90.68" r="6.68" />
      <circle cx="535.46" cy="90.68" r="6.68" />
      <circle cx="555.49" cy="90.68" r="6.68" />
      <circle cx="575.52" cy="90.68" r="6.68" />
      <circle cx="595.55" cy="90.68" r="6.68" />
      <circle cx="615.58" cy="90.68" r="6.68" />
      <circle cx="635.61" cy="90.68" r="6.68" />
      <circle cx="655.64" cy="90.68" r="6.68" />
      <circle cx="675.67" cy="90.68" r="6.68" />
      <circle cx="695.7" cy="90.68" r="6.68" />
      <circle cx="715.73" cy="90.68" r="6.68" />
      <circle cx="735.76" cy="90.68" r="6.68" />
      <circle cx="755.79" cy="90.68" r="6.68" />
      <circle cx="775.82" cy="90.68" r="6.68" />
      <circle cx="795.85" cy="90.68" r="6.68" />
      <circle cx="855.94" cy="90.68" r="6.68" />
      <circle cx="94.8" cy="108.68" r="6.68" />
      <circle cx="114.83" cy="108.68" r="6.68" />
      <circle cx="134.86" cy="108.68" r="6.68" />
      <circle cx="154.89" cy="108.68" r="6.68" />
      <circle cx="174.92" cy="108.68" r="6.68" />
      <circle cx="194.95" cy="108.68" r="6.68" />
      <circle cx="214.98" cy="108.68" r="6.68" />
      <circle cx="235.01" cy="108.68" r="6.68" />
      <circle cx="255.04" cy="108.68" r="6.68" />
      <circle cx="275.07" cy="108.68" r="6.68" />
      <circle cx="295.1" cy="108.68" r="6.68" />
      <circle cx="415.28" cy="108.68" r="6.68" />
      <circle cx="435.31" cy="108.68" r="6.68" />
      <circle cx="455.34" cy="108.68" r="6.68" />
      <circle cx="475.37" cy="108.68" r="6.68" />
      <circle cx="495.4" cy="108.68" r="6.68" />
      <circle cx="515.43" cy="108.68" r="6.68" />
      <circle cx="535.46" cy="108.68" r="6.68" />
      <circle cx="555.49" cy="108.68" r="6.68" />
      <circle cx="575.52" cy="108.68" r="6.68" />
      <circle cx="595.55" cy="108.68" r="6.68" />
      <circle cx="615.58" cy="108.68" r="6.68" />
      <circle cx="635.61" cy="108.68" r="6.68" />
      <circle cx="655.64" cy="108.68" r="6.68" />
      <circle cx="675.67" cy="108.68" r="6.68" />
      <circle cx="695.7" cy="108.68" r="6.68" />
      <circle cx="715.73" cy="108.68" r="6.68" />
      <circle cx="735.76" cy="108.68" r="6.68" />
      <circle cx="755.79" cy="108.68" r="6.68" />
      <circle cx="775.82" cy="108.68" r="6.68" />
      <circle cx="795.85" cy="108.68" r="6.68" />
      <circle cx="815.88" cy="108.68" r="6.68" />
      <circle cx="94.8" cy="126.68" r="6.68" />
      <circle cx="114.83" cy="126.68" r="6.68" />
      <circle cx="134.86" cy="126.68" r="6.68" />
      <circle cx="154.89" cy="126.68" r="6.68" />
      <circle cx="174.92" cy="126.68" r="6.68" />
      <circle cx="194.95" cy="126.68" r="6.68" />
      <circle cx="214.98" cy="126.68" r="6.68" />
      <circle cx="235.01" cy="126.68" r="6.68" />
      <circle cx="435.31" cy="126.68" r="6.68" />
      <circle cx="455.34" cy="126.68" r="6.68" />
      <circle cx="475.37" cy="126.68" r="6.68" />
      <circle cx="495.4" cy="126.68" r="6.68" />
      <circle cx="515.43" cy="126.68" r="6.68" />
      <circle cx="535.46" cy="126.68" r="6.68" />
      <circle cx="555.49" cy="126.68" r="6.68" />
      <circle cx="595.55" cy="126.68" r="6.68" />
      <circle cx="615.58" cy="126.68" r="6.68" />
      <circle cx="635.61" cy="126.68" r="6.68" />
      <circle cx="655.64" cy="126.68" r="6.68" />
      <circle cx="675.67" cy="126.68" r="6.68" />
      <circle cx="695.7" cy="126.68" r="6.68" />
      <circle cx="715.73" cy="126.68" r="6.68" />
      <circle cx="735.76" cy="126.68" r="6.68" />
      <circle cx="755.79" cy="126.68" r="6.68" />
      <circle cx="775.82" cy="126.68" r="6.68" />
      <circle cx="795.85" cy="126.68" r="6.68" />
      <circle cx="835.91" cy="126.68" r="6.68" />
      <circle cx="94.8" cy="144.68" r="6.68" />
      <circle cx="114.83" cy="144.68" r="6.68" />
      <circle cx="134.86" cy="144.68" r="6.68" />
      <circle cx="154.89" cy="144.68" r="6.68" />
      <circle cx="174.92" cy="144.68" r="6.68" />
      <circle cx="194.95" cy="144.68" r="6.68" />
      <circle cx="214.98" cy="144.68" r="6.68" />
      <circle cx="235.01" cy="144.68" r="6.68" />
      <circle cx="415.28" cy="144.68" r="6.68" />
      <circle cx="435.31" cy="144.68" r="6.68" />
      <circle cx="495.4" cy="144.68" r="6.68" />
      <circle cx="515.43" cy="144.68" r="6.68" />
      <circle cx="555.49" cy="144.68" r="6.68" />
      <circle cx="595.55" cy="144.68" r="6.68" />
      <circle cx="615.58" cy="144.68" r="6.68" />
      <circle cx="635.61" cy="144.68" r="6.68" />
      <circle cx="655.64" cy="144.68" r="6.68" />
      <circle cx="675.67" cy="144.68" r="6.68" />
      <circle cx="695.7" cy="144.68" r="6.68" />
      <circle cx="715.73" cy="144.68" r="6.68" />
      <circle cx="735.76" cy="144.68" r="6.68" />
      <circle cx="755.79" cy="144.68" r="6.68" />
      <circle cx="775.82" cy="144.68" r="6.68" />
      <circle cx="795.85" cy="144.68" r="6.68" />
      <circle cx="835.91" cy="144.68" r="6.68" />
      <circle cx="94.8" cy="162.68" r="6.68" />
      <circle cx="114.83" cy="162.68" r="6.68" />
      <circle cx="134.86" cy="162.68" r="6.68" />
      <circle cx="154.89" cy="162.68" r="6.68" />
      <circle cx="174.92" cy="162.68" r="6.68" />
      <circle cx="194.95" cy="162.68" r="6.68" />
      <circle cx="214.98" cy="162.68" r="6.68" />
      <circle cx="435.31" cy="162.68" r="6.68" />
      <circle cx="455.34" cy="162.68" r="6.68" />
      <circle cx="535.46" cy="162.68" r="6.68" />
      <circle cx="555.49" cy="162.68" r="6.68" />
      <circle cx="575.52" cy="162.68" r="6.68" />
      <circle cx="595.55" cy="162.68" r="6.68" />
      <circle cx="615.58" cy="162.68" r="6.68" />
      <circle cx="635.61" cy="162.68" r="6.68" />
      <circle cx="655.64" cy="162.68" r="6.68" />
      <circle cx="675.67" cy="162.68" r="6.68" />
      <circle cx="695.7" cy="162.68" r="6.68" />
      <circle cx="715.73" cy="162.68" r="6.68" />
      <circle cx="735.76" cy="162.68" r="6.68" />
      <circle cx="755.79" cy="162.68" r="6.68" />
      <circle cx="775.82" cy="162.68" r="6.68" />
      <circle cx="815.88" cy="162.68" r="6.68" />
      <circle cx="835.91" cy="162.68" r="6.68" />
      <circle cx="114.83" cy="180.68" r="6.68" />
      <circle cx="134.86" cy="180.68" r="6.68" />
      <circle cx="154.89" cy="180.68" r="6.68" />
      <circle cx="174.92" cy="180.68" r="6.68" />
      <circle cx="194.95" cy="180.68" r="6.68" />
      <circle cx="214.98" cy="180.68" r="6.68" />
      <circle cx="415.28" cy="180.68" r="6.68" />
      <circle cx="435.31" cy="180.68" r="6.68" />
      <circle cx="455.34" cy="180.68" r="6.68" />
      <circle cx="475.37" cy="180.68" r="6.68" />
      <circle cx="495.4" cy="180.68" r="6.68" />
      <circle cx="515.43" cy="180.68" r="6.68" />
      <circle cx="535.46" cy="180.68" r="6.68" />
      <circle cx="555.49" cy="180.68" r="6.68" />
      <circle cx="575.52" cy="180.68" r="6.68" />
      <circle cx="595.55" cy="180.68" r="6.68" />
      <circle cx="615.58" cy="180.68" r="6.68" />
      <circle cx="635.61" cy="180.68" r="6.68" />
      <circle cx="655.64" cy="180.68" r="6.68" />
      <circle cx="675.67" cy="180.68" r="6.68" />
      <circle cx="695.7" cy="180.68" r="6.68" />
      <circle cx="715.73" cy="180.68" r="6.68" />
      <circle cx="735.76" cy="180.68" r="6.68" />
      <circle cx="755.79" cy="180.68" r="6.68" />
      <circle cx="775.82" cy="180.68" r="6.68" />
      <circle cx="795.85" cy="180.68" r="6.68" />
      <circle cx="114.83" cy="198.68" r="6.68" />
      <circle cx="134.86" cy="198.68" r="6.68" />
      <circle cx="154.89" cy="198.68" r="6.68" />
      <circle cx="214.98" cy="198.68" r="6.68" />
      <circle cx="395.25" cy="198.68" r="6.68" />
      <circle cx="415.28" cy="198.68" r="6.68" />
      <circle cx="435.31" cy="198.68" r="6.68" />
      <circle cx="455.34" cy="198.68" r="6.68" />
      <circle cx="475.37" cy="198.68" r="6.68" />
      <circle cx="495.4" cy="198.68" r="6.68" />
      <circle cx="515.43" cy="198.68" r="6.68" />
      <circle cx="535.46" cy="198.68" r="6.68" />
      <circle cx="555.49" cy="198.68" r="6.68" />
      <circle cx="575.52" cy="198.68" r="6.68" />
      <circle cx="595.55" cy="198.68" r="6.68" />
      <circle cx="635.61" cy="198.68" r="6.68" />
      <circle cx="655.64" cy="198.68" r="6.68" />
      <circle cx="675.67" cy="198.68" r="6.68" />
      <circle cx="695.7" cy="198.68" r="6.68" />
      <circle cx="715.73" cy="198.68" r="6.68" />
      <circle cx="735.76" cy="198.68" r="6.68" />
      <circle cx="755.79" cy="198.68" r="6.68" />
      <circle cx="775.82" cy="198.68" r="6.68" />
      <circle cx="134.86" cy="216.68" r="6.68" />
      <circle cx="154.89" cy="216.68" r="6.68" />
      <circle cx="174.92" cy="216.68" r="6.68" />
      <circle cx="395.25" cy="216.68" r="6.68" />
      <circle cx="415.28" cy="216.68" r="6.68" />
      <circle cx="435.31" cy="216.68" r="6.68" />
      <circle cx="455.34" cy="216.68" r="6.68" />
      <circle cx="475.37" cy="216.68" r="6.68" />
      <circle cx="495.4" cy="216.68" r="6.68" />
      <circle cx="515.43" cy="216.68" r="6.68" />
      <circle cx="535.46" cy="216.68" r="6.68" />
      <circle cx="555.49" cy="216.68" r="6.68" />
      <circle cx="575.52" cy="216.68" r="6.68" />
      <circle cx="595.55" cy="216.68" r="6.68" />
      <circle cx="655.64" cy="216.68" r="6.68" />
      <circle cx="675.67" cy="216.68" r="6.68" />
      <circle cx="695.7" cy="216.68" r="6.68" />
      <circle cx="735.76" cy="216.68" r="6.68" />
      <circle cx="755.79" cy="216.68" r="6.68" />
      <circle cx="174.92" cy="234.68" r="6.68" />
      <circle cx="194.95" cy="234.68" r="6.68" />
      <circle cx="214.98" cy="234.68" r="6.68" />
      <circle cx="235.01" cy="234.68" r="6.68" />
      <circle cx="395.25" cy="234.68" r="6.68" />
      <circle cx="415.28" cy="234.68" r="6.68" />
      <circle cx="435.31" cy="234.68" r="6.68" />
      <circle cx="455.34" cy="234.68" r="6.68" />
      <circle cx="475.37" cy="234.68" r="6.68" />
      <circle cx="495.4" cy="234.68" r="6.68" />
      <circle cx="515.43" cy="234.68" r="6.68" />
      <circle cx="535.46" cy="234.68" r="6.68" />
      <circle cx="555.49" cy="234.68" r="6.68" />
      <circle cx="575.52" cy="234.68" r="6.68" />
      <circle cx="595.55" cy="234.68" r="6.68" />
      <circle cx="675.67" cy="234.68" r="6.68" />
      <circle cx="735.76" cy="234.68" r="6.68" />
      <circle cx="755.79" cy="234.68" r="6.68" />
      <circle cx="815.88" cy="234.68" r="6.68" />
      <circle cx="214.98" cy="252.68" r="6.68" />
      <circle cx="235.01" cy="252.68" r="6.68" />
      <circle cx="255.04" cy="252.68" r="6.68" />
      <circle cx="275.07" cy="252.68" r="6.68" />
      <circle cx="395.25" cy="252.68" r="6.68" />
      <circle cx="415.28" cy="252.68" r="6.68" />
      <circle cx="435.31" cy="252.68" r="6.68" />
      <circle cx="455.34" cy="252.68" r="6.68" />
      <circle cx="475.37" cy="252.68" r="6.68" />
      <circle cx="495.4" cy="252.68" r="6.68" />
      <circle cx="515.43" cy="252.68" r="6.68" />
      <circle cx="535.46" cy="252.68" r="6.68" />
      <circle cx="555.49" cy="252.68" r="6.68" />
      <circle cx="575.52" cy="252.68" r="6.68" />
      <circle cx="735.76" cy="252.68" r="6.68" />
      <circle cx="755.79" cy="252.68" r="6.68" />
      <circle cx="795.85" cy="252.68" r="6.68" />
      <circle cx="815.88" cy="252.68" r="6.68" />
      <circle cx="194.95" cy="270.68" r="6.68" />
      <circle cx="214.98" cy="270.68" r="6.68" />
      <circle cx="235.01" cy="270.68" r="6.68" />
      <circle cx="255.04" cy="270.68" r="6.68" />
      <circle cx="275.07" cy="270.68" r="6.68" />
      <circle cx="295.1" cy="270.68" r="6.68" />
      <circle cx="455.34" cy="270.68" r="6.68" />
      <circle cx="475.37" cy="270.68" r="6.68" />
      <circle cx="495.4" cy="270.68" r="6.68" />
      <circle cx="515.43" cy="270.68" r="6.68" />
      <circle cx="535.46" cy="270.68" r="6.68" />
      <circle cx="555.49" cy="270.68" r="6.68" />
      <circle cx="755.79" cy="270.68" r="6.68" />
      <circle cx="775.82" cy="270.68" r="6.68" />
      <circle cx="795.85" cy="270.68" r="6.68" />
      <circle cx="815.88" cy="270.68" r="6.68" />
      <circle cx="835.91" cy="270.68" r="6.68" />
      <circle cx="855.94" cy="270.68" r="6.68" />
      <circle cx="875.97" cy="270.68" r="6.68" />
      <circle cx="194.95" cy="288.68" r="6.68" />
      <circle cx="214.98" cy="288.68" r="6.68" />
      <circle cx="235.01" cy="288.68" r="6.68" />
      <circle cx="255.04" cy="288.68" r="6.68" />
      <circle cx="275.07" cy="288.68" r="6.68" />
      <circle cx="295.1" cy="288.68" r="6.68" />
      <circle cx="315.13" cy="288.68" r="6.68" />
      <circle cx="475.37" cy="288.68" r="6.68" />
      <circle cx="495.4" cy="288.68" r="6.68" />
      <circle cx="515.43" cy="288.68" r="6.68" />
      <circle cx="535.46" cy="288.68" r="6.68" />
      <circle cx="555.49" cy="288.68" r="6.68" />
      <circle cx="775.82" cy="288.68" r="6.68" />
      <circle cx="875.97" cy="288.68" r="6.68" />
      <circle cx="194.95" cy="306.68" r="6.68" />
      <circle cx="214.98" cy="306.68" r="6.68" />
      <circle cx="235.01" cy="306.68" r="6.68" />
      <circle cx="255.04" cy="306.68" r="6.68" />
      <circle cx="275.07" cy="306.68" r="6.68" />
      <circle cx="295.1" cy="306.68" r="6.68" />
      <circle cx="315.13" cy="306.68" r="6.68" />
      <circle cx="475.37" cy="306.68" r="6.68" />
      <circle cx="495.4" cy="306.68" r="6.68" />
      <circle cx="515.43" cy="306.68" r="6.68" />
      <circle cx="535.46" cy="306.68" r="6.68" />
      <circle cx="555.49" cy="306.68" r="6.68" />
      <circle cx="775.82" cy="306.68" r="6.68" />
      <circle cx="875.97" cy="306.68" r="6.68" />
      <circle cx="194.95" cy="324.68" r="6.68" />
      <circle cx="214.98" cy="324.68" r="6.68" />
      <circle cx="235.01" cy="324.68" r="6.68" />
      <circle cx="255.04" cy="324.68" r="6.68" />
      <circle cx="275.07" cy="324.68" r="6.68" />
      <circle cx="295.1" cy="324.68" r="6.68" />
      <circle cx="315.13" cy="324.68" r="6.68" />
      <circle cx="475.37" cy="324.68" r="6.68" />
      <circle cx="495.4" cy="324.68" r="6.68" />
      <circle cx="515.43" cy="324.68" r="6.68" />
      <circle cx="535.46" cy="324.68" r="6.68" />
      <circle cx="555.49" cy="324.68" r="6.68" />
      <circle cx="815.88" cy="324.68" r="6.68" />
      <circle cx="835.91" cy="324.68" r="6.68" />
      <circle cx="855.94" cy="324.68" r="6.68" />
      <circle cx="875.97" cy="324.68" r="6.68" />
      <circle cx="214.98" cy="342.68" r="6.68" />
      <circle cx="235.01" cy="342.68" r="6.68" />
      <circle cx="255.04" cy="342.68" r="6.68" />
      <circle cx="275.07" cy="342.68" r="6.68" />
      <circle cx="295.1" cy="342.68" r="6.68" />
      <circle cx="315.13" cy="342.68" r="6.68" />
      <circle cx="475.37" cy="342.68" r="6.68" />
      <circle cx="495.4" cy="342.68" r="6.68" />
      <circle cx="515.43" cy="342.68" r="6.68" />
      <circle cx="535.46" cy="342.68" r="6.68" />
      <circle cx="575.52" cy="342.68" r="6.68" />
      <circle cx="795.85" cy="342.68" r="6.68" />
      <circle cx="815.88" cy="342.68" r="6.68" />
      <circle cx="835.91" cy="342.68" r="6.68" />
      <circle cx="855.94" cy="342.68" r="6.68" />
      <circle cx="875.97" cy="342.68" r="6.68" />
      <circle cx="235.01" cy="360.68" r="6.68" />
      <circle cx="255.04" cy="360.68" r="6.68" />
      <circle cx="275.07" cy="360.68" r="6.68" />
      <circle cx="295.1" cy="360.68" r="6.68" />
      <circle cx="475.37" cy="360.68" r="6.68" />
      <circle cx="495.4" cy="360.68" r="6.68" />
      <circle cx="515.43" cy="360.68" r="6.68" />
      <circle cx="535.46" cy="360.68" r="6.68" />
      <circle cx="575.52" cy="360.68" r="6.68" />
      <circle cx="795.85" cy="360.68" r="6.68" />
      <circle cx="815.88" cy="360.68" r="6.68" />
      <circle cx="835.91" cy="360.68" r="6.68" />
      <circle cx="855.94" cy="360.68" r="6.68" />
      <circle cx="875.97" cy="360.68" r="6.68" />
      <circle cx="235.01" cy="378.68" r="6.68" />
      <circle cx="255.04" cy="378.68" r="6.68" />
      <circle cx="275.07" cy="378.68" r="6.68" />
      <circle cx="475.37" cy="378.68" r="6.68" />
      <circle cx="495.4" cy="378.68" r="6.68" />
      <circle cx="515.43" cy="378.68" r="6.68" />
      <circle cx="495.4" cy="396.68" r="6.68" />
      <circle cx="515.43" cy="396.68" r="6.68" />
      <circle cx="795.85" cy="378.68" r="6.68" />
      <circle cx="815.88" cy="378.68" r="6.68" />
      <circle cx="855.94" cy="378.68" r="6.68" />
      <circle cx="875.97" cy="378.68" r="6.68" />
      <circle cx="894.97" cy="288.68" r="6.68" />
      <circle cx="894.97" cy="342.68" r="6.68" />
      <circle cx="894.97" cy="360.68" r="6.68" />
      <circle cx="894.97" cy="378.68" r="6.68" />
      <circle cx="235.01" cy="396.68" r="6.68" />
      <circle cx="255.04" cy="396.68" r="6.68" />
      <circle cx="275.07" cy="396.68" r="6.68" />
      <circle cx="875.97" cy="396.68" r="6.68" />
      <circle cx="235.01" cy="414.68" r="6.68" />
      <circle cx="255.04" cy="414.68" r="6.68" />
      <circle cx="235.01" cy="432.68" r="6.68" />
      <circle cx="255.04" cy="432.68" r="6.68" />
      <circle cx="235.01" cy="450.68" r="6.68" />
    </g>
  </svg>

);

export default WorldMap;
