import {
  flow,
  keys,
  includes,
  toLower,
} from 'lodash/fp';

export type VerticalContentfulAlignment = 'top' | 'center' | 'bottom' | 'full height';
export type HorizontalContentfulAlignment = 'full width' | 'left' | 'center' | 'right';

export type ContentfulAlignment = VerticalContentfulAlignment | HorizontalContentfulAlignment;

type VerticalFlexAlignment = 'flex-start' | 'center' | 'flex-end' | 'stretch';
type HorizontalFlexAlignment = 'flex-start' | 'center' | 'flex-end' | 'stretch';

const verticalMappings: { [k in VerticalContentfulAlignment]: VerticalFlexAlignment } = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  'full height': 'stretch',
};

const isVertical = (
  alignment?: ContentfulAlignment,
): alignment is VerticalContentfulAlignment => flow(
  keys,
  includes(alignment),
)(verticalMappings);
const mapVertical = (alignment: VerticalContentfulAlignment): VerticalFlexAlignment => (
  verticalMappings[alignment]
);

const horizontalMappings: { [k in HorizontalContentfulAlignment]: HorizontalFlexAlignment } = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  'full width': 'stretch',
};

const isHorizontal = (
  alignment?: ContentfulAlignment,
): alignment is HorizontalContentfulAlignment => flow(
  keys,
  includes(alignment),
)(horizontalMappings);
const mapHorizontal = (alignment: HorizontalContentfulAlignment): HorizontalFlexAlignment => (
  horizontalMappings[alignment]
);

type DynamicFlexAlignment<T extends ContentfulAlignment> =
  T extends VerticalContentfulAlignment
    ? (VerticalFlexAlignment)
    : T extends HorizontalContentfulAlignment
      ? (HorizontalFlexAlignment)
      : never;

export default <T extends ContentfulAlignment>(
  alignment: T,
): DynamicFlexAlignment<T> => {
  //  There's a high chance the alignment won't be set in contentful
  if (!alignment || alignment.toString() === 'Default') {
    return 'center' as DynamicFlexAlignment<T>;
  }

  const lowercaseAlignment = toLower(alignment) as T;

  if (isVertical(lowercaseAlignment)) {
    return mapVertical(lowercaseAlignment) as DynamicFlexAlignment<T>;
  }

  if (isHorizontal(lowercaseAlignment)) {
    return mapHorizontal(lowercaseAlignment) as DynamicFlexAlignment<T>;
  }

  throw new Error(`Invalid alignment: ${alignment}`);
};
