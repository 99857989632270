import { useEffect } from 'react';
import Prism from 'prismjs';

type LanguageObject = {
  label: string,
  value: string,
  dependencies?: string[],
};

const LanguageDictionary = [
  { label: 'ARM-ASM', value: 'armasm', fileExtension: 'asm' },
  { label: 'Bash', value: 'bash', fileExtension: 'sh' },
  { label: 'Basic', value: 'basic', fileExtension: 'bas' },
  { label: 'C', value: 'c', fileExtension: 'c' },
  { label: 'C#', value: 'csharp', fileExtension: 'cs' },
  {
    label: 'C++', value: 'cpp', fileExtension: 'cpp', dependencies: ['c'],
  },
  {
    label: 'Scala', value: 'scala', fileExtension: 'scala', dependencies: ['java'],
  },
  { label: 'Clojure', value: 'clojure', fileExtension: 'clj' },
  { label: 'CMake', value: 'cmake', fileExtension: 'cmake' },
  { label: 'Cobol', value: 'cobol', fileExtension: 'cbl' },
  { label: 'CSS', value: 'css', fileExtension: 'css' },
  { label: 'Fortran', value: 'fortran', fileExtension: 'f90' },
  { label: 'F#', value: 'fsharp', fileExtension: 'fs' },
  { label: 'GDScript', value: 'gdscript', fileExtension: 'gd' },
  { label: 'Git', value: 'git', fileExtension: 'git' },
  {
    label: 'GLSL', value: 'glsl', fileExtension: 'glsl', dependencies: ['c'],
  },
  { label: 'Go', value: 'go', fileExtension: 'go' },
  { label: 'GraphQL', value: 'graphql', fileExtension: 'graphql' },
  { label: 'Haskell', value: 'haskell', fileExtension: 'hs' },
  {
    label: 'HLSL', value: 'hlsl', fileExtension: 'hlsl', dependencies: ['c'],
  },
  { label: 'HTML', value: 'markup', fileExtension: 'html' },
  { label: 'HTTP', value: 'http', fileExtension: 'http' },
  { label: 'Java', value: 'java', fileExtension: 'java' },
  { label: 'JavaScript', value: 'javascript', fileExtension: 'js' },
  { label: 'JSON', value: 'json', fileExtension: 'json' },
  {
    label: 'JSX', value: 'jsx', fileExtension: 'jsx', dependencies: ['javascript'],
  },
  {
    label: 'Liquid', value: 'liquid', fileExtension: 'liquid', dependencies: ['markup-templating'],
  },
  { label: 'Lisp', value: 'lisp', fileExtension: 'lisp' },
  {
    label: 'LLVM', value: 'llvm', fileExtension: 'll', dependencies: ['c'],
  },
  { label: 'Lua', value: 'lua', fileExtension: 'lua' },
  { label: 'Markup', value: 'markup', fileExtension: 'xml' },
  { label: 'markup-templating', value: 'markup-templating', fileExtension: 'html' },
  { label: 'MATLAB', value: 'matlab', fileExtension: 'm' },
  { label: 'Perl', value: 'perl', fileExtension: 'pl' },
  {
    label: 'PHP', value: 'php', fileExtension: 'php', dependencies: ['markup-templating'],
  },
  { label: 'PowerShell', value: 'powershell', fileExtension: 'ps1' },
  {
    label: 'Pug', value: 'pug', fileExtension: 'pug', dependencies: ['markup'],
  },
  { label: 'Python', value: 'python', fileExtension: 'py' },
  { label: 'R', value: 'r', fileExtension: 'r' },
  { label: 'Regex', value: 'regex', fileExtension: 'regex' },
  { label: 'Ruby', value: 'ruby', fileExtension: 'rb' },
  { label: 'Rust', value: 'rust', fileExtension: 'rs' },
  {
    label: 'Sass', value: 'sass', fileExtension: 'sass', dependencies: ['css'],
  },
  {
    label: 'Scala', value: 'scala', fileExtension: 'scala', dependencies: ['java'],
  },
  {
    label: 'SCSS', value: 'scss', fileExtension: 'scss', dependencies: ['css'],
  },
  { label: 'SQL', value: 'sql', fileExtension: 'sql' },
  {
    label: 'TSX', value: 'tsx', fileExtension: 'tsx', dependencies: ['jsx'],
  },
  {
    label: 'TypeScript', value: 'typescript', fileExtension: 'ts', dependencies: ['javascript'],
  },
  { label: 'XML', value: 'markup', fileExtension: 'xml' },
  { label: 'YAML', value: 'yaml', fileExtension: 'yaml' },
  { label: 'Zig', value: 'zig', fileExtension: 'zig' },
];

const getLanguageObject = (language: string | undefined) => LanguageDictionary.find(
  (lang) => lang.label.toLowerCase() === language?.toLowerCase(),
);

const loadPrismLanguage = async (languageObject: LanguageObject) => {
  const loadLanguage = async (langValue: string) => {
    const langObj = LanguageDictionary.find((lang) => lang.value === langValue);
    if (!langObj) {
      console.warn(`Language not found: ${langValue}`);
      return;
    }

    const { dependencies } = langObj;
    if (dependencies) {
      for (const dependency of dependencies) {
        // eslint-disable-next-line no-await-in-loop
        await loadLanguage(dependency);
      }
    }

    try {
      await import(`prismjs/components/prism-${langValue}`);
    } catch (e) {
      console.warn(`Error loading Prism language: ${langValue}`, e);
    }
  };

  await loadLanguage(languageObject.value);
};

const usePrismHighlight = (language: string | undefined, code: string) => {
  useEffect(() => {
    const highlightCode = async () => {
      const languageObject = LanguageDictionary.find(
        (lang) => lang.label.toLowerCase() === language?.toLowerCase(),
      );

      if (languageObject) {
        await loadPrismLanguage(languageObject);
        Prism.highlightAll();
      }
    };

    highlightCode();
  }, [language, code]);
};

export { getLanguageObject, usePrismHighlight };
